import React, {useEffect, useMemo, useState} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {AppButton} from '../../../Components/UI';
import GameItem from '../../../Components/Casino/Games/GameItem';
import CasinoActionBar from '../../../Components/Casino/CasinoActionBar';
import CasinoGamesSkeletons from '../../../Components/UI/Skeletons/CasinoGamesSkeletons';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';

import {getUser} from '../../../Redux/UserSlice/UserSelectors';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SLOTS_GAMES_ID,
} from '../../../Constants/CasinoMenuFilter';
import {SITE_ID} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {LAST_PLAYED_FILTERS} from '../../../Constants/LastPlayedFilters';

import './index.scss';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

const LastPlayed = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const {getLastPlayedGamesFromStorage} = useStorage();
  const {getCasinoGames, goToCasinoGameScreen} = useCasino();

  const [lastPlayedGames, setLastPlayedGames] = useState([]);
  const [isGameDataLoading, setIsGameDataLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const gameId = useMemo(() => getParam.get('game'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${gameId}` +
        '&openType=real' +
        `&token=${user?.auth_token}`
      )?.trim(),
    [gameId, user?.auth_token],
  );

  const GAMES_DATA = useMemo(() => {
    const storageData = getLastPlayedGamesFromStorage()
      ?.filter(item =>
        params?.tab === 'slots'
          ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
          : params?.tab === 'liveCasino'
          ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
          : true,
      )
      ?.map(item => `${item?.gameId}`);

    return lastPlayedGames
      ?.filter(item => storageData?.includes(item?.extearnal_game_id))
      ?.filter(item =>
        item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase() || ''),
      );
  }, [
    searchValue,
    params?.tab,
    lastPlayedGames,
    getLastPlayedGamesFromStorage,
  ]);

  useEffect(() => {
    setIsGameDataLoading(true);
    const externalIdsString = getLastPlayedGamesFromStorage()
      ?.map(game => game?.gameId)
      ?.join(',');
    if (externalIdsString) {
      getCasinoGames({
        limit: 30,
        externalIds: externalIdsString,
      })
        ?.then?.(response => {
          const specificGames = response?.data?.games;
          specificGames.sort(
            (a, b) =>
              externalIdsString.indexOf(a?.extearnal_game_id) -
              externalIdsString.indexOf(b?.extearnal_game_id),
          );
          setLastPlayedGames(specificGames);
        })
        ?.finally(() => setIsGameDataLoading(false));
    } else {
      setIsGameDataLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!params?.tab) {
      navigate(`${location}/all`);
    }
  }, []);

  useEffect(() => {
    if (gameId && !isLoggedIn) {
      dispatch(setAuthModal({isVisible: true}));
    }
  }, [isLoggedIn, gameId]);

  useEffect(() => {
    if (gameId && isLoggedIn) {
      setIsFullScreen(width < BREAKPOINT_XS);
    }
  }, [gameId, isLoggedIn, width]);

  return (
    <div className="lastPlayedContainer">
      {isGameDataLoading ? (
        <CasinoGamesSkeletons />
      ) : (
        <>
          {!!gameId && isLoggedIn && (
            <GameActionBarFooter
              setIsFullScreen={setIsFullScreen}
              gameTitle={
                GAMES_DATA?.find(item => +item?.extearnal_game_id === +gameId)
                  ?.name
              }
            />
          )}
          {!!gameId && isLoggedIn && (
            <div
              className={`${
                isFullScreen
                  ? 'fullScreenContainer z-max absolute-full column'
                  : `mt-md gameIframeContainer`
              }`}>
              {isFullScreen && (
                <GameActionBarHeader setIsFullScreen={setIsFullScreen} />
              )}
              <iframe
                src={src}
                height="100%"
                scrolling="no"
                title="casino_game"
                className="full-width"
              />
            </div>
          )}

          <div className="flex rowCenter wrap gap-10 py-lg">
            {width > BREAKPOINT_XS && (
              <CasinoActionBar inputValueRef={{current: ''}} />
            )}
            {LAST_PLAYED_FILTERS?.map(tabItem => (
              <AppButton
                small
                type={6}
                width={100}
                fontSize={11}
                key={tabItem?.tab}
                title={t(`${tabItem?.title}`)}
                active={params?.tab === tabItem?.tab}
                onClick={() => navigate(`/casino/lastPlayed/${tabItem?.tab}`)}
              />
            ))}
          </div>
          <div className="row wrap col-gutter-xs casinoGameListContainer">
            {GAMES_DATA?.length > 0 ? (
              GAMES_DATA?.map(gameItem => (
                <GameItem
                  gameItem={gameItem}
                  key={gameItem?.extearnal_game_id}
                  clickHandler={() => {
                    goToCasinoGameScreen(gameItem);
                  }}
                />
              ))
            ) : (
              <span className="textCenter font-24 py-xl full-width whiteText">
                {t('noGames')}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LastPlayed;
