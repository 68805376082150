import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const TopWinnersRowSkeleton = () => (
  <Skeleton
    count={6}
    height={40}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(TopWinnersRowSkeleton);
