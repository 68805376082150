import React, {memo, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  getCurrency,
  getTaxPercentage,
  getMaxOddForMultipleBet,
} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {getBonusInfoData} from '../../../../Redux/BettingSlice/BettingSelectors';

import {
  getCombinations,
  calculateSystemBet,
} from '../../../../Utils/CombinationsGenerator';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {MULTIPLE_BET, SYSTEM_BET} from '../../../../Constants/Betting';

import CalculationRow from '../CalculationRow';

const CalculationsBlock = ({
  odds,
  betType,
  stakeAmount,
  allEventsData,
  systemBetCount,
}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);
  const taxPercentage = useSelector(getTaxPercentage);
  const bonusInfoData = useSelector(getBonusInfoData);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const [systemBetData, setSystemBetData] = useState({});

  const oddsArray = useMemo(
    () => allEventsData?.map(event => event?.price),
    [allEventsData],
  );

  const totalStakeForSystemBet = useMemo(
    () =>
      getCombinations(allEventsData?.length, +systemBetCount)?.length *
      +stakeAmount,
    [allEventsData, stakeAmount, systemBetCount],
  );

  const possibleWinAmount = useMemo(
    () =>
      betType === SYSTEM_BET ? systemBetData?.possibleWin : +stakeAmount * odds,
    [betType, systemBetData?.possibleWin, odds, stakeAmount],
  );

  const notValidOdds = useMemo(() => {
    let notValid = [];
    bonusInfoData?.find(item => {
      notValid = oddsArray?.filter(
        odd => odd < item?.MinOdds && odds !== maxOddForMultipleBet,
      );
    });
    return notValid?.reduce((acc, curr) => curr * acc, 1);
  }, [bonusInfoData, maxOddForMultipleBet, odds, oddsArray]);

  const accumulatorBonusRowData = useMemo(() => {
    const foundBonusData = bonusInfoData?.find(item => {
      const validOddsArray = oddsArray?.filter(odd => odd >= item?.MinOdds);
      return (
        validOddsArray?.length >= item?.MinimumSelections &&
        validOddsArray?.length <= item?.MaximumSelections &&
        +stakeAmount >=
          item?.MinBetStakes?.MinStakes?.find(
            stake => stake?.Currency === currency,
          )?.Amount
      );
    });
    if (foundBonusData) {
      return {
        percent: foundBonusData?.AmountPercent,
        amount:
          ((possibleWinAmount / notValidOdds - Number(stakeAmount)) *
            +foundBonusData?.AmountPercent) /
          100,
      };
    }
  }, [
    currency,
    oddsArray,
    stakeAmount,
    notValidOdds,
    bonusInfoData,
    possibleWinAmount,
  ]);

  const taxAmount = useMemo(
    () =>
      betType === SYSTEM_BET
        ? systemBetData?.tax
        : (possibleWinAmount -
            +stakeAmount +
            (accumulatorBonusRowData?.amount || 0)) *
          (taxPercentage / 100),

    [
      betType,
      stakeAmount,
      taxPercentage,
      possibleWinAmount,
      systemBetData?.tax,
      accumulatorBonusRowData?.amount,
    ],
  );

  const finalPayout = useMemo(
    () =>
      betType === SYSTEM_BET
        ? systemBetData?.finalPayout
        : possibleWinAmount -
          taxAmount +
          (accumulatorBonusRowData?.amount || 0),
    [
      betType,
      taxAmount,
      possibleWinAmount,
      systemBetData?.finalPayout,
      accumulatorBonusRowData?.amount,
    ],
  );

  useEffect(() => {
    if (betType === SYSTEM_BET) {
      setSystemBetData(
        calculateSystemBet({
          oddsArray,
          taxPercentage,
          systemBetCount,
          stakeAmount: +stakeAmount,
        }),
      );
    }
  }, [betType, oddsArray, stakeAmount, systemBetCount, taxPercentage]);

  return (
    <div className="column gap-5 text-caption">
      {betType === SYSTEM_BET && (
        <CalculationRow
          title={t('totalStake')}
          titleClassName="blueText"
          amountClassName="blueText bold-700"
          amount={bigNumbersPrettier(
            fixedNumberWithoutRound(totalStakeForSystemBet),
          )}
        />
      )}

      <CalculationRow
        title={t('possibleWin')}
        titleClassName="blueText"
        amountClassName="blueText bold-700"
        amount={
          betType === SYSTEM_BET
            ? possibleWinAmount
            : bigNumbersPrettier(fixedNumberWithoutRound(possibleWinAmount))
        }
      />

      {betType === MULTIPLE_BET && accumulatorBonusRowData && (
        <CalculationRow
          titleClassName="blueText"
          amountClassName="blueText bold-700"
          amount={bigNumbersPrettier(
            fixedNumberWithoutRound(accumulatorBonusRowData?.amount),
          )}
          title={
            <span className="blueText bold-600">
              {t('accumulatorBonus')} ({accumulatorBonusRowData?.percent}%)
            </span>
          }
        />
      )}
      <CalculationRow
        titleClassName="blueText"
        amountClassName="blueText bold-700"
        title={
          <span className="blueText bold-600">
            {t('tax')} ({taxPercentage}%)
          </span>
        }
        amount={
          betType === SYSTEM_BET
            ? taxAmount
            : bigNumbersPrettier(fixedNumberWithoutRound(taxAmount))
        }
      />

      <CalculationRow
        title={t('finalPayout')}
        titleClassName="blueText"
        amountClassName="greenText bold-700"
        amount={
          betType === SYSTEM_BET
            ? finalPayout
            : bigNumbersPrettier(fixedNumberWithoutRound(finalPayout))
        }
      />
    </div>
  );
};

export default memo(CalculationsBlock);
