import React, {memo, useCallback, useEffect, useState} from 'react';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';
import './index.scss';

import {useSocket} from '../../../../Hooks';

import {
  MAIN_CATEGORY,
  TRANSACTION_CATEGORIES,
  DEFAULT_TRANSACTION_TYPE,
} from '../../../../Constants/TransactionHistoryFilters';
import {GET_PARTNER_DOCUMENT_TYPES} from '../../../../Constants/Socket';
import {BET_HISTORY_FILTERS_BY_DATE} from '../../../../Constants/BetHistoryFilters';

import {AppButton, AppDatePicker, AppSelect} from '../../../UI';
import AppSelectSkeleton from '../../../UI/Skeletons/AppSelectSkeleton';

const TransactionHistoryFilters = ({
  toDate,
  fromDate,
  setToDate,
  setFromDate,
  isDataLoading,
  setIsDataLoading,
}) => {
  const {t} = useTranslation();
  const {getTransactionHistory, getPartnerDocumentTypes} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [category, setCategory] = useState(MAIN_CATEGORY);
  const [type, setType] = useState(DEFAULT_TRANSACTION_TYPE);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isDocumentTypesDataLoading, setIsDocumentTypesDataLoading] =
    useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const showPressHandler = useCallback(() => {
    setShowDatePicker(false);
    setIsDataLoading(true);
    getTransactionHistory({
      type,
      to_date: toDate,
      casino: category,
      from_date: fromDate,
    });
  }, [
    type,
    toDate,
    fromDate,
    category,
    setIsDataLoading,
    getTransactionHistory,
  ]);

  useEffect(() => {
    setIsDocumentTypesDataLoading(true);
    getPartnerDocumentTypes();
  }, []);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case GET_PARTNER_DOCUMENT_TYPES:
          const filteredData = data?.data?.details?.map(item => ({
            value: item?.DocumentTypeId,
            name: item?.DocumentTypeName,
          }));
          setDocumentTypes([
            {
              name: t('all'),
              value: DEFAULT_TRANSACTION_TYPE,
            },
            ...filteredData,
          ]);
          setIsDocumentTypesDataLoading(false);
          break;
        default:
          break;
      }
    },
    [t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="column gap-15 transactionHistoryFilters">
      <div className="row items-center wrap gap-20">
        <div className="row items-center gap-10 justify-between wrap">
          <span className="blueText text-caption bold-500">
            {t('transactionType')}
          </span>
          <div style={{width: 200}}>
            {isDocumentTypesDataLoading ? (
              <AppSelectSkeleton type={2} />
            ) : (
              <AppSelect
                type={2}
                value={type}
                setValue={setType}
                className="z-index-11"
                options={documentTypes}
                titleClassName="textWithDotes transactionTypeTitle"
              />
            )}
          </div>
        </div>
        <div className="row items-center gap-10 justify-between wrap">
          <span className="blueText text-caption bold-500">
            {t('category')}
          </span>
          <div style={{width: 80}}>
            <AppSelect
              type={2}
              value={category}
              setValue={setCategory}
              options={TRANSACTION_CATEGORIES}
            />
          </div>
        </div>
      </div>
      <div className="row wrap items-center gap-row-5">
        <Swiper
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard]}
          className="transactionHistoryFiltersSwiper col-xs-12 col-sm-10">
          {BET_HISTORY_FILTERS_BY_DATE.map(item => (
            <SwiperSlide key={item?.id}>
              <AppButton
                small
                type={11}
                width={100}
                fontSize={12}
                title={t(item?.title)}
                disabled={isDataLoading}
                active={
                  item?.value?.start_date === fromDate &&
                  item?.value?.end_date === toDate
                }
                onClick={() => {
                  setToDate(item?.value?.end_date);
                  setFromDate(item?.value?.start_date);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="col-sm-2 xs-hide justify-end flex pl-sm">
          <AppButton
            small
            type={3}
            toUpperCase
            title={t('show')}
            loading={isDataLoading}
            onClick={showPressHandler}
          />
        </div>
      </div>
      <AppDatePicker
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        disabled={isDataLoading}
        setFromDate={setFromDate}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
      />
      <div className="col-sm-12 xs flex">
        <AppButton
          small
          type={3}
          toUpperCase
          title={t('show')}
          loading={isDataLoading}
          onClick={showPressHandler}
        />
      </div>
    </div>
  );
};

export default memo(TransactionHistoryFilters);
