import React, {memo, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {
  getTodayUnix,
  getLastWeekUnix,
  getThisWeekUnix,
  getLastMonthUnix,
  getThisMonthUnix,
  getYesterdayUnix,
} from '../../../../Utils/GetDatePeriods';

import {
  THIS_WEEK_STATE,
  BET_HISTORY_FILTERS_BY_DATE,
  BET_HISTORY_FILTERS_BY_STATE,
} from '../../../../Constants/BetHistoryFilters';

import {AppButton, AppDatePicker} from '../../../UI';

const SettledBetsFilters = ({
  toDate,
  fromDate,
  setToDate,
  setFromDate,
  isBetHistoryDataLoading,
  stateFilter = null,
  setStateFilter = () => {},
  showStateFilters = true,
  defaultDateFilter = THIS_WEEK_STATE,
}) => {
  const {t} = useTranslation();

  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    if (dateFilter) {
      const {start_date, end_date} =
        dateFilter === 1
          ? getTodayUnix()
          : dateFilter === 2
          ? getYesterdayUnix()
          : dateFilter === 3
          ? getThisWeekUnix()
          : dateFilter === 4
          ? getLastWeekUnix()
          : dateFilter === 5
          ? getThisMonthUnix()
          : getLastMonthUnix();

      setToDate(end_date);
      setFromDate(start_date);
    }

    setDateFilter(dateFilter);
  }, [dateFilter, setFromDate, setToDate]);

  return (
    <div className="settledBetFiltersContainer column gap-5">
      {showStateFilters && (
        <Swiper
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard]}
          className="settledBetFiltersSwiper">
          {BET_HISTORY_FILTERS_BY_STATE.map(item => (
            <SwiperSlide key={item?.id}>
              <AppButton
                small
                type={11}
                width={100}
                fontSize={12}
                title={t(item?.title)}
                disabled={isBetHistoryDataLoading}
                active={item?.value === stateFilter}
                onClick={() => setStateFilter(item?.value)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Swiper
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard]}
        className="settledBetFiltersSwiper">
        {BET_HISTORY_FILTERS_BY_DATE.map(item => (
          <SwiperSlide key={item?.id}>
            <AppButton
              small
              type={11}
              width={100}
              fontSize={12}
              title={t(item?.title)}
              disabled={isBetHistoryDataLoading}
              active={
                item?.value?.start_date === fromDate &&
                item?.value?.end_date === toDate
              }
              onClick={() => {
                setToDate(item?.value?.end_date);
                setFromDate(item?.value?.start_date);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div>
        <AppDatePicker
          type={2}
          toDate={toDate}
          fromDate={fromDate}
          setToDate={setToDate}
          setFromDate={setFromDate}
          showDatePicker={showDatePicker}
          disabled={isBetHistoryDataLoading}
          setShowDatePicker={setShowDatePicker}
        />
      </div>
    </div>
  );
};

export default memo(SettledBetsFilters);
