import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {MULTIPLE_BET, SINGLE_BET} from '../../../../Constants/Betting';

import {
  formatEventName,
  formatGameStates,
} from '../../../../Utils/EventNamesFormatter';
import {getDateColor} from '../../../../Utils/DateFormatter';
import {getGameStartTime} from '../../../../Utils/TimeConvertors';

import EventItem from '../../../Home/Games/EventItem';
import SingleBetCalculationsBlock from '../SingleBetCalculationsBlock';

import {ReactComponent as CloseIcon} from '../../../../Assets/Icons/Globals/close.svg';
import {ReactComponent as CashOutIcon} from '../../../../Assets/Icons/Globals/cashout.svg';
import {ReactComponent as WarningIcon} from '../../../../Assets/Icons/Globals/warning.svg';
import {ReactComponent as DeletedEventIcon} from '../../../../Assets/Icons/Globals/block.svg';
import {ReactComponent as BlockedEventIcon} from '../../../../Assets/Icons/Globals/blockedGame.svg';

import {AppLiveIndicator} from '../../../UI';

const GameRow = ({
  item,
  subId,
  gameId,
  isLast,
  score1,
  score2,
  eventId,
  isFirst,
  betType,
  is_live,
  marketId,
  gameState,
  isBlocked,
  setBetType,
  eventsCount,
  currentEvent,
  setEventsData,
  allEventsData,
  currentMarket,
  isEventDeleted,
  current_game_time,
  notAllowToBetData,
  removeItemHandler,
  current_game_last_set,
}) => {
  const {t} = useTranslation();

  const wssSocket = useSelector(getWssSocket);

  const [team1score, setTeam1score] = useState(score1);
  const [team2score, setTeam2score] = useState(score2);
  const [isGameLive, setIsGameLive] = useState(is_live);
  const [currentGameState, setCurrentGameState] = useState(gameState);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(
    current_game_last_set,
  );
  const [currentGameTime, setCurrentGameTime] = useState(current_game_time);

  const eventText = useMemo(
    () =>
      `${formatEventName({
        name: currentEvent?.name,
        team1Name: item?.team1_name,
        team2Name: item?.team2_name,
      })} ${
        typeof currentEvent?.base !== 'undefined'
          ? `(${
              currentMarket?.market_type?.toLowerCase()?.includes('handicap') &&
              currentEvent?.base > 0
                ? '+'
                : ''
            }${currentEvent?.base})`
          : ''
      }`,
    [
      item?.team1_name,
      item?.team2_name,
      currentEvent?.name,
      currentEvent?.base,
      currentMarket?.market_type,
    ],
  );

  const showSingleBetCalculations = useMemo(
    () =>
      betType === SINGLE_BET &&
      eventsCount !== 1 &&
      !isEventDeleted &&
      !isBlocked,
    [betType, eventsCount, isBlocked, isEventDeleted],
  );

  const isNotAllowed = useMemo(
    () =>
      notAllowToBetData?.includes(gameId) &&
      betType !== SINGLE_BET &&
      eventsCount > 1,
    [betType, eventsCount, gameId, notAllowToBetData],
  );

  useEffect(() => {
    if (eventsCount > 1) {
      setBetType(MULTIPLE_BET);
    }
  }, []);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data)?.data;

      const changedGame = data?.[subId]?.game?.[gameId];
      const changedEvent = changedGame?.market?.[marketId]?.event?.[eventId];

      if (data?.[subId]) {
        if (changedEvent?.price) {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );
            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).market[
                marketId
              ].event[eventId] = {
                ...foundGame.market[marketId].event[eventId],
                lastPrice:
                  typeof foundGame?.market?.[marketId]?.event?.[eventId]
                    ?.lastPrice === 'undefined'
                    ? foundGame.market[marketId].event[eventId]?.price
                    : foundGame?.market?.[marketId]?.event?.[eventId]
                        ?.lastPrice,
                isEventDeleted: false,
                ...changedEvent,
              };
            }
            return prevState;
          });
        }
        if (changedGame === null || changedGame?.market?.[marketId] === null) {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );

            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).market[
                marketId
              ].event[eventId] = {
                ...foundGame.market[marketId].event[eventId],
                lastPrice:
                  typeof foundGame?.market?.[marketId]?.event?.[eventId]
                    ?.lastPrice === 'undefined'
                    ? foundGame.market[marketId].event[eventId]?.price
                    : foundGame?.market?.[marketId]?.event?.[eventId]
                        ?.lastPrice,
                isEventDeleted: true,
                ...changedEvent,
              };
            }
            return prevState;
          });
        }
        if (typeof changedGame?.is_blocked !== 'undefined') {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );

            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).is_blocked =
                !!changedGame?.is_blocked;
            }
            return prevState;
          });
        }
        if (typeof changedGame?.info?.current_game_state !== 'undefined') {
          setCurrentGameState(changedGame?.info?.current_game_state);
        }
        if (typeof changedGame?.is_live !== 'undefined') {
          setIsGameLive(!!changedGame?.is_live);
        }
        if (
          typeof changedGame?.info?.score1 !== 'undefined' &&
          !!changedGame?.info?.score1
        ) {
          setTeam1score(changedGame?.info?.score1);
        }
        if (typeof changedGame?.info?.current_game_time !== 'undefined') {
          setCurrentGameTime(changedGame?.info?.current_game_time);
        }
        if (
          typeof changedGame?.info?.score2 !== 'undefined' &&
          !!changedGame?.info?.score2
        ) {
          setTeam2score(changedGame?.info?.score2);
        }
        if (
          typeof changedGame?.stats?.[`score_${currentGameState}`] !==
          'undefined'
        ) {
          setCurrentGameLastSet(prevState => ({
            ...prevState,
            ...changedGame?.stats?.[`score_${currentGameState}`],
          }));
        }
      }
    },
    [subId, gameId, marketId, eventId, currentGameState, setEventsData],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const sportIcon = useMemo(() => {
    let src = null;
    try {
      src = require(
        `../../../../Assets/Icons/Sport/${
          item?.sport_alias || item?.sport_index
        }.svg`,
      );
    } catch (error) {}
    return src;
  }, [item?.sport_alias, item?.sport_index]);

  return (
    <div className="relative">
      <div className="appBetSlipGameRow">
        <div
          className={`gameInfoContainer row gap-5 justify-between full-width py-sm px-md ${
            isFirst ? 'rounded-top-borders' : ''
          }`}>
          <div className="row items-center gap-5 teamNamesContainer full-width overflow-hidden">
            {(item?.sport_alias || item?.sport_index) && !!sportIcon && (
              <img
                width={17}
                src={sportIcon}
                className="flex-shrink"
                alt={item?.sport_alias}
              />
            )}
            <div className="column blueText bold-700 font-11 overflow-hidden ">
              <span className="ellipsis">{item?.team1_name}</span>
              <span className="ellipsis">{item?.team2_name}</span>
            </div>
          </div>
          <div className="column items-end justify-center flex-shrink">
            <div className="row items-center gap-5">
              {isGameLive && !isEventDeleted && <AppLiveIndicator />}
              {!!currentMarket?.cashout && (
                <CashOutIcon width={15} height={15} />
              )}
              {isNotAllowed && (
                <WarningIcon width={15} height={15} fill="var(--appYellow)" />
              )}
              <CloseIcon
                width={15}
                height={15}
                fill="var(--appRed)"
                className="cursor-pointer z-index-1"
                onClick={() => removeItemHandler(eventId)}
              />
            </div>
            {isGameLive ? (
              !isEventDeleted && (
                <div className="row items-center gap-5 text-caption">
                  {!!team1score &&
                    !!team2score &&
                    typeof team1score !== 'undefined' &&
                    typeof team2score !== 'undefined' && (
                      <div className="text-caption bold-600 row items-center">
                        <span className="orangeText">{team1score}</span>
                        <span className="grayText">-</span>
                        <span className="orangeText">{team2score}</span>
                      </div>
                    )}
                  {item?.info?.set_count !== 1 &&
                    !currentGameTime &&
                    typeof currentGameLastSet?.team1_value !== 'undefined' && (
                      <span className="orangeText bold-600">
                        {currentGameLastSet?.team1_value}-
                        {currentGameLastSet?.team2_value}
                      </span>
                    )}
                  {currentGameState &&
                    typeof currentGameState !== 'undefined' && (
                      <span className="grayText">
                        {t(
                          formatGameStates({
                            gameState: currentGameState,
                            sportAlias: item?.sport_alias,
                          }),
                        )}
                      </span>
                    )}
                  {currentGameTime && currentGameTime !== 'undefined' && (
                    <span className="blueText bold-600">
                      {currentGameTime?.trim?.()}`
                    </span>
                  )}
                </div>
              )
            ) : (
              <span className="grayText text-caption-extra-small bold-500">
                {getGameStartTime(item?.start_ts)}
              </span>
            )}
          </div>
        </div>
        <div
          className={`eventInfoContainer column py-xs px-md ${
            isLast ? 'rounded-bottom-borders' : ''
          } ${!isLast ? 'dashed' : ''}`}>
          <div className="row items-center justify-between">
            <div className="column">
              <span className="text-caption-small grayText bold-600">
                {formatEventName({
                  name: currentMarket?.name,
                  team1Name: item?.team1_name,
                  team2Name: item?.team2_name,
                })}
              </span>
              <span className="font-11 blueText bold-700">{eventText}</span>
            </div>
            <div className="flex gap-3">
              {currentEvent?.lastPrice &&
                currentEvent?.lastPrice !== currentEvent?.price &&
                !isEventDeleted &&
                !isBlocked && (
                  <EventItem
                    betSlip
                    oldPrice
                    disabled
                    type={3}
                    price={currentEvent?.lastPrice}
                  />
                )}
              <EventItem
                betSlip
                disabled
                type={2}
                isBlocked={isBlocked}
                price={currentEvent?.price}
              />
            </div>
          </div>
          {showSingleBetCalculations && (
            <SingleBetCalculationsBlock
              eventId={eventId}
              marketId={marketId}
              allEventsData={allEventsData}
              setEventsData={setEventsData}
              eventOdd={currentEvent?.price}
            />
          )}
          {!!isEventDeleted && (
            <div className="row items-center gap-5 py-sm">
              <DeletedEventIcon fill="red" width={15} height={15} />
              <span className="text-caption grayText redText bold-600">
                {t('eventHasBeenDeleted')}
              </span>
            </div>
          )}
          {!!isBlocked && !isEventDeleted && (
            <div className="row items-center gap-5 py-sm">
              <BlockedEventIcon fill="red" width={15} height={15} />
              <span className="text-caption grayText redText bold-600">
                {t('eventHasBeenBlocked')}
              </span>
            </div>
          )}
        </div>
        {(isEventDeleted || isBlocked) && (
          <div
            className={`absolute deletedEventLayer redText ${
              isFirst
                ? 'rounded-top-borders'
                : isLast
                ? 'rounded-bottom-borders'
                : ''
            }`}
          />
        )}
      </div>
    </div>
  );
};

export default memo(GameRow);
