import React, {memo} from 'react';

import './index.scss';

const RadioButton = ({name, value, onChange, text, defaultChecked}) => (
  <label className="radio-label appRadioButton flex row items-center gap-10">
    <input
      hidden
      name={name}
      type="radio"
      value={value}
      onChange={onChange}
      className="radio-input"
      defaultChecked={defaultChecked}
    />
    <span className="custom-radio" />
    <span className="blueText bold-600 text-subtitle2">{text}</span>
  </label>
);

export default memo(RadioButton);
