import React, {memo} from 'react';
import {useParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';

import {FixedCompetitionsFilters} from '../../../Constants/FixedCompetitionsFilters';

import './index.scss';

import ListItem from './ListItem';

const FixedCompetitions = () => {
  const params = useParams();

  return (
    <Swiper
      keyboard
      mousewheel
      spaceBetween={5}
      slidesPerView="auto"
      modules={[Keyboard]}
      className="fixedCompetitionsSwiper">
      {FixedCompetitionsFilters?.[params?.sport]?.map(item => (
        <SwiperSlide key={item?.competitionId}>
          <ListItem item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(FixedCompetitions);
