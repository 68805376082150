import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {ADD_USER_MESSAGE, SOCKET_RESPONSES} from '../../../../Constants/Socket';

import {AppButton, AppInput, AppTextArea} from '../../../UI';

const NewMessage = ({setPageIndex}) => {
  const {t} = useTranslation();
  const {addUserMessage} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const disabled = useMemo(
    () => !subject?.trim() || !body?.trim(),
    [body, subject],
  );

  const maximumCharsError = useMemo(
    () => subject?.length > 255 && t('maximumSubjectCharsAllowed'),
    [subject?.length, t],
  );

  const sendClickHandler = useCallback(() => {
    setIsLoading(true);
    addUserMessage({subject, body});
  }, [addUserMessage, body, subject]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case ADD_USER_MESSAGE:
          if (data?.data?.result === 0) {
            setPageIndex(1);
          } else if (data?.code === SOCKET_RESPONSES.LIMIT_REACHED) {
            setErrorMsg(t('tooLongMessage'));
          }
          setIsLoading(false);
          break;
        default:
          break;
      }
    },
    [setPageIndex, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="gap-10 column fit">
      <div className="column gap-5">
        <span className="text-body2 blueText">{t('subject')}:</span>
        <AppInput
          inputValue={subject}
          onChange={setSubject}
          error={maximumCharsError}
          placeholder={t('enterMessageSubjectHere')}
        />
      </div>
      <div className="column gap-5">
        <span className="text-body2 blueText">{t('message')}:</span>
        <AppTextArea
          value={body}
          error={errorMsg}
          onChange={setBody}
          setErrorMsg={setErrorMsg}
          placeholder={t('enterMessageTextHere')}
        />
      </div>

      <div className="mt-auto">
        <AppButton
          type={3}
          title={t('send')}
          loading={isLoading}
          disabled={disabled}
          onClick={sendClickHandler}
        />
      </div>
    </div>
  );
};

export default memo(NewMessage);
