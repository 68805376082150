import {
  CATEGORY_DICE_ID,
  CATEGORY_CRASH_ID,
  CATEGORY_BLAST_ID,
  CATEGORY_AVIATOR_ID,
  CATEGORY_BIG_HILO_ID,
} from './CasinoMenuFilter';

export const CASINO_FIXED_GAMES_VALUES = [
  {
    icon: 'blast',
    iconSize: 20,
    title: 'blast',
    extearnal_game_id: CATEGORY_BLAST_ID,
  },
  {
    icon: 'crash',
    iconSize: 20,
    title: 'crash',
    extearnal_game_id: CATEGORY_CRASH_ID,
  },
  {
    icon: 'dice',
    iconSize: 20,
    title: 'dice',
    extearnal_game_id: CATEGORY_DICE_ID,
  },
  {
    isNew: true,
    title: null,
    iconSize: 45,
    icon: 'aviator',
    extearnal_game_id: CATEGORY_AVIATOR_ID,
  },
  {
    iconSize: 20,
    icon: 'bigHiLo',
    title: 'bigHilo',
    extearnal_game_id: CATEGORY_BIG_HILO_ID,
  },
];
