import React, {memo, useCallback, useEffect, useState} from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Keyboard, Navigation} from 'swiper/modules';

import GameItem from './GameItem';

import {useScreenSize, useSocket} from '../../../Hooks';

import {getSid, getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {GET_FEATURED_GAMES} from '../../../Constants/Socket';

import './index.scss';

const autoplay = {
  delay: 1500,
  disableOnInteraction: false,
};
const FeaturedGames = () => {
  const {t} = useTranslation();

  const {getFeaturedGames, unSubscribeLiveEvents} = useSocket();
  const {width} = useScreenSize();

  const sid = useSelector(getSid);
  const wssSocket = useSelector(getWssSocket);

  const [subId, setSubId] = useState(null);
  const [featuredGames, setFeaturedGames] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isGameDataLoading, setIsGameDataLoading] = useState(false);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event.data);

    switch (data?.rid) {
      case GET_FEATURED_GAMES:
        const sportObject = data?.data?.data?.sport;

        const gamesArray = [];

        for (const sport in sportObject) {
          for (const region in sportObject[sport]?.region) {
            for (const competition in sportObject[sport]?.region[region]
              ?.competition) {
              for (const currentGame in sportObject[sport]?.region[region]
                ?.competition?.[competition]?.game) {
                gamesArray.push({
                  ...sportObject[sport]?.region[region]?.competition?.[
                    competition
                  ]?.game?.[currentGame],
                  region_alias: sportObject[sport]?.region?.[region]?.alias,
                  competition_name:
                    sportObject[sport]?.region[region]?.competition?.[
                      competition
                    ]?.name,
                });
              }
            }
          }
        }
        setSubId(data?.data?.subid);
        setFeaturedGames(gamesArray);
        setTimeout(() => {
          setIsGameDataLoading(false);
        }, 300);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket, getFeaturedGames]);

  useEffect(() => {
    if (!!sid) {
      setIsGameDataLoading(true);
      getFeaturedGames();
    }

    return () => {
      unSubscribeLiveEvents({subId});
    };
  }, [sid]);

  return (
    !!featuredGames?.length && (
      <div
        className={`featuredGamesContainer ${
          width < BREAKPOINT_XS && 'mb-sm'
        }`}>
        {width > BREAKPOINT_XS && (
          <div
            className={`flex justify-between items-center py-xs  mb-sm px-sm featuredTitleContainer`}>
            <span className="bold-600 blueText">{t('featuredGames')}</span>
            {/*<span className="bold-500 blueText">*/}
            {/*  {(activeItemIndex || 0) + 1}/{featuredGames?.length}*/}
            {/*</span>*/}
          </div>
        )}
        <Swiper
          loop
          spaceBetween={5}
          // autoplay={autoplay}
          key={width < BREAKPOINT_XS}
          keyboard={width > BREAKPOINT_XS}
          navigation={width > BREAKPOINT_XS}
          mousewheel={width > BREAKPOINT_XS}
          slidesPerView={width < 355 ? 1 : 'auto'}
          slidesOffsetAfter={
            width > BREAKPOINT_XS &&
            !document.querySelector('.swiper-button-next.swiper-button-lock')
              ? 30
              : 0
          }
          slidesOffsetBefore={
            width > BREAKPOINT_XS &&
            !document.querySelector('.swiper-button-prev.swiper-button-lock')
              ? 30
              : 0
          }
          className={`featuredGamesSwiperContainer flex items-center full-width ${
            width > BREAKPOINT_XS && 'webFeaturedGamesSwiperContainer'
          }`}
          onSlideChange={item => setActiveItemIndex(item?.realIndex)}
          modules={
            width > BREAKPOINT_XS
              ? [Autoplay, Navigation, Keyboard]
              : [Autoplay]
          }>
          {(featuredGames || [])?.map(item => (
            <SwiperSlide key={item?.id}>
              <GameItem game={item} subId={subId} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};

export default memo(FeaturedGames);
