import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard, Navigation} from 'swiper/modules';

import './index.scss';

import {getSid, getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../Hooks';

import {formatUnixDate} from '../../../Utils/DateFormatter';
import {bigNumbersPrettier} from '../../../Utils/BigNumbersPrettier';

import {
  GET_PARTNER_BIG_WINS,
  GET_PARTNER_LAST_WINS,
} from '../../../Constants/Socket';
import {
  WINNERS_HEADER_DATA,
  TOP_WINNERS_HEADER_TITLE,
  LAST_WINNERS_HEADER_TITLE,
} from '../../../Constants/Globals';

import TopWinnersRowSkeleton from '../../UI/Skeletons/TopWinnersRowSkeleton';

import {AppButton} from '../../UI';

const TopWinners = () => {
  const {t} = useTranslation();
  const {getTopWinners, getLastWinners} = useSocket();

  const sid = useSelector(getSid);
  const wssSocket = useSelector(getWssSocket);

  const [topWinners, setTopWinners] = useState([]);
  const [lastWinners, setLastWinners] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedHeaderTitle, setSelectedHeaderTitle] = useState(
    LAST_WINNERS_HEADER_TITLE,
  );

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case GET_PARTNER_LAST_WINS:
        setLastWinners(data?.data?.details);
        setIsDataLoading(false);
        break;
      case GET_PARTNER_BIG_WINS:
        setTopWinners(data?.data?.details);
        setIsDataLoading(false);
        break;
      default:
        break;
    }
  }, []);

  const WINNERS_DATA = useMemo(
    () =>
      selectedHeaderTitle === TOP_WINNERS_HEADER_TITLE
        ? topWinners
        : selectedHeaderTitle === LAST_WINNERS_HEADER_TITLE
        ? lastWinners
        : [],
    [lastWinners, selectedHeaderTitle, topWinners],
  );

  useEffect(() => {
    setIsDataLoading(true);
    if (selectedHeaderTitle === TOP_WINNERS_HEADER_TITLE) {
      getTopWinners();
    } else {
      getLastWinners();
    }
  }, [getLastWinners, getTopWinners, selectedHeaderTitle, sid]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="topWinnersContainer column pa-sm">
      <div className="row pt-lg gap-10">
        {WINNERS_HEADER_DATA.map(({id, title}) => (
          <AppButton
            type={7}
            key={id}
            width={100}
            fontSize={9}
            title={t(title)}
            loading={isDataLoading}
            active={title === selectedHeaderTitle}
            onClick={() => setSelectedHeaderTitle(title)}
            className="px-sm py-md items-center justify-center flex"
          />
        ))}
      </div>
      <div className="py-md whiteText text-caption bold-600">
        {isDataLoading ? (
          <TopWinnersRowSkeleton />
        ) : (
          <div className="column rounded-borders darkenBlueBackground pa-xs">
            <div className="row items-center bold-800  headerTitlesContainer pa-md">
              <div className="col-xs-3">
                <span>{t('id')?.toUpperCase?.()}</span>
              </div>
              <div className="col-xs-3 flex justify-center">
                <span>{t('game')}</span>
              </div>
              <div className="col-xs-3 justify-center flex">
                <span>{t('time')}</span>
              </div>
              <div className="col-xs-3 justify-center flex">
                <span className="textWithDotes">{t('winAmount')}</span>
              </div>
            </div>
            {(WINNERS_DATA || [])?.map?.(item => (
              <div
                key={item?.time}
                className="row items-center rounded-borders pa-md topWinnersRowItem col-gutter-sm">
                <div className="col-xs-3 flex">
                  <span className="textWithDotes">
                    {item?.player_user_name}
                  </span>
                </div>
                <div className="col-xs-3 flex justify-center">
                  <span className="textWithDotes">{item?.game_name}</span>
                </div>
                <div className="col-xs-3 justify-center flex">
                  <span className="textWithDotes">
                    {formatUnixDate(moment?.(item?.time)?.unix?.())}
                  </span>
                </div>
                <div className="col-xs-3 justify-center flex">
                  <span className="greenText mr-sm">{item?.currency_id}</span>
                  <span className="textWithDotes">
                    {bigNumbersPrettier(item?.win_amount)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TopWinners);
