import {useCallback} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation, createSearchParams} from 'react-router-dom';

import {useStorage} from './index';

import {getLanguage} from '../Redux/AppSlice/AppSelectors';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SLOTS_GAMES_ID,
} from '../Constants/CasinoMenuFilter';
import {SITE_ID} from '../Constants/Socket';
import {APP_LANGUAGES} from '../Constants/Languages';

const UseCasino = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {setLastPlayedGamesToStorage} = useStorage();

  const language = useSelector(getLanguage);

  const goToCasinoGameScreen = useCallback(
    (game, path) => {
      const categoryId = game?.categories?.find(
        item =>
          +item === CATEGORY_LIVE_CASINO_ID ||
          +item === CATEGORY_SLOTS_GAMES_ID,
      );

      setLastPlayedGamesToStorage(game?.extearnal_game_id, categoryId)
        ?.then?.()
        ?.catch?.();

      navigate({
        pathname: path || location?.pathname,
        search: createSearchParams({
          game: game?.extearnal_game_id,
        }).toString(),
      });
    },
    [location?.pathname, navigate, setLastPlayedGamesToStorage],
  );

  const getCasinoGames = useCallback(
    ({
      limit = 30,
      offset = 0,
      category = '',
      provider = '',
      searchQuery = '',
      externalIds = '',
    } = {}) => {
      const baseUrl = `https://cmsbetconstruct.com/casino/getGames?partner_id=${SITE_ID}&lang=${
        language === APP_LANGUAGES.CHN ? 'chi' : language
      }&offset=${offset}&limit=${limit}${
        !!searchQuery ? `&search=${searchQuery}` : ''
      }${category ? `&category=${category}` : ''}${
        provider ? `&provider=${provider}` : ''
      }${externalIds ? `&external_id=${externalIds}` : ''}`;
      return axios.get(`${baseUrl}`);
    },
    [language],
  );

  const getCasinoProviders = useCallback(
    ({categoryId = null} = {}) => {
      return axios.get(
        'https://cmsbetconstruct.com/casino/getOptions' +
          `?partner_id=${SITE_ID}&only_providers=1&lang=${
            language === APP_LANGUAGES.CHN ? 'chi' : language
          }` +
          (categoryId ? `&categories=${categoryId}` : ''),
      );
    },
    [language],
  );

  return {getCasinoGames, getCasinoProviders, goToCasinoGameScreen};
};

export default UseCasino;
