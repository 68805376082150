import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {PAYMENT_SERVICES} from '../../../../Constants/PaymentServices';
import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../../Constants/Globals';
import {MAKE_DEPOSIT, SOCKET_RESPONSES} from '../../../../Constants/Socket';

import {AppButton, AppInput} from '../../../UI';
import OperatorInfoText from '../OperatorInfoText';

const Deposit = ({userOperator}) => {
  const {t} = useTranslation();
  const {deposit} = useSocket();
  const dispatch = useDispatch();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [depositAmount, setDepositAmount] = useState('');
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [activeOperator, setActiveOperator] = useState(
    PAYMENT_SERVICES.find(item => item?.title === userOperator) ||
      PAYMENT_SERVICES?.[0],
  );

  const depositClickHandler = useCallback(() => {
    setIsDepositLoading(true);
    deposit({
      amount: depositAmount,
      service: activeOperator?.id,
    });
  }, [activeOperator, deposit, depositAmount]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case MAKE_DEPOSIT:
          if (!Object.keys(data?.data || {})?.length) {
            inputRef?.current.blur();
            setErrorMsg(t('somethingWentWrong'));
          } else {
            if (data?.data?.status === SOCKET_RESPONSES?.PENDING_MESSAGE) {
              dispatch(
                setMainMenuModal({
                  isVisible: false,
                }),
              );
              setTimeout(() => {
                dispatch(
                  setSuccessModal({
                    isVisible: true,
                    successTitle: t('depositCompletedTitle'),
                    successDescription: t('depositCompletedDescription'),
                  }),
                );
              }, APP_MODAL_CLOSE_TIMEOUT_MS);
            } else {
              inputRef?.current.blur();
              setErrorMsg(data?.data?.details?.error);
            }
          }
          setIsDepositLoading(false);
          break;
        default:
          break;
      }
    },
    [currency, depositAmount, dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="menu-balance-depositContainer">
      <span className="blueText text-caption">
        {t('pleaseSelectAPaymentMethod')}
      </span>
      <div className="column gap-15 py-md">
        <div className="row gap-10">
          <div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">
            <span className="text-center grayText bold-500">MOBILE MONEY</span>
          </div>
          <Swiper
            keyboard
            mousewheel
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Keyboard]}
            className="paymentsSwiper"
            initialSlide={
              PAYMENT_SERVICES?.findIndex?.(
                item => item?.title === userOperator,
              ) || 0
            }>
            {PAYMENT_SERVICES?.filter(item => item?.show !== false).map(
              item => (
                <SwiperSlide key={item?.id}>
                  <img
                    src={item?.logo}
                    alt={item?.title}
                    onClick={() => setActiveOperator(item)}
                    className={`paymentSwiperImage cursor-pointer ${
                      activeOperator?.title !== item?.title
                        ? 'not-selected'
                        : ''
                    }`}
                  />
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </div>

      <div className="column gap-2">
        <span className="text-caption blueText">{t('amount')}</span>
        <div className="row wrap col-gutter-sm">
          <div className="col-xs-12 col-sm-8">
            <AppInput
              error={errorMsg}
              inputRef={inputRef}
              setErrorMsg={setErrorMsg}
              inputValue={depositAmount}
              onChange={setDepositAmount}
              placeholder={t('enterAmountToDeposit', {currency})}
            />
            <span className="text-caption blueText">
              {t('minMaxLimits', {
                currency,
                minValue: 1000,
                maxValue: bigNumbersPrettier(5000000),
              })}
            </span>
          </div>
          <div className="col-xs-12 col-sm-4">
            <AppButton
              type={3}
              height={45}
              loading={isDepositLoading}
              onClick={depositClickHandler}
              title={t('deposit')?.toUpperCase?.()}
              disabled={!+depositAmount || +depositAmount < 1000}
            />
          </div>
        </div>
      </div>
      <OperatorInfoText activeOperator={activeOperator?.title} />
    </div>
  );
};

export default memo(Deposit);
