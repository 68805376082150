import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {FreeMode, Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {CASINO_FIXED_GAMES_VALUES} from '../../../Constants/CasinoFixedGamesValues';

const CasinoFixedGames = ({isMobile = false}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const currentGameId = getParam.get('game');
  const {goToCasinoGameScreen} = useCasino();

  return (
    <div
      className={`casinoFixedGamesWrapper px-sm ${
        isMobile ? 'mx-sm' : 'my-sm'
      } blueBackground`}>
      <Swiper
        freeMode
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        className={`casinoFixedGamesSwiper ${
          width > BREAKPOINT_XS ? 'desktop' : 'mobile'
        }`}>
        {CASINO_FIXED_GAMES_VALUES?.map(item => (
          <SwiperSlide key={item?.icon}>
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                goToCasinoGameScreen(item, '/casino/slots/all');
              }}
              className={`${
                +currentGameId === +item?.extearnal_game_id
                  ? 'yellowBackground'
                  : 'darkBlueBackground'
              } whiteText text-caption-small bold-600 rounded-borders items-center row justify-center cursor-pointer gameItem gap-5 my-sm relative`}>
              <img
                alt={item?.icon}
                width={item?.iconSize}
                src={require(`../../../Assets/Icons/Casino/${item?.icon}.svg`)}
              />
              {item?.title && (
                <span className="whiteText">{t(item?.title)}</span>
              )}
              {item?.isNew && (
                <div className="absolute-top-right redBackground newBadge items-center px-sm py-sm flex">
                  <span className="text-caption-extra-small bold-700 whiteText resetLineHeight">
                    {t('new')}
                  </span>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(CasinoFixedGames);
