import React, {memo, useState} from 'react';
import {useSelector} from 'react-redux';

import './index.scss';

import {getUser} from '../../Redux/UserSlice/UserSelectors';

import {ReactComponent as ExitIcon} from '../../Assets/Icons/Globals/exit.svg';
import {ReactComponent as CommunityChatIcon} from '../../Assets/Icons/BottomTabs/CommunityChat.svg';

import {COMMUNITY_CHAT_URI, USER_ID} from '../../Constants/Globals';

const CommunityChatWidget = ({show}) => {
  const user = useSelector(getUser);

  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <div
      onClick={() => setIsFullScreen(true)}
      className={`${show ? '' : 'hidden'} communityChatWidgetWrapper`}>
      <div className="communityChatWidget flex items-center justify-center">
        <CommunityChatIcon fill="var(--appBlue)" width={32} height={32} />
      </div>
      <div className="communityChatIframeContainer">
        <ExitIcon
          width={25}
          height={25}
          onClick={e => {
            e.stopPropagation();
            setIsFullScreen(false);
          }}
          className={isFullScreen ? 'communityChatIframeCloseIcon' : 'hidden'}
        />
        <iframe
          width="100%"
          height="100%"
          allow="clipboard-read; clipboard-write; microphone;"
          src={COMMUNITY_CHAT_URI?.replace(USER_ID, user?.unique_id)}
          className={
            isFullScreen ? 'absolute-full communityChatIframe' : 'hidden'
          }
        />
      </div>
    </div>
  );
};

export default memo(CommunityChatWidget);
