import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

const CalculationRow = ({
  title,
  amount,
  hideCurrency = false,
  titleClassName = 'blueText bold-600',
  amountClassName = 'blueText bold-600',
}) => {
  const currency = useSelector(getCurrency);

  return (
    <div className="row justify-between items-center">
      {typeof title === 'string' ? (
        <span className={`${titleClassName} bold-600 text-caption`}>
          {title}
        </span>
      ) : (
        title
      )}
      <div
        style={{width: '50%'}}
        className={`${amountClassName} gap-5 row justify-between bold-600 text-caption`}>
        {hideCurrency ? <div /> : <span>{currency}</span>}
        <span className="ellipsis">{bigNumbersPrettier(amount)}</span>
      </div>
    </div>
  );
};

export default memo(CalculationRow);
