export const CASH = 'Cash';
export const M_PESA = 'FastHub';
export const EZY_PESA = 'EzyPesa';
export const AIRTEL_NEW = 'AirtelVoda';
export const TIGO_PESA = 'FastHubTigo';
export const HALO_PESA = 'FastHubHalotel';

export let PAYMENT_SERVICES = [
  {
    id: 13071,
    title: M_PESA,
    logo: require('../Assets/Images/PaymentServices/MPesa.png'),
  },
  {
    id: 13825,
    title: AIRTEL_NEW,
    logo: require('../Assets/Images/PaymentServices/AirtelMoney.png'),
  },
  {
    id: 13080,
    title: TIGO_PESA,
    logo: require('../Assets/Images/PaymentServices/TigoPesa.png'),
  },
  {
    id: 13081,
    title: HALO_PESA,
    logo: require('../Assets/Images/PaymentServices/HaloPesa.png'),
  },
];

export const LE_GRANDE_CASINO_ID = 104816;
export const PALM_BEACH_CASINO_ID = 104817;
export const CASH_WITHDRAW_SERVICE_ID = 353;
export const CASINO_MIN_WITHDRAW_AMOUNT = 20000;
export const OPERATOR_MIN_WITHDRAW_AMOUNT = 1000;
export const LE_GRANDE_CASINO_TITLE = 'LE_GRANDE';
export const PALM_BEACH_CASINO_TITLE = 'PALM_BEACH';
export const OPERATOR_MAX_WITHDRAW_AMOUNT = 5000000;
export const CASINO_MAX_WITHDRAW_AMOUNT = 100000000;

export let CASINO_METHODS = [
  {
    id: LE_GRANDE_CASINO_ID,
    title: LE_GRANDE_CASINO_TITLE,
    background: 'var(--lightAqua)',
    office_id: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/LeGrandeCasino.png'),
  },
  {
    id: PALM_BEACH_CASINO_ID,
    title: PALM_BEACH_CASINO_TITLE,
    background: 'var(--lightOrange)',
    office_id: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/PalmBeachCasino.png'),
  },
];
