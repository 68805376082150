import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {
  getOpenBetsCount,
  getHeaderTabIndex,
} from '../../../../Redux/BettingSlice/BettingSelectors';
import {setHeaderTabIndex} from '../../../../Redux/BettingSlice';
import {getBetSlipCount} from '../../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {BETSLIP_HEADER_TABS} from '../../../../Constants/Betting';

import Counter from '../Counter';
import {AppButton} from '../../../UI';

const HeaderTabs = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {getOpenedBetsCount} = useSocket();

  const betSlipCount = useSelector(getBetSlipCount);
  const openBetsCount = useSelector(getOpenBetsCount);
  const headerTabIndex = useSelector(getHeaderTabIndex);

  useEffect(() => {
    getOpenedBetsCount();
  }, []);

  useEffect(
    () => () => {
      dispatch(setHeaderTabIndex(0));
    },
    [],
  );

  return (
    <div className="row items-center gap-5 pt-md full-width">
      {BETSLIP_HEADER_TABS?.map(({key, title, tabIndex}) => (
        <AppButton
          type={2}
          key={key}
          fontSize={11}
          className="py-sm"
          active={headerTabIndex === tabIndex}
          onClick={() => dispatch(setHeaderTabIndex(tabIndex))}
          title={
            <div className="row items-center justify-center gap-5">
              <span
                className={`text-caption-small bold-700 ${
                  headerTabIndex === tabIndex ? 'blueText' : 'whiteText'
                }`}>
                {t(title)}
              </span>
              {tabIndex === 0 && !!betSlipCount && width > BREAKPOINT_XS && (
                <Counter
                  value={betSlipCount}
                  active={headerTabIndex === tabIndex}
                />
              )}
              {tabIndex === 1 && !!openBetsCount && (
                <Counter
                  value={openBetsCount}
                  active={headerTabIndex === tabIndex}
                />
              )}
            </div>
          }
        />
      ))}
    </div>
  );
};

export default memo(HeaderTabs);
