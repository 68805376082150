import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {useSocket} from '../../../../../Hooks';

import {
  SELF_EXCLUSIONS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../../../Constants/Globals';
import {SET_CLIENT_SELF_EXCLUSION} from '../../../../../Constants/Socket';

import {AppButton, AppRadioButton} from '../../../../UI';

const SelfExclusion = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {updateSelfExclusion} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const selectedLimit = useRef({limit: SELF_EXCLUSIONS?.[0]?.limit, index: 0});

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const saveClickHandler = useCallback(() => {
    setIsSaveLoading(true);
    updateSelfExclusion({limits: selectedLimit?.current?.limit, exc_type: 2});
  }, [updateSelfExclusion]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case SET_CLIENT_SELF_EXCLUSION:
          if (data?.code === 0 && data?.data?.result === 0) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('selfExclusionPeriodSet'),
                  successDescription: `${t('sorryToSeeYouGoDeeYouIn')} ${
                    SELF_EXCLUSIONS?.[selectedLimit.current?.index]?.title
                  }!`,
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          }
          setIsSaveLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <>
      <div className="py-lg flex column items-center selfExclusionTabContainer">
        <div className="flex row items-center full-width justify-center">
          <div className="col-xs-12 col-sm-10 col-md-8 flex column content justify-between gap-10">
            <div className="flex justify-between column gap-10 col-sm-12 col-md-4">
              <span className="blueText text-body2 bold-600 textCenter py-md">
                {t('selfExclusion')?.toUpperCase?.()}
              </span>
              <div className="line" />
              <span className="blueText bold-500 text-subtitle2">
                {t('selfExclusionText')}
              </span>
              <div className="flex column gap-5">
                {SELF_EXCLUSIONS.map(({limit, title, value}, index) => (
                  <AppRadioButton
                    key={value}
                    value={value}
                    text={t(title)}
                    name="exclusionTime"
                    defaultChecked={index === 0}
                    onChange={() => (selectedLimit.current = {limit, index})}
                  />
                ))}
              </div>
            </div>
            <div className="flex column gap-5">
              <AppButton
                type={3}
                title={t('save')}
                loading={isSaveLoading}
                onClick={saveClickHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SelfExclusion);
