import React, {memo, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';

import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  getIsGameDataLoading,
  getIsPopularCompetitionsLoading,
} from '../../../Redux/SportSlice/SportSelectors';
import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';
import {setIsPopularCompetitionsLoading} from '../../../Redux/SportSlice';

import {useSocket} from '../../../Hooks';

import {getCompetitionsArray} from '../../../Utils/GetSportId';

import {
  FILTERS_LIVE_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {Flags} from '../../../Constants/Flags';
import {GET_ALL_POPULAR_COMPETITIONS} from '../../../Constants/Socket';

import {AppButton} from '../../UI';
import PopularCompetitionSkeletons from '../../UI/Skeletons/PopularCompetitionSkeletons';

const PopularCompetitions = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {getPopularCompetitions} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isPopularCompetitionsLoading = useSelector(
    getIsPopularCompetitionsLoading,
  );
  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const [popularCompetitions, setPopularCompetitions] = useState([]);

  useEffect(() => {
    if (!!params?.sport) {
      dispatch(setIsPopularCompetitionsLoading(true));
      getPopularCompetitions({
        activeSportAlias: params?.sport,
      });
    }
  }, [dispatch, getPopularCompetitions, params?.sport]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case GET_ALL_POPULAR_COMPETITIONS:
          const resultArray = [];
          const regions = data?.data?.data?.region || {};

          for (const regionId in regions) {
            const region = regions?.[regionId];

            for (const competitionId in region?.competition) {
              const competition = region?.competition?.[competitionId];

              const resultObject = {...competition, regionAlias: region?.alias};

              resultArray?.push?.(resultObject);
            }
          }
          setPopularCompetitions(resultArray);
          setTimeout(
            () => dispatch(setIsPopularCompetitionsLoading(false)),
            200,
          );
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="popularCompetitionsWrapper pb-sm">
      {isPopularCompetitionsLoading ? (
        <PopularCompetitionSkeletons />
      ) : (
        !isPopularCompetitionsLoading &&
        !!popularCompetitions?.length && (
          <Swiper
            keyboard
            mousewheel
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Keyboard]}
            className="popularCompetitionsSwiper">
            {popularCompetitions?.map(item => (
              <SwiperSlide key={item?.id}>
                <AppButton
                  type={15}
                  disabledWithoutLayer={isGameDataLoading}
                  onClick={() =>
                    navigate(
                      `/sports/${
                        params?.filter === FILTERS_LIVE_NAME
                          ? FILTERS_LIVE_NAME
                          : FILTERS_UPCOMING_MATCHES_NAME
                      }/${params?.sport}/${item?.regionAlias}/${item?.id}`,
                    )
                  }
                  className="py-sm px-md"
                  active={getCompetitionsArray(params?.competition)?.includes(
                    item?.id,
                  )}
                  title={
                    <div className="row items-center gap-5">
                      <img
                        width={18}
                        alt={item?.regionAlias}
                        src={Flags?.[item?.regionAlias]}
                      />
                      <span className="text-caption-small bold-500">
                        {t(item?.name)}
                      </span>
                    </div>
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )
      )}
    </div>
  );
};

export default memo(PopularCompetitions);
