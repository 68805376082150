import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  setBonusInfoData,
  setIsBetSlipDataLoading,
} from '../../../../Redux/BettingSlice';
import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';
import {
  getHeaderTabIndex,
  getIsBetSlipDataLoading,
} from '../../../../Redux/BettingSlice/BettingSelectors';
import {setBetSlipCount, setShowBetSlip} from '../../../../Redux/AppSlice';
import {getMaxOddForMultipleBet} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize, useSocket, useStorage} from '../../../../Hooks';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import {
  UNSUBSCRIBE_BULK,
  SUBSCRIBE_TO_EVENTS,
  GET_SPORT_BONUS_RULES,
  UNSUBSCRIBE_LIVE_EVENT,
} from '../../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {SINGLE_BET, SYSTEM_BET} from '../../../../Constants/Betting';

import Zigzag from '../../Components/Zigzag';
import GameRow from '../../Components/GameRow';
import OddsContainer from '../../Components/OddsContainer';
import SystemDropdown from '../../Components/SystemDropdown';
import BetTypeDropdown from '../../Components/BetTypeDropdown';
import BetSlipSuccessModal from '../../Components/SuccessModal';

import BetSlipGameRowSkeleton from '../../../UI/Skeletons/BetSlipGameRowSkeleton';

import Logo from '../../../../Assets/Icons/Globals/PigabetLogoLightBlue.svg';
import moment from 'moment';

const MainBetting = () => {
  const {
    setBetSlipDataToStorage,
    getBetSlipDataFromStorage,
    removeBetSlipDataFromStorage,
  } = useStorage();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {unSubscribeBulk, getSportBonusRules, unSubscribeLiveEvents} =
    useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const headerTabIndex = useSelector(getHeaderTabIndex);
  const isBetSlipDataLoading = useSelector(getIsBetSlipDataLoading);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const containerRef = useRef(null);

  const [betType, setBetType] = useState(SINGLE_BET);
  const [eventsData, setEventsData] = useState(
    getBetSlipDataFromStorage() || [],
  );
  const [systemBetCount, setSystemBetCount] = useState(2);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const allEventsData = useMemo(
    () =>
      eventsData?.map(
        item =>
          (Object.values(Object.values(item?.market || {})?.[0].event) ||
            {})?.[0],
      ),
    [eventsData],
  );

  const allGamesData = useMemo(
    () =>
      eventsData?.reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue?.id]: currentValue,
        };
      }, {}),
    [eventsData],
  );

  const oddsAmount = useMemo(() => {
    const calculatedOdds = allEventsData?.reduce(
      (acc, curr) => acc * curr.price,
      1,
    );

    return calculatedOdds > maxOddForMultipleBet
      ? maxOddForMultipleBet
      : +fixedNumberWithoutRound(calculatedOdds);
  }, [allEventsData, maxOddForMultipleBet]);

  const isBettingBlocked = useMemo(
    () =>
      Object.values(allGamesData || {})?.find(item => item?.is_blocked) ||
      allEventsData?.some(item => item?.isEventDeleted),
    [allEventsData, allGamesData],
  );

  const notAllowToBetData = useMemo(() => {
    let notAllowedGameIds = [];
    for (let i = 0; i < eventsData?.length; i++) {
      const game = eventsData?.[i];
      if (
        eventsData?.find((el, index) => {
          const elMarket = Object.values(el?.market || {})?.[0];
          const gameMarket = Object.values(game?.market || {})?.[0];

          return (
            index !== i &&
            game?.id === el?.id &&
            (elMarket?.prematch_express_id ===
              gameMarket?.prematch_express_id ||
              (elMarket?.express_id ?? gameMarket?.express_id ?? 1) ===
                (gameMarket?.express_id ?? elMarket?.express_id ?? 1))
          );
        })
      ) {
        notAllowedGameIds?.push(game?.id);
      }
    }
    return notAllowedGameIds;
  }, [eventsData]);

  const removeItemHandler = useCallback(
    eventId => {
      unSubscribeLiveEvents({
        rid: `${UNSUBSCRIBE_LIVE_EVENT}_${eventId}`,
        subId: eventsData?.find(game => game?.unique_id === eventId)?.subId,
      });
      setEventsData(prev => prev?.filter(item => item?.unique_id !== eventId));
    },
    [eventsData, unSubscribeLiveEvents],
  );

  const removeAllHandler = useCallback(() => {
    setEventsData([]);
    removeBetSlipDataFromStorage();
    const allSubIdsArray = eventsData?.map(item => item?.subId);
    unSubscribeBulk({
      subIdsArray: allSubIdsArray,
      rid: `${UNSUBSCRIBE_BULK}_betSlip`,
    });
  }, [removeBetSlipDataFromStorage, eventsData, unSubscribeBulk]);

  useEffect(() => {
    setBetSlipDataToStorage(eventsData);
  }, [eventsData, setBetSlipDataToStorage]);

  useEffect(() => {
    dispatch(setShowBetSlip(!!eventsData?.length));
    dispatch(setBetSlipCount(eventsData?.length));
  }, [dispatch, eventsData?.length]);

  useEffect(() => {
    getSportBonusRules();
  }, [getSportBonusRules]);

  useEffect(() => {
    if (allEventsData?.length === 1) {
      setBetType(SINGLE_BET);
    }
  }, [allEventsData?.length]);

  const dataHandler = useCallback(
    data => {
      const gameId = +Object.keys(data?.data?.data?.game || {})?.[0];
      const marketId = +Object.keys(
        data?.data?.data?.game?.[gameId]?.market || {},
      )?.[0];
      const eventId = +Object.keys(
        data?.data?.data?.game?.[gameId]?.market?.[marketId]?.event || {},
      )?.[0];

      setEventsData(prev => {
        const prevState = [...(prev || [])];

        const foundEventIndex = prevState?.findIndex(
          item => item?.unique_id === eventId,
        );
        if (foundEventIndex > -1) {
          const lastPrice =
            prevState[foundEventIndex].market[marketId].event[eventId]
              ?.lastPrice;

          prevState[foundEventIndex] = data?.data?.data?.game?.[gameId];
          prevState[foundEventIndex].subId = data?.data?.subid;
          prevState[foundEventIndex].timestamp = moment?.()?.unix?.();
          prevState[foundEventIndex].unique_id = eventId;
          prevState[foundEventIndex].market[marketId].event[eventId] = {
            ...prevState[foundEventIndex].market[marketId].event[eventId],
            lastPrice,
          };
          return prevState;
        } else {
          const unique_id = +Object.keys(
            Object.values(
              Object.values(data?.data?.data?.game || {})?.[0]?.market || {},
            )?.[0]?.event || {},
          );
          return [
            ...(prevState || []),
            {
              ...Object.values(data?.data?.data?.game || {})?.[0],
              subId: data?.data?.subid,
              unique_id,
              timestamp: moment?.()?.unix?.(),
            },
          ];
        }
      });
      dispatch(setIsBetSlipDataLoading(false));

      containerRef.current?.scroll?.({
        behavior: 'smooth',
        top: containerRef.current?.scrollHeight,
      });
    },
    [dispatch],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      if (data?.rid?.includes(`${SUBSCRIBE_TO_EVENTS}_`)) {
        const ridPartsArray = data?.rid?.split('_');
        const ridEventId = +ridPartsArray?.[5];
        const ridMarketId = +ridPartsArray?.[4];

        const isEventFound = eventsData?.find(
          item => item?.unique_id === +ridEventId,
        );

        if (isEventFound) {
          if (Object.keys(data?.data?.data?.game || {})?.length) {
            dataHandler(data);
          } else {
            setEventsData(prev => {
              const prevState = [...(prev || [])];
              const foundGame = prevState?.find(
                item => item?.unique_id === +ridEventId,
              );
              const foundMarket = foundGame?.market?.[+ridMarketId];
              const foundEvent = foundMarket?.event?.[+ridEventId];
              if (foundEvent) {
                prevState.find(item => item?.unique_id === +ridEventId).market[
                  +ridMarketId
                ].event[+ridEventId].isEventDeleted = true;
                prevState.find(item => item?.unique_id === +ridEventId).subId =
                  data?.data?.subid;
              }
              return prevState;
            });
          }
        }
      }

      if (data?.rid?.includes(`${UNSUBSCRIBE_LIVE_EVENT}_`)) {
        const unsubscribedEventId = +data?.rid?.split('_')?.[1];
        if (unsubscribedEventId) {
          setEventsData(prevState =>
            prevState?.filter(item => item?.unique_id !== unsubscribedEventId),
          );
        }
      }
      switch (data?.rid) {
        case SUBSCRIBE_TO_EVENTS:
          if (Object.keys(data?.data?.data?.game || {})?.length) {
            dataHandler(data);
          }
          break;
        case GET_SPORT_BONUS_RULES:
          if (data?.data?.result === 0) {
            dispatch(setBonusInfoData(data?.data?.details));
          }
          break;
        default:
          break;
      }
    },
    [dataHandler, dispatch, eventsData],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`mainBettingContainerWrapper column ${
        width > BREAKPOINT_XS ? '' : 'pb-md '
      } px-md justify-between full-width ${
        headerTabIndex === 0 ? '' : 'hidden'
      } ${
        width > BREAKPOINT_XS ? (isLoggedIn ? 'loggedIn' : 'loggedOut') : ''
      }`}>
      <div className="column mt-md scroll-auto-y scroll-1">
        {!!eventsData?.length && (
          <div className="row justify-between items-end">
            <div className="row items-center gap-5">
              <BetTypeDropdown
                betType={betType}
                setBetType={setBetType}
                allEventsData={allEventsData}
              />
              {betType === SYSTEM_BET && (
                <SystemDropdown
                  setBetType={setBetType}
                  allEventsData={allEventsData}
                  systemBetCount={systemBetCount}
                  eventsCount={allEventsData?.length}
                  setSystemBetCount={setSystemBetCount}
                />
              )}
            </div>
            <span
              onClick={removeAllHandler}
              className="redText text-caption text-underline bold-600 cursor-pointer">
              {t('removeAll')}
            </span>
          </div>
        )}
        <div className="column my-sm scroll-auto-y scroll-1" ref={containerRef}>
          {eventsData?.map((item, index) => {
            const marketId = +Object.keys(item?.market || {})?.[0];
            const eventId = +Object.keys(
              item?.market?.[marketId]?.event || {},
            )?.[0];

            return (
              <GameRow
                item={item}
                eventId={eventId}
                betType={betType}
                gameId={item?.id}
                subId={item?.subId}
                marketId={marketId}
                isFirst={index === 0}
                setBetType={setBetType}
                isFirstItem={index === 0}
                is_live={!!item?.is_live}
                allEventsData={allEventsData}
                setEventsData={setEventsData}
                isBlocked={!!item?.is_blocked}
                score2={item?.info?.score2 || 0}
                score1={item?.info?.score1 || 0}
                setCount={item?.info?.set_count}
                eventsCount={allEventsData?.length}
                key={`${eventId}_${item?.timestamp}`}
                removeItemHandler={removeItemHandler}
                notAllowToBetData={notAllowToBetData}
                currentMarket={item?.market?.[marketId]}
                isLast={index === eventsData?.length - 1}
                gameState={item?.info?.current_game_state}
                current_game_time={item?.info?.current_game_time}
                currentEvent={item?.market?.[marketId]?.event?.[eventId]}
                current_game_last_set={
                  item?.stats?.[`score_${item?.info?.current_game_state}`]
                }
                isEventDeleted={
                  item?.market?.[marketId]?.event?.[eventId]?.isEventDeleted
                }
              />
            );
          })}
          {isBetSlipDataLoading && <BetSlipGameRowSkeleton count={1} />}
          <div id="scrollView" />
        </div>
      </div>
      {!!eventsData?.length ? (
        <OddsContainer
          betType={betType}
          oddsAmount={oddsAmount}
          allEventsData={allEventsData}
          systemBetCount={systemBetCount}
          isBettingBlocked={isBettingBlocked}
          removeAllHandler={removeAllHandler}
          removeItemHandler={removeItemHandler}
          notAllowToBetData={notAllowToBetData}
          setIsSuccessModalVisible={setIsSuccessModalVisible}
        />
      ) : (
        !isBetSlipDataLoading && (
          <span className="blueText bold-500 font-18 text-center py-xxxl">
            {t('emptyBetSlip')}
          </span>
        )
      )}
      {(width > BREAKPOINT_XS || !eventsData?.length) && (
        <div className="column items-center">
          <img src={Logo} alt="logo" width={100} className="py-lg" />
          <Zigzag />
        </div>
      )}
      <BetSlipSuccessModal
        isOpen={isSuccessModalVisible}
        setIsOpen={setIsSuccessModalVisible}
      />
    </div>
  );
};

export default memo(MainBetting);
