import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import './index.scss';

import {useSelector} from 'react-redux';
import {getIsGameDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {AppButton} from '../../UI';

import {getCompetitionsArray} from '../../../Utils/GetSportId';

import {MATCH_FILTERS} from '../../../Constants/MatchFilters';

const ListItem = ({item}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const isActive = useMemo(
    () =>
      getCompetitionsArray(params?.competition)?.includes(item?.competitionId),
    [item?.competitionId, params?.competition],
  );

  const clickHandler = useCallback(
    ({regionAlias, competitionId}) => {
      navigate(
        `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
          params?.sport
        }/${regionAlias}/${competitionId}`,
      );
      window.scrollTo(0, 0);
    },
    [navigate, params?.filter, params?.sport],
  );

  return (
    <AppButton
      small
      type={8}
      active={isActive}
      disabledWithoutLayer={isGameDataLoading}
      onClick={() =>
        clickHandler({
          regionAlias: item?.regionAlias,
          competitionId: item?.competitionId,
        })
      }
      title={
        <div className="flex row items-center gap-5 px-xs">
          <img
            height={20}
            src={item?.logo}
            alt={item?.title}
            className="competitionIcon"
          />
          <span className="font-13 flex-shrink">{t(item?.title)}</span>
        </div>
      }
    />
  );
};

export default memo(ListItem);
