import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {useScreenSize} from '../../../../Hooks';

import {setMyBetsModal} from '../../../../Redux/AppSlice';
import {setHeaderTabIndex} from '../../../../Redux/BettingSlice';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';

import {AppButton, AppModal} from '../../../UI';

import {ReactComponent as SuccessIcon} from '../../../../Assets/Icons/Globals/betslip.svg';

const BetSlipSuccessModal = ({isOpen, setIsOpen}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const Header = useMemo(
    () => <span className="py-md text-subtitle2 whiteText">{t('done')}</span>,
    [t],
  );

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const myBetsClickHandler = useCallback(() => {
    dispatch(
      width > BREAKPOINT_XS
        ? setMyBetsModal({isVisible: true})
        : setHeaderTabIndex(1),
    );
    setIsOpen(false);
  }, [dispatch, setIsOpen, width]);

  return (
    <AppModal
      headerType={2}
      isOpen={isOpen}
      header={Header}
      maxWidth="400px"
      onRequestClose={onRequestClose}>
      <div
        className="flex column items-center justify-between mx-auto py-xl"
        style={{width: 300, height: 400}}>
        <div className="column justify-between items-center">
          <SuccessIcon width={150} height={130} fill="var(--lightGreen)" />
        </div>
        <span className="greenText text-subtitle2 textCenter">
          {t('betPlacedCongrats')}
        </span>
        <div className="flex column items-center gap-10 full-width">
          <AppButton
            type={3}
            title={t('myBets')}
            onClick={myBetsClickHandler}
          />
          <AppButton
            type={1}
            onClick={onRequestClose}
            title={t('seeMoreOptions')}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default memo(BetSlipSuccessModal);
