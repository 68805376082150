import {memo, useCallback, useEffect, useState} from 'react';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_MD, BREAKPOINT_XXS} from '../../../Constants/Globals';

const GameItem = ({gameItem, clickHandler}) => {
  const {width} = useScreenSize();

  const [isImageLoading, setIsImageLoading] = useState(false);

  const preloadImage = useCallback(url => {
    setIsImageLoading(true);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsImageLoading(false);
    };
    img.onerror = () => {
      setIsImageLoading(false);
    };
  }, []);

  useEffect(() => {
    preloadImage(gameItem?.icon_3);
  }, []);

  return (
    <div
      onClick={() => {
        clickHandler();
        window.scrollTo(0, 0);
      }}
      className="casinoGameItemContainer cursor-pointer"
      style={{
        width:
          width > BREAKPOINT_MD
            ? 'calc(100%  / 10)'
            : width < BREAKPOINT_XXS
            ? 'calc(100%  / 4)'
            : 'calc(100%  / 7)',
      }}>
      {isImageLoading ? (
        <div className="rowCenter full-width full-height loaderContainer">
          <span className="loader"></span>
        </div>
      ) : (
        <img
          alt={gameItem?.name}
          src={gameItem?.icon_3}
          className="block image full-width"
        />
      )}
    </div>
  );
};

export default memo(GameItem);
