import {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getLanguage} from '../../../Redux/AppSlice/AppSelectors';

import './index.scss';

import useAppFunctions from '../../../Hooks/UseAppFunctions';

import {availableLanguages} from '../../../Constants/Languages';

import {AppButton, AppModal} from '../index';

import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';

const LanguageChooser = ({type = 1}) => {
  const {t} = useTranslation();
  const {changeLanguage} = useAppFunctions();

  const language = useSelector(getLanguage);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const saveClickHandler = useCallback(() => {
    changeLanguage(selectedLanguage);
    setIsOpen(false);
  }, [changeLanguage, selectedLanguage]);

  return (
    <div className="appLanguageChooserContainer">
      <div
        onClick={() => setIsOpen(true)}
        className={`languageContainer background_${type} rowCenter gap-3 cursor-pointer`}>
        <img
          className="flag"
          alt="choosedLanguage"
          src={require(`../../../Assets/Icons/Flags/${language || 'eng'}.svg`)}
        />
        <DownTriangle fill="var(--darkenGray)" width={8} height={8} />
      </div>
      <AppModal
        isOpen={isOpen}
        maxWidth="400px"
        onRequestClose={() => setIsOpen(false)}
        onAfterOpen={() => setSelectedLanguage(language)}
        header={
          <div className="py-md">
            <span className="text-body2 blueText bold-600">
              {t('chooseLanguage')}
            </span>
          </div>
        }>
        <div className="column items-center full-width gap-5 languageItemContainer px-xxxl">
          <div className="column gap-10 full-width">
            {availableLanguages?.map(item => (
              <AppButton
                type={2}
                key={item?.status}
                active={item?.status === selectedLanguage}
                onClick={() => setSelectedLanguage(item?.status)}
                title={
                  <div className="flex items-center gap-10 justify-center">
                    <img width={30} alt={item?.status} src={item?.icon} />
                    <span className="text-body2 bold-500">
                      {t(item?.title)}
                    </span>
                  </div>
                }
              />
            ))}
          </div>
          <div className="mt-auto full-width">
            <AppButton type={3} title={t('save')} onClick={saveClickHandler} />
          </div>
        </div>
      </AppModal>
    </div>
  );
};

export default memo(LanguageChooser);
