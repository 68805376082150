import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LiveChatWidget} from '@livechat/widget-react';
import {useTranslation} from 'react-i18next';
import './Layout.scss';

import {
  setSubIds,
  setSports,
  setBoostedGameIds,
  setIsSportDataLoading,
  setActiveFilterParams,
} from '../Redux/SportSlice';
import store from '../Redux/store';
import {
  getSid,
  getWssSocket,
  getRecaptcha,
  getIsLoggedIn,
  getShowBetSlip,
  getShowSearchModal,
  getShowMobileBetSlip,
  getIsContentScrolled,
} from '../Redux/AppSlice/AppSelectors';
import {setProviders} from '../Redux/CasinoSlice';
import {setIsContentScrolled, setShowBetSlip} from '../Redux/AppSlice';
import {getSubIds, getSportsArray} from '../Redux/SportSlice/SportSelectors';
import {getShowCasinoSearchModal} from '../Redux/CasinoSlice/CasinoSelectors';

import {useScreenSize} from '../Hooks';
import useSocket from '../Hooks/UseSocket';
import useCasino from '../Hooks/UseCasino';

import {
  LICENSE_ID,
  GET_ALL_SPORTS,
  GET_BOOSTED_SPORTS,
  GET_BOOSTED_SELECTIONS,
} from '../Constants/Socket';
import {
  MATCH_FILTERS,
  FILTERS_TODAY_NAME,
  FILTERS_BOOSTED_ODDS_NAME,
  LIMIT_OF_TODAY_FILTER_GAMES,
} from '../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../Constants/Globals';
import {FixedCasinoProviders} from '../Constants/FixedCasinoProviders';

import {
  AppIntro,
  AppHeader,
  AppBetSlip,
  AppCarousel,
  AppRecaptcha,
  AppSuccessModal,
  AppCallToAction,
} from '../Components/UI';
import Footer from '../Components/Footer';
import MyBets from '../Components/MyBets';
import AuthStack from '../Components/Auth';
import Messages from '../Components/Messages';
import MainMenu from '../Components/MainMenu';
import Promotions from '../Components/Promotions';
import TopWinners from '../Components/Footer/TopWinners';
import Sports from '../Components/Home/Sports/SportsList';
import MobileHeader from '../Components/Header/MobileHeader';
import MobileBottomTab from '../Components/Home/MobileBottomTab';
import SearchModal from '../Components/Home/ActionBar/SearchModal';
import CommunityChatWidget from '../Components/CommunityChatWidget';
import CasinoSearchModal from '../Components/Casino/CasinoActionBar/CasinoSearchModal';

import {ReactComponent as BetSlipIcon} from '../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as CloseIcon} from '../Assets/Icons/Globals/closeTransparent.svg';

const Layout = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const {getCasinoProviders} = useCasino();
  const {
    getGames,
    getSports,
    getBoostedGames,
    getInitialGames,
    getBoostedSports,
    getBoostedSelections,
  } = useSocket();

  const sid = useSelector(getSid);
  const subIds = useSelector(getSubIds);
  const wssSocket = useSelector(getWssSocket);
  const recaptcha = useSelector(getRecaptcha);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const sportsData = useSelector(getSportsArray);
  const showBetSlip = useSelector(getShowBetSlip);
  const isScrolled = useSelector(getIsContentScrolled);
  const showSearchModal = useSelector(getShowSearchModal);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);
  const showCasinoSearchModal = useSelector(getShowCasinoSearchModal);

  const contentWrapperRef = useRef(null);

  const selectedGameId = useMemo(() => getParam.get('game'), [getParam]);

  const requestGames = useCallback(
    showLoader => {
      if (params?.region && params?.competition) {
        getGames({
          showLoader,
          regionAlias: params?.region,
          activeSportAlias: params?.sport,
          gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
          competitionId: params?.competition?.split('_')?.map(item => +item),
        });
      } else {
        const searchQuery = store?.getState?.()?.sport?.searchQuery;
        getInitialGames({
          showLoader,
          search: searchQuery,
          activeSportAlias: params?.sport,
          withoutLimit: params?.filter === FILTERS_TODAY_NAME,
          gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
        });
      }
    },
    [
      getGames,
      params?.sport,
      params?.filter,
      params?.region,
      getInitialGames,
      params?.competition,
    ],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      const gameCount = data?.data?.[subIds?.sports]?.sport;

      if (data?.rid === GET_ALL_SPORTS || data?.rid === GET_BOOSTED_SPORTS) {
        dispatch(setSubIds({sports: data?.data?.subid}));

        const sportsArray = Object.values(data?.data?.data?.sport || {})?.sort(
          (a, b) => a?.order - b?.order,
        );

        const initialSport = sportsArray?.[0];
        if (params?.region && params?.competition) {
          navigate({
            pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
              params?.sport
            }/${params?.region}/${params?.competition}`,
            ...(!!selectedGameId
              ? {
                  search: createSearchParams({
                    game: selectedGameId,
                  }).toString(),
                }
              : {}),
          });
        } else if (params?.sport) {
          navigate({
            pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
              params?.sport
            }`,
            ...(!!selectedGameId
              ? {
                  search: createSearchParams({
                    game: selectedGameId,
                  }).toString(),
                }
              : {}),
          });
        } else if (!!params?.filter) {
          navigate({
            pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
              initialSport?.alias
            }`,
            ...(!!selectedGameId
              ? {
                  search: createSearchParams({
                    game: selectedGameId,
                  }).toString(),
                }
              : {}),
          });
        }

        dispatch(setSports(sportsArray));
        setTimeout(() => {
          dispatch(setIsSportDataLoading(false));
        }, 100);
      } else if (data?.rid === GET_BOOSTED_SELECTIONS) {
        const gameIds = Object.keys(data?.data?.details || {})?.map(
          item => +item,
        );

        dispatch(setBoostedGameIds(gameIds));

        getBoostedSports({
          gameIdsArray: gameIds,
        });

        getBoostedGames({
          gameIdsArray: gameIds,
          activeSportAlias: params?.sport,
        });
      } else if (Object.keys(gameCount || {})?.length > 0) {
        const newSports = [...sportsData];

        Object.entries(data?.data?.[subIds?.sports]?.sport || {})?.map(el => {
          const elementElementIndex = sportsData?.findIndex(
            item => +item?.id === +el?.[0],
          );
          newSports[elementElementIndex] = {
            ...newSports[elementElementIndex],
            ...el?.[1],
          };
          if (
            el?.[1]?.game > sportsData?.[elementElementIndex]?.game &&
            newSports[elementElementIndex]?.alias === params?.sport
          ) {
            requestGames(false);
          }
        });

        dispatch(setSports(newSports));
      }
    },
    [
      dispatch,
      navigate,
      sportsData,
      requestGames,
      params?.sport,
      params?.region,
      params?.filter,
      selectedGameId,
      subIds?.sports,
      getBoostedGames,
      getBoostedSports,
      params?.competition,
    ],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    if (!!sid) {
      const activeFilterParams = store.getState()?.sport?.activeFilterParams;

      if (params?.category === 'liveCasino' || params?.tab === 'liveCasino') {
        dispatch(setProviders(FixedCasinoProviders));
      } else if (
        params?.category === 'slots' ||
        typeof params?.tab !== 'undefined'
      ) {
        dispatch(setIsSportDataLoading(true));
        getCasinoProviders()
          ?.then(res =>
            dispatch(
              setProviders(
                res?.data?.providers?.filter(item => item?.name !== 'EVB'),
              ),
            ),
          )
          ?.finally(() =>
            setTimeout(() => dispatch(setIsSportDataLoading(false)), 100),
          );
      } else if (params?.filter || params?.filter !== activeFilterParams) {
        dispatch(setActiveFilterParams(MATCH_FILTERS?.[params?.filter]?.name));
        if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
          getBoostedSelections();
        } else {
          getSports({gameFilters: MATCH_FILTERS?.[params?.filter]?.status});
        }
      }
      if (params?.sport) {
        if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
          const boostedGameIds = store.getState()?.sport?.boostedGameIds;
          if (boostedGameIds?.length > 0) {
            getBoostedGames({
              gameIdsArray: boostedGameIds,
              activeSportAlias: params?.sport,
            });
          }
        } else {
          if (params?.filter !== FILTERS_BOOSTED_ODDS_NAME) {
            requestGames();
          }
        }
      }
    }
  }, [
    sid,
    params?.tab,
    params?.sport,
    params?.filter,
    params?.region,
    params?.category,
    params?.competition,
  ]);

  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/sports' ||
      location.pathname === '/sports/'
    ) {
      navigate(`/sports/${FILTERS_TODAY_NAME}`);
    }
  }, []);

  useEffect(() => {
    if (
      showMobileBetSlip ||
      (!!selectedGameId && location?.pathname?.includes('casino'))
    ) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [location?.pathname, selectedGameId, showMobileBetSlip]);

  return (
    <div
      className={`appLayout ${
        width < BREAKPOINT_XS
          ? isLoggedIn
            ? 'loggedInPadding'
            : 'notLoggedInPadding'
          : ''
      } `}>
      {width < BREAKPOINT_XS ? <MobileHeader /> : <AppHeader />}
      <div className="contentContainerWrapper row full-width">
        {location?.pathname !== '/casino/poker' &&
          !location?.pathname?.includes('casino') && <Sports />}
        <div
          ref={contentWrapperRef}
          onScroll={e => {
            if (width < BREAKPOINT_XS) {
              dispatch(setIsContentScrolled(e?.target?.scrollTop > 50));
            }
          }}
          className={`contentWrapper flex-1 ${
            width < BREAKPOINT_XS
              ? ''
              : location?.pathname?.includes('casino')
              ? 'mx-md'
              : 'mx-sm'
          } ${
            location?.pathname?.includes('casino') ? 'px-md' : ''
          } rounded-top-borders`}>
          <div className="content full-height">
            {location.pathname !== '/jackpot' &&
              location?.pathname !== '/casino/poker' && (
                <>
                  {location?.pathname?.includes('casino') && <AppCarousel />}
                  {/*{width > BREAKPOINT_XS && <AppJackpot />}*/}
                </>
              )}
            <Outlet />
            <TopWinners />
            <AppCallToAction />
          </div>
        </div>
        <div
          className={`${
            width < BREAKPOINT_XS
              ? 'appBetSlipWrapper_mobile flex items-center justify-center full-width'
              : 'appBetSlipWrapper_desktop blueBackground pa-sm rounded-top--borders'
          } ${
            width >= BREAKPOINT_XS
              ? !!showBetSlip
                ? 'column mr-md'
                : 'hide'
              : ''
          } ${
            showMobileBetSlip && width < BREAKPOINT_XS
              ? 'slide-up'
              : 'slide-down'
          } appBetSlipWrapper`}>
          {width > BREAKPOINT_XS && (
            <div className="row items-center  justify-between">
              <div className="row items-center gap-5">
                <BetSlipIcon width={13} height={13} fill="white" />
                <span className="text-caption whiteText bold-700">
                  {t('betSlipLower')}
                </span>
              </div>
              <CloseIcon
                width={13}
                height={13}
                fill="white"
                className="cursor-pointer"
                onClick={() => dispatch(setShowBetSlip(false))}
              />
            </div>
          )}
          <div
            className={`${
              width > BREAKPOINT_XS
                ? 'semiLightBlueBackground full-height betSlipDesktopWrapper'
                : 'full-width flex justify-center'
            } rounded-borders mt-md`}>
            <AppBetSlip />
          </div>
        </div>
      </div>

      {!!getParam?.get('showPromotions') && <Promotions />}
      <AuthStack />
      <MainMenu />
      {width < BREAKPOINT_XS && <MobileBottomTab />}
      <Messages />
      <MyBets />
      <AppSuccessModal />
      {showSearchModal && <SearchModal />}
      {showCasinoSearchModal && <CasinoSearchModal />}
      {recaptcha?.isVisible && <AppRecaptcha />}
      <AppIntro />
      <LiveChatWidget
        license={LICENSE_ID?.toString?.()}
        visibility={width > BREAKPOINT_XS ? 'minimized' : 'hidden'}
      />
      <CommunityChatWidget
        show={width > BREAKPOINT_XS && isLoggedIn !== null}
      />
      {location.pathname !== '/jackpot' && <Footer />}
    </div>
  );
};

export default Layout;
