import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  useParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  getSportsArray,
  getIsGameDataLoading,
  getIsSportDataLoading,
  getActiveFilterParams,
} from '../../../../Redux/SportSlice/SportSelectors';

import {useScreenSize, useSocket} from '../../../../Hooks';

import SportItem from './SportItem';
import {AppButton} from '../../../UI';
import Providers from '../../../Casino/Providers';
import SportSkeletons from '../../../UI/Skeletons/SportSkeletons';

import {
  MATCH_FILTERS,
  FILTERS_LIVE_NAME,
  FILTERS_PROMOTIONS_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_STATUS,
  FILTERS_POPULAR_COMPETITIONS_NAME,
} from '../../../../Constants/MatchFilters';
import {GET_ALL_SPORTS_SIDEBAR} from '../../../../Constants/Socket';
import {setIsSportDataLoading} from '../../../../Redux/SportSlice';
import {
  getShowBetSlip,
  getWssSocket,
} from '../../../../Redux/AppSlice/AppSelectors';

import {ReactComponent as BetSlipIcon} from '../../../../Assets/Icons/Globals/betslip.svg';
import {setShowBetSlip} from '../../../../Redux/AppSlice';

const Sports = () => {
  const params = useParams();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {getSports} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const showBetSlip = useSelector(getShowBetSlip);
  const sportsArray = useSelector(getSportsArray);
  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const isSportDataLoading = useSelector(getIsSportDataLoading);
  const activeFilterParams = useSelector(getActiveFilterParams);

  const [selectedRegion, setSelectedRegion] = useState({});
  const [sportDataStatic, setSportDataStatic] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(width > 1024);
  const [pressedSportId, setPressedSportId] = useState(null);

  const clickHandler = useCallback(
    filterItem => {
      if (filterItem?.name !== activeFilterParams) {
        if (filterItem?.name !== FILTERS_PROMOTIONS_NAME) {
          navigate(`/sports/${filterItem?.name}`);
        } else {
          navigate({
            pathname: location?.pathname,
            search: createSearchParams({
              showPromotions: 'all',
            }).toString(),
          });
        }
      }
    },
    [activeFilterParams, location?.pathname, navigate],
  );

  useEffect(() => {
    getSports({
      rid: GET_ALL_SPORTS_SIDEBAR,
      gameFilters: FILTERS_UPCOMING_MATCHES_STATUS,
    });
  }, [getSports]);

  useEffect(() => {
    setIsMenuOpen(width > 768);
  }, [width]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      if (data?.rid === GET_ALL_SPORTS_SIDEBAR) {
        const sportsArray = Object.values(data?.data?.data?.sport || {})?.sort(
          (a, b) => a?.order - b?.order,
        );
        setSportDataStatic(sportsArray);
        setTimeout(() => {
          dispatch(setIsSportDataLoading(false));
        }, 100);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    width > 1240 && (
      <div
        className={`sportsContainerWrapper flex column gap-10 items-center ${
          isMenuOpen ? 'opened' : 'closed'
        }`}>
        <div
          className={`flex row full-width items-center ${
            isMenuOpen ? 'justify-end' : 'justify-center'
          }`}>
          {isMenuOpen && location?.pathname?.includes('casino') && (
            <span className="whiteText boldText font-13">{t('providers')}</span>
          )}
          {/*{!location?.pathname?.includes('casino') && isMenuOpen && (*/}
          {/*  <div className="row items-center gap-5 justify-center pr-sm typeContainer">*/}
          {/*    <AppButton*/}
          {/*      type={6}*/}
          {/*      width="50%"*/}
          {/*      title={t('live')}*/}
          {/*      disabledWithoutLayer={isGameDataLoading}*/}
          {/*      active={params?.filter === FILTERS_LIVE_NAME}*/}
          {/*      className="flex items-center justify-center"*/}
          {/*      onClick={() =>*/}
          {/*        clickHandler({*/}
          {/*          name: FILTERS_LIVE_NAME,*/}
          {/*        })*/}
          {/*      }*/}
          {/*    />*/}
          {/*    <AppButton*/}
          {/*      type={6}*/}
          {/*      width="50%"*/}
          {/*      title={t('prematch')}*/}
          {/*      disabledWithoutLayer={isGameDataLoading}*/}
          {/*      active={params?.filter !== FILTERS_LIVE_NAME}*/}
          {/*      className="flex items-center justify-center"*/}
          {/*      onClick={() =>*/}
          {/*        clickHandler({*/}
          {/*          name: FILTERS_UPCOMING_MATCHES_NAME,*/}
          {/*        })*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
          <img
            width={15}
            height={15}
            alt="expandMenu"
            className="expandMenu"
            src={require(
              `../../../../Assets/Icons/Globals/${
                isMenuOpen ? 'minimizeMenu' : 'maximizeMenu'
              }.svg`,
            )}
            onClick={() => setIsMenuOpen(prevState => !prevState)}
          />
        </div>
        {/*{isMenuOpen && (*/}
        {/*  <div>*/}
        {/*    <AppInput type={2} search placeholder={t('searchSports')} />*/}
        {/*  </div>*/}
        {/*)}*/}
        <div
          onClick={() => dispatch(setShowBetSlip(!showBetSlip))}
          className={`row gap-5 justify-center items-center cursor-pointer rounded-borders myBetsButton ${
            isMenuOpen ? 'opened full-width' : 'closed'
          } ${showBetSlip ? 'yellowBackground' : 'whiteBackground'}`}>
          <BetSlipIcon width={15} height={15} fill="var(--appBlue)" />
          {isMenuOpen && (
            <span className="blueText text-caption bold-700">
              {t('myBetsLower')}
            </span>
          )}
        </div>
        {isSportDataLoading && !sportDataStatic?.length && <SportSkeletons />}
        <div
          className={`sportsContainer flex column gap-10 full-width scroll-auto-y scroll-3 ${
            isSportDataLoading && !sportDataStatic?.length && 'hidden'
          }`}>
          {location?.pathname?.includes('/sports') ? (
            <>
              {params?.filter !== FILTERS_LIVE_NAME &&
                Object.values(MATCH_FILTERS).map(
                  matchItem =>
                    matchItem?.name !== FILTERS_LIVE_NAME &&
                    matchItem?.name !== FILTERS_PROMOTIONS_NAME &&
                    matchItem?.name !== FILTERS_UPCOMING_MATCHES_NAME &&
                    matchItem?.name !== FILTERS_POPULAR_COMPETITIONS_NAME && (
                      <AppButton
                        type={6}
                        key={matchItem?.name}
                        width={isMenuOpen ? '100%' : 40}
                        disabledWithoutLayer={isGameDataLoading}
                        className={
                          isMenuOpen
                            ? 'z-index-1'
                            : 'flex items-center justify-center z-index-1'
                        }
                        active={params?.filter === matchItem?.name}
                        onClick={() => clickHandler(matchItem)}
                        title={
                          <div className="flex row gap-5 items-center">
                            <img
                              alt={matchItem?.icon}
                              width={isMenuOpen ? 15 : 20}
                              height={isMenuOpen ? 18 : 20}
                              src={require(
                                `../../../../Assets/Icons/SportFilters/${matchItem?.icon}.svg`,
                              )}
                            />
                            {isMenuOpen && (
                              <span className="bold-700 text-caption ellipsis">
                                {t(matchItem?.name)}
                              </span>
                            )}
                          </div>
                        }
                      />
                    ),
                )}
              {sportsArray?.map(sportItem => (
                <SportItem
                  key={sportItem?.id}
                  sportItem={sportItem}
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  pressedSportId={pressedSportId}
                  selectedRegion={selectedRegion}
                  setSelectedRegion={setSelectedRegion}
                  setPressedSportId={setPressedSportId}
                />
              ))}
            </>
          ) : (
            <Providers isMenuOpen={isMenuOpen} />
          )}
        </div>
      </div>
    )
  );
};

export default memo(Sports);
