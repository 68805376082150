import React, {memo, useCallback, useState} from 'react';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {useSocket} from '../../../Hooks';

import {formatUnixDate} from '../../../Utils/DateFormatter';

import {ReactComponent as DeleteIcon} from '../../../Assets/Icons/Globals/delete.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';

const MessageItem = ({item, index, sentMessage = false}) => {
  const {readInbox, deleteInbox} = useSocket();

  const [isExpanded, setExpanded] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const onClick = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
    if (!item?.checked && !sentMessage) {
      readInbox({inboxId: item?.id, index});
    }
  }, [index, item?.checked, item?.id, readInbox, sentMessage]);

  const onDeleteInbox = useCallback(
    e => {
      e.stopPropagation();
      deleteInbox({inboxId: item?.id, type: sentMessage ? 'sent' : 'inbox'});
    },
    [deleteInbox, item?.id, sentMessage],
  );

  const TriangleIcon = isExpanded ? TopTriangle : DownTriangle;

  return (
    <div className="messageItemContainer">
      <div
        {...getToggleProps({
          onClick,
        })}>
        <div
          className={`row px-xl py-md items-center justify-between ${
            item?.checked || isExpanded || sentMessage
              ? 'checked rounded-top-borders'
              : 'unChecked rounded-borders'
          }`}>
          <div className="column">
            <span className="blueText text-body2 bold-700">
              {item?.subject}
            </span>
            <span className="blueText text-caption bold-500">
              {formatUnixDate(item?.date)}
            </span>
          </div>
          <div className="gap-10 row items-center">
            {item?.is_deletable && (
              <DeleteIcon
                width={15}
                height={15}
                fill="var(--appRed)"
                onClick={e => onDeleteInbox(e)}
              />
            )}
            <TriangleIcon width={10} height={10} fill="var(--appBlue)" />
          </div>
        </div>
      </div>
      <div
        {...getCollapseProps()}
        className="flex column checked rounded-bottom-borders">
        <div className="px-xl pt-sm pb-lg">
          <span className="blueText text-caption text-pre text-wrap">
            {item?.body}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(MessageItem);
