import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {getThisWeekUnix} from '../../../../Utils/GetDatePeriods';

import {GET_BETS_HISTORY} from '../../../../Constants/Socket';
import {ALL_STATE} from '../../../../Constants/BetHistoryFilters';

import Zigzag from '../../Components/Zigzag';
import SettledBetsFilters from '../../Components/SettledBetsFilters';
import BetHistoryContainer from '../../Components/BetHistoryContainer';

import BetSlipGameRowSkeleton from '../../../UI/Skeletons/BetSlipGameRowSkeleton';

const SettledBets = ({rowType}) => {
  const {t} = useTranslation();
  const {getBetsHistory} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [stateFilter, setStateFilter] = useState(ALL_STATE);
  const [toDate, setToDate] = useState(getThisWeekUnix().end_date);
  const [fromDate, setFromDate] = useState(getThisWeekUnix().start_date);

  const [betHistoryData, setBetHistoryData] = useState([]);
  const [isBetHistoryDataLoading, setIsBetHistoryDataLoading] = useState(false);

  const filteredData = useMemo(
    () =>
      stateFilter !== ALL_STATE
        ? betHistoryData
            ?.filter?.(item => item?.outcome === stateFilter)
            ?.sort?.((a, b) => b?.date_time - a?.date_time)
        : betHistoryData
            ?.filter?.(item => item?.outcome !== 0)
            ?.sort?.((a, b) => b?.date_time - a?.date_time),
    [betHistoryData, stateFilter],
  );

  useEffect(() => {
    if (fromDate && toDate) {
      setIsBetHistoryDataLoading(true);
      getBetsHistory({
        rid: GET_BETS_HISTORY,
        from_date: fromDate,
        to_date: toDate,
      });
    }
  }, [fromDate, getBetsHistory, toDate]);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event.data);
    switch (data?.rid) {
      case GET_BETS_HISTORY:
        setBetHistoryData(data?.data?.bets);
        setIsBetHistoryDataLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`settledBetsContainerWrapper full-width whiteBackground gap-10 column ${
        rowType ? 'py-md' : 'pa-md'
      } mb-md`}>
      <SettledBetsFilters
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        stateFilter={stateFilter}
        setFromDate={setFromDate}
        setStateFilter={setStateFilter}
        isBetHistoryDataLoading={isBetHistoryDataLoading}
      />
      <div
        className={`${
          rowType
            ? 'row wrap col-gutter-x-sm col-gutter-y-sm overflow-hidden'
            : 'column scroll-1 scroll-auto-y gap-5'
        }`}>
        {!!filteredData?.length && !isBetHistoryDataLoading
          ? filteredData?.map(item => (
              <BetHistoryContainer
                key={item?.id}
                betId={item?.id}
                rowType={rowType}
                type={item?.type}
                totalOdds={item?.k}
                stake={item?.amount}
                payout={item?.payout}
                events={item?.events}
                outcome={item?.outcome}
                date_time={item?.date_time}
                possibleWin={item?.possible_win}
                eventsCount={item?.events?.length}
                systemMinCount={item?.system_min_count}
                setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
                finalPayout={(item?.possible_win - item?.tax_amount).toFixed(2)}
              />
            ))
          : !isBetHistoryDataLoading && (
              <span className="blueText bold-600 text-h6 text-center py-xxxl full-width">
                {t('noDataToShow')}
              </span>
            )}
        {isBetHistoryDataLoading && (
          <div className="full-width">
            <BetSlipGameRowSkeleton count={3} />
          </div>
        )}
      </div>

      {/*<div className="column items-center">*/}
      {/*  {!rowType && !!filteredData?.length && <Zigzag />}*/}
      {/*</div>*/}
    </div>
  );
};

export default memo(SettledBets);
