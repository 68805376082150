import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const TransactionHistoryRowSkeleton = () => (
  <Skeleton
    count={6}
    height={30}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--lightGray)"
    highlightColor="var(--darkenGray)"
  />
);

export default memo(TransactionHistoryRowSkeleton);
