import React, {memo, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useOutSideClickHandler} from '../../../Hooks';

import success from '../../../Assets/Icons/Globals/success.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';

const Select = ({
  value,
  options,
  checked,
  setValue,
  disabled,
  type = 1,
  className = null,
  itemClassName = null,
  titleClassName = null,
  mainContainerClassName = null,
}) => {
  const {t} = useTranslation();

  const appSelectRef = useRef(null);

  useOutSideClickHandler(appSelectRef, () => setIsActive(false));

  const [isActive, setIsActive] = useState(false);

  const foundOption = useMemo(
    () => options?.find(item => item?.value === value),
    [options, value],
  );

  const onItemClick = useCallback(
    item => {
      setValue(item?.value);
      setIsActive(!isActive);
    },
    [isActive, setValue],
  );

  const TriangleIcon = isActive ? TopTriangle : DownTriangle;

  return (
    <div
      ref={appSelectRef}
      className={`appSelect relative full-width ${className ? className : ''}`}>
      <div
        className={`
          dropdown-btn ${
            isActive ? 'rounded-top-borders' : 'rounded-borders'
          } flex dropdown-btn_${type} row items-center justify-between ${
            !!mainContainerClassName
              ? mainContainerClassName
              : type === 1
              ? 'pa-lg'
              : 'px-md py-sm'
          } full-width full-height ${disabled ? 'disabled' : ''}
        `}
        onClick={() =>
          disabled ? () => {} : setIsActive(prevState => !prevState)
        }>
        <div className="flex row items-center gap-5">
          {foundOption?.logo && (
            <img
              width={25}
              height={15}
              alt={foundOption?.logo}
              src={foundOption?.logo}
            />
          )}
          <span
            className={`${
              disabled ? 'blueText' : type === 1 ? 'whiteText' : 'blueText'
            } text-caption-small ${titleClassName ? titleClassName : ''}`}>
            {foundOption?.name ? t(foundOption?.name) : value}
          </span>
        </div>

        {!disabled && (
          <TriangleIcon
            width={10}
            height={10}
            fill={type === 1 ? 'var(--white)' : 'var(--appBlue)'}
          />
        )}
      </div>
      {isActive && (
        <div
          className={`dropdown-content dropdown-content_${type} full-width absolute scroll-y scroll-${
            type === 1 ? 2 : 1
          }`}>
          {options?.map(item => (
            <div
              key={item?.value}
              onClick={() => onItemClick(item)}
              className={`item ${
                !!itemClassName
                  ? itemClassName
                  : type === 1
                  ? 'pa-lg'
                  : 'px-md py-sm'
              } flex row text-caption-small items-center gap-5 ${
                item?.value === value ? 'selected' : 'no-selected'
              }`}>
              {item?.logo && (
                <img alt={item?.logo} src={item?.logo} width={25} height={15} />
              )}
              {t(item?.name)}
            </div>
          ))}
        </div>
      )}
      {checked && <img alt="success" src={success} className="checkedIcon" />}
    </div>
  );
};

export default memo(Select);
