import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize, useStorage} from '../../../Hooks';

import {
  FILTERS_LIVE_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import Games from '../../../Components/Home/Games';
import ActionBar from '../../../Components/Home/ActionBar';
import HomeSports from '../../../Components/Home/HomeSports';
import FeaturedGames from '../../../Components/Home/FeaturedGames';
import FixedCompetitions from '../../../Components/Home/FixedCompetitions';
import PopularCompetitions from '../../../Components/Home/PopularCompetitions';
import FixedMarketsFilters from '../../../Components/Home/FixedMarketsFilters';

const Home = () => {
  const params = useParams();
  const {width} = useScreenSize();
  const {setGroupedByCompetition, getGroupedByCompetition} = useStorage();

  const [isGrouped, setIsGrouped] = useState(getGroupedByCompetition() || true);

  useEffect(() => {
    setGroupedByCompetition(isGrouped);
  }, [isGrouped, setGroupedByCompetition]);

  return (
    <div
      className={`homePageContainer ${width > BREAKPOINT_XS ? 'pa-md' : ''}`}>
      {params?.filter !== FILTERS_LIVE_NAME && <FeaturedGames />}
      <div className="sportsWrapper">
        <div className={`flex column ${width < BREAKPOINT_XS && 'reverse'}`}>
          {/*{width > BREAKPOINT_XS && <ActionBar />}*/}
          {width < BREAKPOINT_XS && <HomeSports />}
        </div>
        <div
          className={`${
            width > BREAKPOINT_XS
              ? 'whiteBackground mt-sm pa-md rounded-top-borders py-sm'
              : 'blueBackground pb-sm'
          }`}>
          <div className={`${width > BREAKPOINT_XS ? 'mx-sm' : ''}`}>
            <div>
              {params?.filter !== FILTERS_LIVE_NAME &&
                params?.filter !== FILTERS_UPCOMING_MATCHES_NAME && (
                  <PopularCompetitions />
                )}
              {params?.filter === FILTERS_UPCOMING_MATCHES_NAME && (
                <div className="pb-sm">
                  <FixedCompetitions />
                </div>
              )}
              {width < BREAKPOINT_XS && (
                <FixedMarketsFilters
                  isGrouped={isGrouped}
                  setIsGrouped={setIsGrouped}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex column gamesContainer scroll-3 rounded-bottom-borders pb-md">
        <Games isGrouped={isGrouped} setIsGrouped={setIsGrouped} />
      </div>
    </div>
  );
};

export default Home;
