import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import './index.scss';

import {setMainMenuModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getMainMenuModal} from '../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize} from '../../../Hooks';

import {bigNumbersPrettier} from '../../../Utils/BigNumbersPrettier';
import {fixedNumberWithoutRound} from '../../../Utils/GetOddValue';

import {
  BREAKPOINT_XS,
  BALANCE_MANAGEMENT_CARDS,
} from '../../../Constants/Globals';
import {
  M_PESA,
  HALO_PESA,
  TIGO_PESA,
  AIRTEL_NEW,
} from '../../../Constants/PaymentServices';
import {BALANCE_MANAGEMENT_TAB_ITEMS} from '../../../Constants/Menu';

import Deposit from './Deposit';
import Withdraw from './Withdraw';
import {AppButton} from '../../UI';

import {ReactComponent as CopyIcon} from '../../../Assets/Icons/MainMenu/copy.svg';

let copyTimeout = null;

const BalanceManagement = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);
  const currency = useSelector(getCurrency);
  const mainMenuModal = useSelector(getMainMenuModal);

  const [isCopied, setIsCopied] = useState(false);
  const [showBalance, setShowBalance] = useState(true);

  const userOperator = useMemo(() => {
    switch (user?.username?.slice?.(3, 5)) {
      case '62':
      case '61':
        return HALO_PESA;
      case '68':
      case '69':
      case '78':
        return AIRTEL_NEW;
      case '74':
      case '75':
      case '76':
        return M_PESA;
      case '71':
      case '67':
      case '65':
      case '77':
        return TIGO_PESA;
      default:
        break;
    }
  }, [user?.username]);

  const copyPressHandler = useCallback(async () => {
    clearTimeout(copyTimeout);
    await navigator.clipboard.writeText(user?.unique_id);
    setIsCopied(true);
    copyTimeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [user?.unique_id]);

  return (
    <div className="flex column items-center balanceManagementContainer full-width">
      <div className="flex row items-center full-width justify-center">
        <div className="col-xs-12 col-sm-10 col-md-8 flex column justify-between gap-10">
          <div className="column gap-5">
            <div className="column pb-md">
              <Swiper
                keyboard
                spaceBetween={10}
                modules={[Keyboard]}
                className="balanceCardsSwiper full-width"
                slidesPerView={width < BREAKPOINT_XS ? 'auto' : 2}>
                {BALANCE_MANAGEMENT_CARDS.map(item => (
                  <SwiperSlide
                    key={item?.id}
                    style={width < BREAKPOINT_XS ? {width: '70%'} : {}}>
                    <div
                      className={`column ${
                        item?.id === 1 ? 'greenBackground' : 'yellowBackground'
                      } py-md px-lg cardContainer`}>
                      <span
                        className={`${
                          item?.id === 1 ? 'whiteText' : 'blackText'
                        } bold-500 text-caption`}>
                        {t(item?.title)}
                      </span>
                      <span
                        className={`${
                          item?.id === 1 ? 'whiteText' : 'blackText'
                        } bold-700`}>
                        {bigNumbersPrettier(
                          item?.id === 1
                            ? showBalance
                              ? fixedNumberWithoutRound(user?.balance)
                              : '******'
                            : fixedNumberWithoutRound(user?.bonus_balance),
                        )}{' '}
                        {(showBalance || item?.id === 2) && currency}
                      </span>
                      {item?.id === 1 && (
                        <div
                          className="row pa-sm rounded-borders lightenGreenBackground self-end"
                          onClick={() =>
                            setShowBalance(prevState => !prevState)
                          }>
                          <img
                            width={17}
                            height={17}
                            src={require(
                              `../../../Assets/Icons/Globals/${
                                showBalance
                                  ? 'closedEyeWhite'
                                  : 'openedEyeWhite'
                              }.svg`,
                            )}
                            alt={
                              showBalance ? 'closedEyeWhite' : 'openedEyeWhite'
                            }
                          />
                        </div>
                      )}
                      <img
                        width={60}
                        height={60}
                        alt={item?.icon}
                        className="itemIcon"
                        src={require(
                          `../../../Assets/Icons/Globals/${item?.icon}.svg`,
                        )}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="rowCenterBetween">
                <div className="row items-center gap-10 py-sm">
                  <div className="grayBackground flex items-center justify-center nameContainer">
                    <span className="whiteText">{`${user?.first_name?.charAt(
                      0,
                    )}${user?.last_name?.charAt(0)}`}</span>
                  </div>
                  <div className="column">
                    <span className="grayText text-caption bold-700">{`${user?.first_name} ${user?.last_name}`}</span>
                    <div className="row items-center gap-5">
                      <span className="grayText text-caption-small bold-500 resetLineHeight">
                        {user?.id}
                      </span>
                      <div
                        onClick={copyPressHandler}
                        className="row items-center gap-5 bold-500">
                        <CopyIcon
                          width={11}
                          height={11}
                          icon="copy"
                          fill="var(--gray)"
                        />
                        {isCopied && (
                          <span className="grayText text-caption-small resetLineHeight">
                            copied
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gap-5 items-center">
                  <img
                    width={15}
                    height={15}
                    src={require(
                      `../../../Assets/Icons/Globals/${
                        user?.is_verified ? 'verified' : 'lost'
                      }.svg`,
                    )}
                    alt={user?.is_verified ? 'verified' : 'lost'}
                  />
                  <span className="grayText text-caption-small resetLineHeight bold-500">
                    {t(user?.is_verified ? 'verified' : 'unVerified')}
                  </span>
                </div>
              </div>
            </div>
            <div className="row gap-5 full-width">
              {BALANCE_MANAGEMENT_TAB_ITEMS.map(({title, tabIndex}) => (
                <AppButton
                  type={2}
                  key={tabIndex}
                  title={t(title)?.toUpperCase?.()}
                  active={mainMenuModal?.balanceManagementTabIndex === tabIndex}
                  onClick={() =>
                    dispatch(
                      setMainMenuModal({balanceManagementTabIndex: tabIndex}),
                    )
                  }
                />
              ))}
            </div>
            {mainMenuModal?.balanceManagementTabIndex === 0 ? (
              <Deposit userOperator={userOperator} />
            ) : (
              <Withdraw userOperator={userOperator} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BalanceManagement);
