import moment from 'moment';

import i18next from 'i18next';

export const checkIsToday = unix_timestamp => {
  const today = moment().startOf('day');
  const date = moment.unix(unix_timestamp);
  return date.isSame(today, 'd');
};

export const getGameStartTime = unix_timestamp => {
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'day').startOf('day');

  const date = moment.unix(unix_timestamp);

  return date.isSame(today, 'd')
    ? `${i18next.t('todayUpper')} - ${date.format('HH:mm')}`
    : date.isSame(tomorrow, 'day')
    ? `${i18next.t('tomorrowUpper')} - ${date.format('HH:mm')}`
    : date.format('DD/MM/YY - HH:mm');
};

export const convertDateOfBirth = date => moment(date).format('YYYY-MM-DD');
