import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import './index.scss';

import {
  getIsGameDataLoading,
  getActiveFilterParams,
} from '../../../Redux/SportSlice/SportSelectors';

import {useScreenSize} from '../../../Hooks';

import {AppButton} from '../../UI';

import {
  MATCH_FILTERS,
  FILTERS_PROMOTIONS_NAME,
  FILTERS_POPULAR_COMPETITIONS_NAME,
} from '../../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

const SportFilters = ({isMobile = false}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const activeFilterParams = useSelector(getActiveFilterParams);

  const clickHandler = useCallback(
    filterItem => {
      if (filterItem?.name !== activeFilterParams) {
        if (filterItem?.name === FILTERS_PROMOTIONS_NAME) {
          getParam.set('showPromotions', 'all');
          setGetParam(getParam);
        } else {
          window.scrollTo(0, 0);
          navigate(`/sports/${filterItem?.name}`);
        }
      }
    },
    [activeFilterParams, getParam, navigate, setGetParam],
  );

  return (
    <Swiper
      keyboard
      freeMode
      mousewheel
      spaceBetween={5}
      slidesPerView="auto"
      modules={[Keyboard, FreeMode]}
      className={`sportFiltersSwiper full-width ${
        width > BREAKPOINT_XS ? 'desktop' : 'mobile mt-sm'
      }`}>
      {Object.values(MATCH_FILTERS).map(
        matchItem =>
          !(matchItem?.name === FILTERS_POPULAR_COMPETITIONS_NAME) && (
            <SwiperSlide
              key={matchItem?.name}
              className={`${width > BREAKPOINT_XS ? 'py-sm' : ''}`}>
              <AppButton
                className="py-sm px-lg"
                type={isMobile ? 7 : 9}
                onClick={() => clickHandler(matchItem)}
                disabledWithoutLayer={isGameDataLoading}
                active={params?.filter === matchItem?.name}
                title={
                  <div className="flex row gap-8 items-center">
                    {matchItem?.icon && (
                      <img
                        width={15}
                        alt={matchItem?.icon}
                        height={matchItem?.icon === 'live' ? 10 : 17}
                        src={require(
                          `../../../Assets/Icons/SportFilters/${matchItem?.icon}.svg`,
                        )}
                      />
                    )}
                    <span className="bold-600 text-caption">
                      {t(matchItem?.name)}
                    </span>
                  </div>
                }
              />
            </SwiperSlide>
          ),
      )}
    </Swiper>
  );
};

export default memo(SportFilters);
