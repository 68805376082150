import {memo} from 'react';

import {SportIcons} from '../../../../Constants/SportIcons';

import TopTriangle from '../../../../Assets/Icons/Globals/triangleTopBlue.svg';
import DownTriangle from '../../../../Assets/Icons/Globals/downTriangleBlue.svg';

const SportButton = ({sportItem, isExpanded, isMenuOpen}) => {
  return (
    <div className="rowCenterBetween titleContainer">
      <div className="row items-center gap-5 overflow-hidden">
        <img
          alt={sportItem?.alias}
          width={isMenuOpen ? 15 : 20}
          height={isMenuOpen ? 15 : 20}
          src={SportIcons?.[sportItem?.alias]}
        />
        {isMenuOpen && (
          // <>
          //   <span className="text-caption bold-700 ellipsis">
          //     {sportItem?.name}
          //   </span>
          //   <span className="gameCount text-caption bold-700 flex-shrink mr-sm">
          //     ({sportItem?.game})
          //   </span>
          // </>
          <span className="text-caption bold-700 ellipsis">
            {sportItem?.name}
          </span>
        )}
      </div>
      {isMenuOpen && (
        <img
          width={10}
          height={10}
          alt="arrowIcon"
          className="flex-shrink"
          src={isExpanded ? TopTriangle : DownTriangle}
        />
      )}
    </div>
  );
};

export default memo(SportButton);
