import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import './MobileGameStats.scss';

import {ReactComponent as ShirtIcon} from '../../../Assets/Icons/Globals/shirt.svg';

const MobileGameStats = ({
  team1Name,
  team2Name,
  shirt1Color,
  shirt2Color,
  sortedGameInfo,
}) => {
  const {t} = useTranslation();

  const getItemColor = useCallback(
    result =>
      result === 'W'
        ? 'var(--lightGreen)'
        : result === 'L'
        ? 'var(--appRed)'
        : 'var(--appYellow)',
    [],
  );

  return (
    <div className="mobileGameStatsContainer rounded-borders pa-md mx-sm mb-sm relative">
      <div className="flex column">
        <div className="row justify-between items-center">
          <div className="column">
            <div className="row items-center gap-5">
              <ShirtIcon fill={`#${shirt1Color}`} width={15} height={15} />
              <span className="whiteText bold-600 font-15">{team1Name}</span>
            </div>
            {!!sortedGameInfo?.[0]?.Position &&
              !!sortedGameInfo?.[0]?.Points && (
                <div className="grayText bold-500 text-caption">
                  <span>{t('position')}: </span>
                  <span>{sortedGameInfo?.[0]?.Position} </span>
                  <span>{t('points')}: </span>
                  <span>{sortedGameInfo?.[0]?.Points}</span>
                </div>
              )}
          </div>
          <div className="flex blackBackground rounded-borders">
            {sortedGameInfo?.[0]?.GeneralInfoWDL?.map((item, index) => (
              <div
                key={index}
                style={{
                  borderRight:
                    index !== sortedGameInfo?.[0]?.GeneralInfoWDL?.length - 1
                      ? '1px solid gray'
                      : '',
                }}
                className="flex py-xs rowCenter statItem">
                <span
                  style={{color: getItemColor(item)}}
                  className="bold-700 font-12">
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="row justify-between items-center">
          <div className="column">
            <div className="row items-center gap-5">
              <ShirtIcon fill={`#${shirt2Color}`} width={15} height={15} />
              <span className="whiteText bold-600 font-15">{team2Name}</span>
            </div>
            {!!sortedGameInfo?.[1]?.Position &&
              !!sortedGameInfo?.[1]?.Points && (
                <div className="grayText bold-500 text-caption">
                  <span>{t('position')}: </span>
                  <span>{sortedGameInfo?.[1]?.Position} </span>
                  <span>{t('points')}: </span>
                  <span>{sortedGameInfo?.[1]?.Points}</span>
                </div>
              )}
          </div>
          <div className="flex blackBackground rounded-borders">
            {sortedGameInfo?.[1]?.GeneralInfoWDL?.map((item, index) => (
              <div
                key={index}
                className="flex py-xs rowCenter statItem"
                style={{
                  borderRight:
                    index !== sortedGameInfo?.[1]?.GeneralInfoWDL?.length - 1
                      ? '1px solid gray'
                      : '',
                }}>
                <span
                  style={{color: getItemColor(item)}}
                  className="bold-700 font-12">
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MobileGameStats);
