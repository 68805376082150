import React, {memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getMainMenuModal} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import Header from './Header';
import Profile from './Profile';
import BalanceManagement from './BalanceManagement';
import TransactionHistory from './TransactionHistory';

import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppModal, AppPrivacyPolicy} from '../UI';

const MainMenu = () => {
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const mainMenuModal = useSelector(getMainMenuModal);

  const onMainMenuRequestClose = useCallback(() => {
    dispatch(setMainMenuModal({isVisible: false}));
  }, [dispatch]);

  return (
    <AppModal
      header={<Header />}
      isOpen={mainMenuModal?.isVisible}
      onRequestClose={onMainMenuRequestClose}
      maxWidth={width < BREAKPOINT_XS ? '95vw' : undefined}>
      {mainMenuModal?.tabIndex === 0 ? (
        <BalanceManagement />
      ) : mainMenuModal?.tabIndex === 1 ? (
        <TransactionHistory />
      ) : mainMenuModal?.tabIndex === 2 ? (
        <Profile />
      ) : mainMenuModal?.tabIndex === 3 ? (
        <AppPrivacyPolicy />
      ) : null}
    </AppModal>
  );
};

export default memo(MainMenu);
