import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../Hooks';

import {setShowMobileBetSlip} from '../../Redux/AppSlice';
import {getIsLoggedIn} from '../../Redux/AppSlice/AppSelectors';
import {getIsGameDataLoading} from '../../Redux/SportSlice/SportSelectors';
import {getHeaderTabIndex} from '../../Redux/BettingSlice/BettingSelectors';

import OpenBets from './Tabs/OpenBets';
import MainBetting from './Tabs/MainBetting';
import SettledBets from './Tabs/SettledBets';
import HeaderTabs from './Components/HeaderTabs';

import {BREAKPOINT_XS} from '../../Constants/Globals';

import {ReactComponent as CloseIcon} from '../../Assets/Icons/Globals/close.svg';

const BetSlip = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const headerTabIndex = useSelector(getHeaderTabIndex);
  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const [maxHeight, setMaxHeight] = useState(
    document.querySelector('.homePageContainer')?.offsetHeight +
      document.querySelector('.topWinnersContainer')?.offsetHeight,
  );

  useEffect(() => {
    if (!isGameDataLoading) {
      setMaxHeight(
        document.querySelector('.homePageContainer')?.offsetHeight +
          document.querySelector('.topWinnersContainer')?.offsetHeight,
      );
    }
  }, [isGameDataLoading]);

  return (
    <>
      {width < BREAKPOINT_XS && (
        <div
          className="overlay"
          onClick={() => dispatch(setShowMobileBetSlip(false))}
        />
      )}
      <div
        className={`column appBetSlipContainer ${
          width < BREAKPOINT_XS ? 'lightGrayBackground' : ''
        } ${
          width < BREAKPOINT_XS
            ? 'mobile flex items-center justify-center full-width'
            : 'desktop'
        }`}
        style={
          {
            // maxHeight: maxHeight - 50, // no need for now if will be request regarding betslip max height just uncomment this line
          }
        }>
        {width < BREAKPOINT_XS && (
          <div className="yellowBackground full-width mobileBetSlipHeader row items-center justify-center py-sm relative">
            <span className="text-body1 bold-700 blueText">
              {t('myBetsLower')}
            </span>
            <CloseIcon
              width={15}
              className="absolute-top-right mr-md"
              onClick={() => dispatch(setShowMobileBetSlip(false))}
            />
          </div>
        )}
        {isLoggedIn && (
          <div className="px-md flex full-width">
            <HeaderTabs />
          </div>
        )}
        <div
          className={`full-width pa-md flex overflow-hidden betSlipContentWrapper ${
            width < BREAKPOINT_XS
              ? 'mobile'
              : `desktop ${
                  headerTabIndex !== 1 && headerTabIndex !== 2
                    ? 'full-height'
                    : ''
                }`
          }`}>
          <MainBetting />
          {headerTabIndex === 1 ? (
            <OpenBets />
          ) : headerTabIndex === 2 ? (
            <SettledBets />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default memo(BetSlip);
