import React, {
  memo,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import {useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {getShowBetSlip} from '../../../Redux/AppSlice/AppSelectors';
import {getIsPopularCompetitionsLoading} from '../../../Redux/SportSlice/SportSelectors';

import './AccordionItem.scss';

import {useScreenSize} from '../../../Hooks';

import {ReactComponent as TriangleTopIcon} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangleIcon} from '../../../Assets/Icons/Globals/downTriangle.svg';

import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';
import {
  FOOTBALL_ALIAS,
  MARKETS_TOTALS,
  MARKETS_WINNER,
  MARKETS_ODD_EVEN,
  MARKETS_HANDICAP,
  MARKETS_DOUBLE_CHANCE,
  MARKETS_BOTH_TEAM_TO_SCORE,
} from '../../../Constants/MatchFilters';
import {Flags} from '../../../Constants/Flags';
import {
  FIXED_MARKETS_FILTERS,
  FIXED_FOOTBALL_MARKETS_FILTERS,
} from '../../../Constants/FixedMarketFiltersArray';

const AccordionItem = ({
  title,
  children,
  GAMES_DATA,
  region_alias,
  defaultExpanded = false,
}) => {
  const params = useParams();
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const getMarketParam = getParam.get('market');

  const selectedGameId = useMemo(() => getParam.get('game'), [getParam]);

  const showBetSlip = useSelector(getShowBetSlip);
  const isPopularCompetitionsLoading = useSelector(
    getIsPopularCompetitionsLoading,
  );

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [height, setHeight] = useState(
    document?.querySelector?.('.sportsWrapper')?.offsetHeight +
      document?.querySelector?.('.groupByCompetitionContainer')?.offsetHeight,
  );

  const isFootball = useMemo(
    () => params?.sport === FOOTBALL_ALIAS,
    [params?.sport],
  );

  const TriangleIcon = useMemo(
    () => (expanded ? TriangleTopIcon : DownTriangleIcon),
    [expanded],
  );

  useLayoutEffect(() => {
    if (!isPopularCompetitionsLoading) {
      setHeight(
        document?.querySelector?.('.sportsWrapper')?.offsetHeight +
          document?.querySelector?.('.groupByCompetitionContainer')
            ?.offsetHeight,
      );
    }
  }, [width, isPopularCompetitionsLoading]);

  const getResultName = useCallback(
    displayKey => {
      let colCount = null;
      for (let i = 0; i < GAMES_DATA?.length; i++) {
        if (!!colCount) {
          break;
        }

        const market = Object.values(GAMES_DATA[i]?.market || {})?.find(
          marketItem => marketItem?.display_key === displayKey,
        );

        if (!!market?.col_count) {
          colCount = market?.col_count;
        }
      }
      // TODO it's written without map because the names Pano wants to change every time
      return (
        <div className="justify-end flex items-center full-height text-caption-small">
          {displayKey === MARKETS_WINNER ? (
            colCount === 2 ? (
              <div className="flex column items-center">
                <div
                  style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                  className="flex justify-between gap-5">
                  <span className="full-width text-center grayText">1</span>
                  <span className="full-width text-center grayText">2</span>
                </div>
              </div>
            ) : (
              <div className="flex column items-center">
                <div
                  style={{width: width < BREAKPOINT_XS ? 175 : 190}}
                  className="flex justify-between gap-5">
                  <span className="full-width text-center grayText">1</span>
                  <span className="full-width text-center grayText">X</span>
                  <span className="full-width text-center grayText">2</span>
                </div>
              </div>
            )
          ) : displayKey === MARKETS_BOTH_TEAM_TO_SCORE ? (
            <div className="flex column items-center" style={{maxWidth: 125}}>
              <div
                style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span className="full-width text-center grayText">
                  {t('YES')}
                </span>
                <span className="full-width text-center grayText">
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : displayKey === MARKETS_DOUBLE_CHANCE ? (
            <div className="flex column items-center">
              <div
                style={{width: width < BREAKPOINT_XS ? 175 : 190}}
                className="flex justify-between gap-5">
                <span className="full-width text-center grayText">1 X</span>
                <span className="full-width text-center grayText">1 2</span>
                <span className="full-width text-center grayText">2 X</span>
              </div>
            </div>
          ) : displayKey === MARKETS_HANDICAP ? (
            <div className="flex column items-center">
              <div
                style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span className="full-width text-center grayText">
                  {t('YES')}
                </span>
                <span className="full-width text-center grayText">
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : displayKey === MARKETS_TOTALS ? (
            <div className="flex column items-center">
              <div
                style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span className="full-width text-center grayText">
                  {t('OVER')}
                </span>
                <span className="full-width text-center grayText">
                  {t('UNDER')}
                </span>
              </div>
            </div>
          ) : MARKETS_ODD_EVEN ? (
            <div className="flex column items-center">
              <div
                style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span className="full-width text-center grayText">
                  {t('YES')}
                </span>
                <span className="full-width text-center grayText">
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
    [GAMES_DATA, t, width],
  );

  return (
    <div className="accordionItemContainer relative">
      <div
        onClick={() => setExpanded(prevState => !prevState)}
        // style={{top: !!selectedGameId ? 35 : (height || 180) - 2}}
        style={{
          top: document.querySelector('.groupByCompetitionContainer')
            ?.offsetHeight,
        }}
        className={`headerContainer row justify-between px-sm items-center rounded-borders py-sm lightGrayBackground ${
          width < BREAKPOINT_XS ? 'mobile' : 'desktop'
        }`}>
        <div style={{flexGrow: 1}} className="row items-center gap-5">
          {Flags?.[region_alias] && (
            <img
              alt="flag"
              width={15}
              height={10}
              src={Flags?.[region_alias]}
            />
          )}
          <span
            className="text-caption bold-700 textWithDotes"
            style={{maxWidth: width / 2 - 70}}>
            {title}
          </span>
        </div>
        <div>
          <div className="row gap-30">
            <div className={`sm-hide ${!!getMarketParam && 'hide'}`}>
              {getResultName(MARKETS_WINNER)}
            </div>
            {!!isFootball && (
              <div
                className={`sm-hide ${
                  (!!getMarketParam ||
                    (!!selectedGameId && width >= BREAKPOINT_MD) ||
                    (!!showBetSlip &&
                      width >= BREAKPOINT_XS &&
                      width < BREAKPOINT_MD)) &&
                  'hide'
                }`}>
                {getResultName(MARKETS_DOUBLE_CHANCE)}
              </div>
            )}
            {!!isFootball && (
              <div
                className={`sm-hide ${
                  (!!getMarketParam ||
                    (!!selectedGameId && width >= BREAKPOINT_MD) ||
                    (!!showBetSlip &&
                      width >= BREAKPOINT_XS &&
                      width < BREAKPOINT_LG)) &&
                  'hide'
                }`}>
                {getResultName(MARKETS_BOTH_TEAM_TO_SCORE)}
              </div>
            )}
            <div
              className={`sm-hide ${
                (!!getMarketParam ||
                  (!!selectedGameId && width >= BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    !!isFootball &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_LG)) &&
                'hide'
              }`}>
              {getResultName(MARKETS_HANDICAP)}
            </div>
            <div
              className={` md-hide sm-hide ${
                (!!getMarketParam ||
                  (!!selectedGameId && width > BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_LG)) &&
                'hide'
              }`}>
              {getResultName(MARKETS_TOTALS)}
            </div>
          </div>
        </div>
        <div className={`${!getMarketParam && 'hide'}`}>
          {getResultName(
            (params?.sport === FOOTBALL_ALIAS
              ? FIXED_FOOTBALL_MARKETS_FILTERS
              : FIXED_MARKETS_FILTERS
            )?.find(item => item?.title === getMarketParam)?.displayKey,
          )}
        </div>
        {width < BREAKPOINT_XS && (
          <div className="column gap-5 justify-center pl-sm">
            <TriangleIcon width={11} height={10} fill="var(--gray)" />
          </div>
        )}
      </div>
      <div className={`${expanded ? 'block' : 'hidden'}`}>{children}</div>
    </div>
  );
};

export default memo(AccordionItem);
