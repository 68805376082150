import React, {memo, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {getGameStartTime} from '../../../../Utils/TimeConvertors';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {getCombinations} from '../../../../Utils/CombinationsGenerator';

import currencyImage from '../../../../Assets/Icons/Globals/TZS.svg';
import Logo from '../../../../Assets/Icons/Globals/PigabetLogoGray.svg';
import {ReactComponent as TriangleTop} from '../../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as TriangleDown} from '../../../../Assets/Icons/Globals/downTriangleWhite.svg';

import Zigzag from '../Zigzag';
import BetHistoryItem from '../BetHistoryItem';
import CashOutActions from '../CashOutActions';
import CalculationRow from '../CalculationRow';
import CashOutCalculations from '../CashOutCalculations';

import {AppButton} from '../../../UI';

const BetHistoryContainer = ({
  type,
  stake,
  betId,
  index,
  bonus,
  events,
  payout,
  outcome,
  setIndex,
  date_time,
  totalOdds,
  tax_amount,
  finalPayout,
  eventsCount,
  possibleWin,
  cashOutAmount,
  systemMinCount,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
  rowType = false,
  setIsBetHistoryDataLoading,
}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  const [isExpanded, setExpanded] = useState(index === 0 || rowType);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const betType = useMemo(
    () =>
      type === 1
        ? 'singleUpper'
        : type === 2
        ? 'multipleUpper'
        : type === 3
        ? 'systemUpper'
        : 'chainUpper',
    [type],
  );

  const betOutComeTitle = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? 'lostUpper'
        : outcome === 2
        ? 'returnedUpper'
        : outcome === 3
        ? 'wonStateUpper'
        : 'cashedOutUpper'),
    [outcome],
  );

  const betOutComeTitleWithAmount = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? t('lostUpper')
        : outcome === 2
        ? t('returnedWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : outcome === 3
        ? t('wonUpper', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : t('cashedOutWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })),
    [outcome, t, currency, payout],
  );

  const betOutComeType = useMemo(
    () =>
      outcome &&
      (outcome === 1 ? 5 : outcome === 2 ? 13 : outcome === 3 ? 3 : 1),
    [outcome],
  );

  const TriangleIcon = isExpanded ? TriangleTop : TriangleDown;

  return (
    <div
      className={`betHistoryContainer ${
        rowType ? 'rowType col-xs-12 col-md-6 col-lg-4 col-xl-3' : ''
      }`}
      {...getToggleProps({
        onClick: () => setExpanded(prevExpanded => !prevExpanded),
      })}>
      <div className="column">
        <div className="darkenGrayBackground rounded-borders pa-md">
          <div className="row items-center justify-between gap-5">
            <div className="row items-center gap-5 overflow-hidden">
              <span className="text-caption blueText bold-700 flex-shrink">
                {t(betType)} ({eventsCount})
              </span>
              {type === 3 && !!systemMinCount && (
                <span className="text-caption blueText bold-700 flex-shrink">{`${systemMinCount}/${eventsCount} (${
                  getCombinations(eventsCount, systemMinCount)?.length
                } opt.)`}</span>
              )}
              <span className="text-caption-small grayText bold-600 ellipsis">
                {getGameStartTime(date_time)}
              </span>
            </div>
            <div className="row items-center gap-10 flex-shrink">
              {outcome ? (
                <AppButton
                  fontSize={11}
                  disabledWithoutLayer
                  type={betOutComeType}
                  className="px-md py-xs"
                  title={t(betOutComeTitle)}
                />
              ) : (
                !isExpanded && (
                  <div className="row items-center gap-5">
                    <img height={12} alt="currency" src={currencyImage} />
                    <span className="text-caption blueText bold-700">
                      {currency} {bigNumbersPrettier(stake)}
                    </span>
                  </div>
                )
              )}
              <TriangleIcon
                width={12}
                height={12}
                fill="var(--appBlue)"
                className="flex-shrink"
              />
            </div>
          </div>
        </div>
        {!isExpanded && (
          <div className="column">
            <div className="px-md semiLightGrayBackground rounded-bottom-borders gap-10 column">
              <div className="pt-md column">
                {outcome && (
                  <>
                    <CalculationRow
                      title={t('stake')}
                      titleClassName="grayText"
                      amountClassName="grayText"
                      amount={bigNumbersPrettier(stake)}
                    />
                    {outcome === 5 && (
                      <CalculationRow
                        title={t('possibleWin')}
                        titleClassName="grayText"
                        amountClassName="grayText"
                        amount={bigNumbersPrettier(possibleWin)}
                      />
                    )}
                  </>
                )}
                {outcome && outcome === 3 && (
                  <CalculationRow
                    hideCurrency
                    amount={totalOdds}
                    title={t('totalOdds')}
                    titleClassName="grayText"
                    amountClassName="grayText"
                  />
                )}
                {(outcome === 3 || !outcome) && (
                  <CalculationRow
                    title={t('finalPayout')}
                    amount={fixedNumberWithoutRound(finalPayout)}
                    titleClassName={outcome ? 'grayText' : 'blueText'}
                    amountClassName={outcome ? 'grayText' : 'greenText'}
                  />
                )}
              </div>
              {!outcome && (
                <div className="pb-md">
                  <CashOutActions
                    betId={betId}
                    cashOutAmount={cashOutAmount}
                    cashOutedAmountRef={cashOutedAmountRef}
                    setIsSuccessModalOpen={setIsSuccessModalOpen}
                  />
                </div>
              )}
              {outcome !== 1 && outcome !== 2 && outcome && (
                <div className="pb-md">
                  <AppButton
                    fontSize={11}
                    disabledWithoutLayer
                    type={betOutComeType}
                    className="py-sm px-md"
                    title={betOutComeTitleWithAmount}
                  />
                </div>
              )}
              <Zigzag type={2} />
            </div>
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        {isExpanded && (
          <div
            className={`lightGrayBackground rounded-bottom-borders column ${
              rowType ? 'rowTypeBody' : ''
            }`}>
            <div className="scroll-1 scroll-auto-y bodyContainer">
              {(events || [])?.map((event, i) => (
                <BetHistoryItem
                  betOutCome={outcome}
                  gameId={event?.game_id}
                  team1Name={event?.team1}
                  team2Name={event?.team2}
                  key={event?.selection_id}
                  team1_name={event?.team1}
                  team2_name={event?.team2}
                  eventOutCome={event?.outcome}
                  event_name={event?.event_name}
                  coeficient={event?.coeficient}
                  sportAlias={event?.sport_index}
                  market_name={event?.market_name}
                  isLast={i === events?.length - 1}
                  game_start_date={event?.game_start_date}
                  setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
                />
              ))}
            </div>
            <CashOutCalculations
              stake={stake}
              betId={betId}
              bonus={bonus}
              payout={payout}
              tax={tax_amount}
              outcome={outcome}
              totalOdds={totalOdds}
              finalPayout={finalPayout}
            />
            <div className="pt-md">
              {!outcome && (
                <CashOutActions
                  betId={betId}
                  cashOutAmount={cashOutAmount}
                  cashOutedAmountRef={cashOutedAmountRef}
                  setIsSuccessModalOpen={setIsSuccessModalOpen}
                />
              )}
            </div>
            <div
              className={`column items-center px-md flex justify-end logoContainer ${
                rowType ? 'rowType' : ''
              }`}>
              <img src={Logo} alt="logo" width={100} className="py-lg" />
              <Zigzag type={2} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BetHistoryContainer);
