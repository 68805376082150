import React, {memo} from 'react';
import {useScreenSize} from '../../../../Hooks';

import './index.scss';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';

const Zigzag = ({count = 25, type = 1}) => {
  const {width} = useScreenSize();

  return (
    width > BREAKPOINT_XS && (
      <div className="full-width row gap-5">
        {Array.apply(null, Array(count)).map((_, index) => (
          <div key={index} className={`trapezoid trapezoid_${type}`} />
        ))}
      </div>
    )
  );
};

export default memo(Zigzag);
