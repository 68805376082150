import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import {AppButton, AppInput} from '../../UI';
import SearchGamesSkeletons from '../../UI/Skeletons/SearchGamesSkeletons';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {setShowCasinoSearchModal} from '../../../Redux/CasinoSlice';

import {ReactComponent as CloseIcon} from '../../../Assets/Icons/Globals/close.svg';

import {
  CATEGORY_LIVE_CASINO_ID,
  PROVIDERS_ALL_GAMES_NAME,
} from '../../../Constants/CasinoMenuFilter';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import './index.scss';
const CasinoSearchModal = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const [getParam, setGetParam] = useSearchParams();

  const {
    getSearchedValues,
    setSearchedValuesToStorage,
    getLastPlayedGamesFromStorage,
  } = useStorage();
  const {getCasinoGames, goToCasinoGameScreen} = useCasino();

  const inputRef = useRef(null);
  const timeOutRef = useRef(null);
  const searchValueRef = useRef(null);

  const params = useParams();
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevSearchedValues, setPrevSearchedValues] = useState([]);

  const searchValue = useMemo(() => getParam.get('search'), [getParam]);

  const onCancel = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
    dispatch(setShowCasinoSearchModal(false));
  }, [dispatch, getParam, setGetParam]);

  const onClearSearch = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
  }, [getParam, setGetParam]);

  const onItemClick = useCallback(
    (e, gameItem) => {
      e.preventDefault();
      e.stopPropagation();

      getParam.delete('search');
      setGetParam(getParam);

      dispatch(setShowCasinoSearchModal(false));
      goToCasinoGameScreen(gameItem);
    },
    [dispatch, getParam, goToCasinoGameScreen, setGetParam],
  );

  const closeIconHandler = useCallback(
    (e, item) => {
      e.stopPropagation();
      setSearchedValuesToStorage(item?.val, true, true);
      setTimeout(() => {
        setPrevSearchedValues(getSearchedValues(true));
      }, 100);
    },
    [getSearchedValues, setSearchedValuesToStorage],
  );

  const handleInputChange = useCallback(
    text => {
      if (text?.trim()?.length === 0) {
        getParam.delete('search');
        setGetParam(getParam);
        setGames([]);
      } else {
        setIsLoading(true);
        getParam.set('search', text);
        setGetParam(getParam);

        clearTimeout(timeOutRef.current);

        const externalIdsString = getLastPlayedGamesFromStorage()
          ?.map(game => game?.gameId)
          ?.join(',');

        timeOutRef.current = setTimeout(() => {
          getCasinoGames({
            ...(location?.pathname?.includes('lastPlayed')
              ? {limit: 30, searchQuery: text, externalIds: externalIdsString}
              : {
                  searchQuery: text,
                  provider:
                    params?.provider === PROVIDERS_ALL_GAMES_NAME
                      ? ''
                      : params?.provider,
                  category:
                    params?.category === 'liveCasino'
                      ? CATEGORY_LIVE_CASINO_ID
                      : '',
                }),
          })
            ?.then(res => {
              if (searchValueRef.current === text) {
                setGames(res?.data?.games);
              }
            })
            ?.finally(() => setIsLoading(false));
        }, 300);
      }
    },
    [
      getParam,
      setGetParam,
      getCasinoGames,
      params?.provider,
      params?.category,
      location?.pathname,
      getLastPlayedGamesFromStorage,
    ],
  );

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    inputRef?.current?.focus?.();

    getParam.delete('search');
    setGetParam(getParam);

    setPrevSearchedValues(getSearchedValues(true));

    return () => {
      setPrevSearchedValues(getSearchedValues(true));
      setSearchedValuesToStorage(searchValueRef?.current, false, true);
    };
  }, []);

  return (
    <div
      className={`fixed ${
        !(width < BREAKPOINT_XS) && 'items-center'
      } justify-center flex full-width full-height searchModalContainer z-max`}
      onClick={() => dispatch(setShowCasinoSearchModal(false))}>
      <div
        style={{
          top: 15,
          ...(width < BREAKPOINT_XS
            ? {left: 0, right: 0}
            : {left: 15, right: 15}),
        }}
        className={`fixed wrapper ${width < BREAKPOINT_XS && 'mobileWrapper'}`}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <div className="flex row items-center gap-5">
          <AppInput
            search
            type={4}
            height={35}
            inputRef={inputRef}
            className="pa-sm mobileSize"
            onChange={handleInputChange}
            placeholder={t('searchTeam')}
            inputValue={searchValue || ''}
          />
          <AppButton
            type={5}
            onClick={onCancel}
            className={`resetButton`}
            title={
              <span className="text-caption bold-500">{t('cancelLower')}</span>
            }
          />
        </div>
        <div className="px-xl py-md mt-sm gamesContainer">
          {games?.length === 0 && !isLoading ? (
            <div className="flex row items-center justify-between mb-xl">
              <span>Type more to get results ... </span>
              <AppButton
                onClick={onClearSearch}
                className="clearSearchButton"
                title={
                  <span className="text-caption-small">{t('clearSearch')}</span>
                }
              />
            </div>
          ) : (
            <div className="scroll-auto-y scroll-1 listContainer">
              {isLoading ? (
                <SearchGamesSkeletons count={2} />
              ) : games?.length === 0 ? (
                <div className="flex justify-center">
                  <span className="textCenter font-24 py-xl full-width blueText">
                    {t('noGames')}
                  </span>
                </div>
              ) : (
                (games || [])?.map((gameItem, index) => (
                  <div
                    key={index}
                    onClick={e => onItemClick(e, gameItem)}
                    className="flex items-center my-sm cursor-pointer casinoSearchedItemContainer pa-sm">
                    <img src={gameItem?.icon_3} className="searchedItemImage" />
                    <span className="text-body2 blueText bold-500 ml-sm">
                      {gameItem?.name}
                    </span>
                  </div>
                ))
              )}
            </div>
          )}
          {!!prevSearchedValues?.length && (
            <div className="mt-lg">
              <span>{t('resentSearches')}</span>
              <div className="flex gap-10 mt-md wrap">
                {(prevSearchedValues || [])?.map((item, index) => (
                  <AppButton
                    key={index}
                    onClick={() => handleInputChange(item?.val)}
                    title={
                      <div className="flex items-center">
                        <span>{item?.val}</span>
                        <CloseIcon
                          width={10}
                          height={10}
                          fill="var(--appRed)"
                          className="ml-lg mt-xxs cursor-pointer"
                          onClick={e => closeIconHandler(e, item)}
                        />
                      </div>
                    }
                    className="resentSearchButton px-md py-sm"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CasinoSearchModal);
