import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {
  formatEventName,
  formatGameStates,
} from '../../../../Utils/EventNamesFormatter';
import {getDateColor} from '../../../../Utils/DateFormatter';
import {getGameStartTime} from '../../../../Utils/TimeConvertors';

import {
  GET_MATCH_SCORES,
  GET_SPECIFIC_GAME_STATS,
} from '../../../../Constants/Socket';

import EventItem from '../../../Home/Games/EventItem';

import {AppButton, AppLiveIndicator} from '../../../UI';
import MatchResultInfoSkeleton from '../../../UI/Skeletons/MatchResultInfoSkeleton';
import BetItemInfoSkeleton from '../../../UI/Skeletons/BetItemInfoSkeleton';

const BetHistoryItem = ({
  gameId,
  isLast,
  team1Name,
  team2Name,
  coeficient,
  sportAlias,
  betOutCome,
  event_name,
  market_name,
  eventOutCome,
  game_start_date,
  setIsBetHistoryDataLoading,
}) => {
  const {
    getMatchScores,
    getBetsHistory,
    getOpenedBetsCount,
    getSpecificGameStats,
    unSubscribeLiveEvents,
    subscribeToCashOutAmount,
  } = useSocket();
  const {t} = useTranslation();

  const wssSocket = useSelector(getWssSocket);

  const [subId, setSubId] = useState(null);
  const [isLive, setIsLive] = useState(null);
  const [setCount, setSetCount] = useState(null);
  const [team1Score, setTeam1Score] = useState(0);
  const [team2Score, setTeam2Score] = useState(0);
  const [matchResult, setMatchResult] = useState(null);
  const [isMatchResultDataLoading, setIsMatchResultDataLoading] =
    useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentGameTime, setCurrentGameTime] = useState(null);
  const [currentGameState, setCurrentGameState] = useState(null);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(null);

  const eventOutComeTitle = useMemo(
    () =>
      eventOutCome === 1
        ? 'lostUpper'
        : eventOutCome === 3
        ? 'wonStateUpper'
        : '',
    [eventOutCome],
  );

  const eventOutComeIcon = useMemo(
    () =>
      eventOutCome === 3
        ? 'success'
        : eventOutCome === 1
        ? 'lost'
        : eventOutCome === 2
        ? 'success'
        : null,
    [eventOutCome],
  );

  const betOutComeType = useMemo(
    () =>
      eventOutCome &&
      (eventOutCome === 1
        ? 5
        : eventOutCome === 2
        ? 13
        : eventOutCome === 3
        ? 3
        : 4),
    [eventOutCome],
  );

  useEffect(() => {
    if (gameId && eventOutCome === 0) {
      setIsDataLoading(true);
      getSpecificGameStats({
        gameId,
        rid: `${GET_SPECIFIC_GAME_STATS}_${gameId}`,
      });
    }
  }, [eventOutCome, gameId, getSpecificGameStats]);

  useEffect(
    () => () => subId && unSubscribeLiveEvents({subId}),
    [subId, unSubscribeLiveEvents],
  );

  useEffect(() => {
    if (gameId) {
      setIsMatchResultDataLoading(true);
      getMatchScores({
        rid: `${GET_MATCH_SCORES}_${gameId}`,
        match_id_list: [gameId],
      });
    }
  }, [gameId, getMatchScores]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case `${GET_SPECIFIC_GAME_STATS}_${gameId}`:
          if (data?.data?.subid) {
            setSubId(data?.data?.subid);
          }
          const currentGame = data?.data?.data?.game?.[gameId];
          if (
            currentGame?.info?.score1 &&
            currentGame?.info?.current_game_state !== 'notstarted'
          ) {
            setTeam1Score(currentGame?.info?.score1);
            setTeam2Score(currentGame?.info?.score2);
          }
          if (typeof currentGame?.is_live !== 'undefined') {
            setIsLive(!!currentGame?.is_live);
          }
          if (typeof currentGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(currentGame?.info?.current_game_state);
          }
          if (
            typeof currentGame?.stats?.[
              `score_${currentGame?.info?.current_game_state}`
            ] !== 'undefined'
          ) {
            setCurrentGameLastSet(
              currentGame?.stats?.[
                `score_${currentGame?.info?.current_game_state}`
              ],
            );
          }
          if (typeof currentGame?.info?.current_game_time !== 'undefined') {
            setCurrentGameTime(currentGame?.info?.current_game_time);
          }

          if (typeof currentGame?.info?.set_count !== 'undefined') {
            setSetCount(currentGame?.info?.set_count);
          }

          setIsDataLoading(false);
          break;
        case `${GET_MATCH_SCORES}_${gameId}`:
          const score = data?.data?.details?.[0]?.score;
          const index = score?.indexOf('(') || score?.indexOf(',');
          setMatchResult(score?.slice(0, index)?.trim?.()?.replace(',', ''));
          setIsMatchResultDataLoading(false);
          break;
        default:
          break;
      }
      if (data?.data?.[subId]) {
        const changedGame = data?.data?.[subId]?.game?.[gameId];
        if (changedGame) {
          if (typeof changedGame?.info?.score1 !== 'undefined') {
            setTeam1Score(changedGame?.info?.score1);
          }
          if (typeof changedGame?.info?.score2 !== 'undefined') {
            setTeam2Score(changedGame?.info?.score2);
          }
          if (typeof changedGame?.info?.current_game_time !== 'undefined') {
            setCurrentGameTime(changedGame?.info?.current_game_time);
          }
          if (typeof changedGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(changedGame?.info?.current_game_state);
          }
          if (
            typeof changedGame?.stats?.[`score_${currentGameState}`] !==
            'undefined'
          ) {
            setCurrentGameLastSet(prevState => ({
              ...prevState,
              ...changedGame?.stats?.[`score_${currentGameState}`],
            }));
          }
        } else {
          setTimeout(() => {
            setIsBetHistoryDataLoading(true);
            setIsMatchResultDataLoading(true);
            subscribeToCashOutAmount();
            getBetsHistory({outcome: 0});
            getOpenedBetsCount();
            getMatchScores({
              rid: `${GET_MATCH_SCORES}_${gameId}`,
              match_id_list: [gameId],
            });
          }, 1000);
        }
      }
    },
    [
      subId,
      gameId,
      getMatchScores,
      getBetsHistory,
      currentGameState,
      getOpenedBetsCount,
      subscribeToCashOutAmount,
      setIsBetHistoryDataLoading,
    ],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="betHistoryItemContainer">
      <div className="semiLightGrayBackground gameInfoContainer row gap-5 justify-between full-width py-sm px-md">
        <div className="row items-center gap-5 teamNamesContainer full-width overflow-hidden">
          {!!sportAlias && (
            <img
              width={25}
              alt={sportAlias}
              className="flex-shrink"
              src={require(`../../../../Assets/Icons/Sport/${sportAlias}.svg`)}
            />
          )}
          <div className="column blueText bold-700 text-caption overflow-hidden">
            <span className="ellipsis">{team1Name}</span>
            <span className="ellipsis">{team2Name}</span>
          </div>
        </div>
        {isDataLoading ? (
          <BetItemInfoSkeleton />
        ) : (
          <div className="column items-end justify-center flex-shrink">
            <div className="row items-center gap-5">
              {isLive && <AppLiveIndicator />}
            </div>
            {isLive ? (
              <div className="row items-center gap-5 text-caption">
                {isLive &&
                  !!team1Score &&
                  !!team2Score &&
                  typeof team1Score !== 'undefined' &&
                  typeof team2Score !== 'undefined' && (
                    <div className="text-caption bold-600 row items-center">
                      <span className="orangeText">{team1Score}</span>
                      <span className="orangeText">-</span>
                      <span className="orangeText">{team2Score}</span>
                    </div>
                  )}
                {setCount !== 1 &&
                  !currentGameTime &&
                  typeof currentGameLastSet?.team1_value !== 'undefined' && (
                    <span className="orangeText bold-600">
                      {currentGameLastSet?.team1_value}-
                      {currentGameLastSet?.team2_value}
                    </span>
                  )}
                {currentGameState &&
                  typeof currentGameState !== 'undefined' && (
                    <span className="grayText">
                      {t(
                        formatGameStates({
                          sportAlias,
                          gameState: currentGameState,
                        }),
                      )}
                    </span>
                  )}
                {currentGameTime && currentGameTime !== 'undefined' && (
                  <span className="grayText">{currentGameTime?.trim?.()}`</span>
                )}
              </div>
            ) : eventOutComeTitle ? (
              <div>
                <AppButton
                  fontSize={10}
                  disabledWithoutLayer
                  type={betOutComeType}
                  className="px-md py-sm"
                  title={t(eventOutComeTitle)}
                />
              </div>
            ) : (
              <span
                className="grayText text-caption bold-500"
                style={getDateColor(getGameStartTime(game_start_date))}>
                {getGameStartTime(game_start_date)}
              </span>
            )}
            {isMatchResultDataLoading ? (
              <MatchResultInfoSkeleton />
            ) : (
              matchResult &&
              !isLive && (
                <span className="bold-600 text-caption text-right blueText ellipsis">
                  {matchResult}
                </span>
              )
            )}
          </div>
        )}
      </div>
      <div
        className={`eventInfoContainer lightGrayBackground column pa-md ${
          isLast ? 'rounded-bottom-borders' : ''
        } ${!isLast ? 'dashed' : ''}`}>
        <div className="row items-center justify-between">
          <div className="column">
            <span className="text-caption-small grayText bold-600">
              {formatEventName({
                team1Name,
                team2Name,
                name: market_name,
              })}
            </span>
            <div className="row items-center gap-5">
              <span className="text-caption blueText bold-700">
                {formatEventName({
                  team1Name,
                  team2Name,
                  name: event_name,
                })}
              </span>
              {eventOutComeIcon && (
                <img
                  width={15}
                  alt={eventOutComeIcon}
                  src={require(
                    `../../../../Assets/Icons/Globals/${eventOutComeIcon}.svg`,
                  )}
                />
              )}
            </div>
          </div>
          <div className="flex gap-5">
            <EventItem type={2} price={coeficient} disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BetHistoryItem);
