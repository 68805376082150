import React, {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const BetSlipGameRowSkeleton = ({count}) => (
  <Skeleton
    width="100%"
    height={110}
    count={count}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--lightGray)"
    highlightColor="var(--semiLightGray)"
  />
);

export default memo(BetSlipGameRowSkeleton);
