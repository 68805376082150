import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getOpenBetsCount} from '../../../../Redux/BettingSlice/BettingSelectors';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {getTodayUnix} from '../../../../Utils/GetDatePeriods';

import {
  GET_OPEN_BETS_HISTORY,
  SUBSCRIBE_TO_CASH_OUT_AMOUNT,
} from '../../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {TODAY_STATE} from '../../../../Constants/BetHistoryFilters';

import Zigzag from '../../Components/Zigzag';
import OpenBetsContainer from '../../Components/OpenBetsContainer';
import SettledBetsFilters from '../../Components/SettledBetsFilters';
import CashOutSuccessModal from '../../Components/CashOutSuccessModal';

import BetSlipGameRowSkeleton from '../../../UI/Skeletons/BetSlipGameRowSkeleton';

import Logo from '../../../../Assets/Icons/Globals/PigabetLogoLightBlue.svg';

const OpenBets = ({rowType = false}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const {getBetsHistory, subscribeToCashOutAmount} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const openBetsCount = useSelector(getOpenBetsCount);

  const cashOutSubIdRef = useRef(null);
  const cashOutedAmountRef = useRef(null);

  const [toDate, setToDate] = useState(getTodayUnix().end_date);
  const [betHistoryData, setBetHistoryData] = useState([]);
  const [fromDate, setFromDate] = useState(getTodayUnix().start_date);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isBetHistoryDataLoading, setIsBetHistoryDataLoading] = useState(false);

  useEffect(() => {
    if (isSuccessModalOpen) {
      setIsBetHistoryDataLoading(true);
      getBetsHistory({outcome: 0});
      subscribeToCashOutAmount();
    }
  }, [isSuccessModalOpen]);

  useEffect(() => {
    if (fromDate && toDate) {
      setBetHistoryData([]);
      setIsBetHistoryDataLoading(true);
      getBetsHistory({
        rid: GET_OPEN_BETS_HISTORY,
        from_date: fromDate,
        to_date: toDate,
        outcome: 0,
      });
      subscribeToCashOutAmount();
    }
  }, [fromDate, getBetsHistory, subscribeToCashOutAmount, toDate]);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event.data);
    switch (data?.rid) {
      case GET_OPEN_BETS_HISTORY:
        setBetHistoryData(data?.data?.bets);
        setIsBetHistoryDataLoading(false);
        break;
      case SUBSCRIBE_TO_CASH_OUT_AMOUNT:
        if (data?.data?.subid) {
          cashOutSubIdRef.current = data?.data?.subid;
        }
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`whiteBackground gap-10 column ${
        rowType ? '' : 'px-md openBetsContainerWrapper'
      } mb-md justify-between full-width pt-md`}>
      {rowType && (
        <SettledBetsFilters
          toDate={toDate}
          fromDate={fromDate}
          setToDate={setToDate}
          showStateFilters={false}
          setFromDate={setFromDate}
          defaultDateFilter={TODAY_STATE}
          isBetHistoryDataLoading={isBetHistoryDataLoading}
        />
      )}
      <div
        className={`${
          rowType
            ? 'row wrap col-gutter-x-sm col-gutter-y-sm overflow-hidden'
            : 'column scroll-1 scroll-auto-y gap-5'
        }`}>
        {!!betHistoryData?.length
          ? betHistoryData?.map((item, index) => (
              <OpenBetsContainer
                index={index}
                key={item?.id}
                betId={item?.id}
                rowType={rowType}
                type={item?.type}
                totalOdds={item?.k}
                bonus={item?.bonus}
                stake={item?.amount}
                events={item?.events}
                cash_out={item?.cash_out}
                date_time={item?.date_time}
                tax_amount={item?.tax_amount}
                cashOutSubIdRef={cashOutSubIdRef}
                eventsCount={item?.events?.length}
                systemMinCount={item?.system_min_count}
                cashOutedAmountRef={cashOutedAmountRef}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
                finalPayout={item?.possible_win - item?.tax_amount}
                setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
              />
            ))
          : !isBetHistoryDataLoading && (
              <span className="blueText bold-600 text-h6 text-center py-xxxl full-width">
                {t('noDataToShow')}
              </span>
            )}

        <CashOutSuccessModal
          isOpen={isSuccessModalOpen}
          setIsOpen={setIsSuccessModalOpen}
          cashOutedAmountRef={cashOutedAmountRef}
        />
        {isBetHistoryDataLoading && (
          <div className="full-width">
            <BetSlipGameRowSkeleton count={openBetsCount || 1} />
          </div>
        )}
      </div>
      {!rowType && width > BREAKPOINT_XS && (
        <div className="column items-center">
          <img src={Logo} alt="logo" width={100} className="py-lg" />
          <Zigzag />
        </div>
      )}
    </div>
  );
};

export default memo(OpenBets);
