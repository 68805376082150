import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';
import {setIsBetSlipDataLoading} from '../../../Redux/BettingSlice';
import {getActiveFilterParams} from '../../../Redux/SportSlice/SportSelectors';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import {getPriceLabel} from '../../../Utils/GetOddValue';

import {
  MARKETS_TOTALS,
  MARKETS_HANDICAP,
  FILTERS_BOOSTED_ODDS_NAME,
} from '../../../Constants/MatchFilters';
import {
  UNSUBSCRIBE_BULK,
  UNSUBSCRIBE_LIVE_EVENT,
} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as Triangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as BlockIcon} from '../../../Assets/Icons/Globals/blockedGame.svg';
import {ReactComponent as BoostedOddIcon} from '../../../Assets/Icons/SportFilters/boostedOdds.svg';

const EventItem = ({
  base,
  price,
  gameId,
  eventId,
  betSlip,
  oldPrice,
  marketId,
  disabled,
  isBlocked,
  lastPrice,
  type = 1,
  displayKey,
  isSuspended,
  isLast = null,
  isFirst = null,
  showBase = false,
  showBoosted = false,
  featuredGame = false,
}) => {
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const {getBetSlipDataFromStorage} = useStorage();
  const {subscribeToEvent, unSubscribeLiveEvents} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const activeFilterParams = useSelector(getActiveFilterParams);

  const [selectedEventId, setSelectedEventId] = useState(
    getBetSlipDataFromStorage()?.find(item => item?.unique_id === eventId)
      ?.unique_id || null,
  );

  const selectedGameId = useMemo(() => getParam.get('game'), [getParam]);

  const isOddIncreased = useMemo(() => price > lastPrice, [lastPrice, price]);

  const clickHandler = useCallback(
    e => {
      e.stopPropagation();
      setSelectedEventId(eventId);
      if (eventId === selectedEventId) {
        setSelectedEventId(null);
        unSubscribeLiveEvents({
          subId: getBetSlipDataFromStorage()?.find(
            item => item?.unique_id === eventId,
          )?.subId,
          rid: `${UNSUBSCRIBE_LIVE_EVENT}_${eventId}`,
        });
      } else {
        subscribeToEvent({eventId, gameId, marketId});
        setSelectedEventId(eventId);
        dispatch(setIsBetSlipDataLoading(true));
      }
    },
    [
      gameId,
      eventId,
      dispatch,
      marketId,
      selectedEventId,
      subscribeToEvent,
      unSubscribeLiveEvents,
      getBetSlipDataFromStorage,
    ],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      if (data?.rid?.includes(`${UNSUBSCRIBE_LIVE_EVENT}_`)) {
        const unsubscribedEventId = +data?.rid?.split('_')?.[1];
        if (unsubscribedEventId === eventId) {
          setSelectedEventId(null);
        }
      }
      if (data?.rid === `${UNSUBSCRIBE_BULK}_betSlip`) {
        setSelectedEventId(null);
      }
    },
    [eventId],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <>
      {showBase && !!base && (
        <div
          className={`flex gap-2 grayText font-10 ${
            width > BREAKPOINT_XS ? 'absolute' : ''
          }`}
          style={{bottom: width > BREAKPOINT_XS ? 30 : 50}}>
          <span className={`${!base ? 'invisible' : ''}`}>
            {displayKey === MARKETS_HANDICAP && +base > 0 && '+'}
            {base || 'emptyText'}
          </span>
        </div>
      )}
      <div
        onClick={
          disabled || !price || typeof price === 'undefined' || isSuspended
            ? () => {}
            : clickHandler
        }
        className={`rowCenter relative boldText ${
          disabled ? '' : 'cursor-pointer'
        } eventItemContainer ${
          width < BREAKPOINT_XS && !betSlip
            ? `mobile ${
                isFirst ? 'borderRadiusFirst' : isLast ? 'borderRadiusLast' : ''
              } ${
                (typeof isFirst !== 'boolean' && typeof isLast !== 'boolean') ||
                (typeof price === 'undefined' && !isSuspended)
                  ? 'rounded-borders'
                  : ''
              }`
            : `desktop ${featuredGame ? '' : 'rounded-borders'} `
        } ${isBlocked ? 'blocked' : 'not-blocked'} color-type-${type} ${
          eventId === selectedEventId ? 'selected' : 'no-selected'
        } ${
          +selectedGameId === +gameId &&
          eventId === selectedEventId &&
          'selectedBorders'
        } ${
          activeFilterParams === FILTERS_BOOSTED_ODDS_NAME && showBoosted
            ? 'boostedOdds gap-5'
            : ''
        } ${
          featuredGame
            ? `featuredGame ${
                isFirst ? 'borderRadiusFirst' : isLast ? 'borderRadiusLast' : ''
              } ${
                (typeof isFirst !== 'boolean' && typeof isLast !== 'boolean') ||
                (typeof price === 'undefined' && !isSuspended)
                  ? 'rounded-borders'
                  : ''
              }`
            : ''
        } ${(isSuspended || !price) && 'suspendedBackground'}`}>
        {activeFilterParams === FILTERS_BOOSTED_ODDS_NAME && showBoosted && (
          <BoostedOddIcon width={15} height="100%" />
        )}
        {isSuspended || !price ? (
          <BlockIcon fill="var(--white)" width={15} height={15} />
        ) : (
          <span
            className={`${oldPrice ? 'text-strike' : ''} ${
              !betSlip && width < BREAKPOINT_XS ? 'font-14' : 'font-12'
            }`}>
            {!!price ? getPriceLabel(price) : '-'}
          </span>
        )}
        {typeof lastPrice !== 'undefined' && !isSuspended && !!price && (
          <Triangle
            width={15}
            height={15}
            className={`oddChangeIcon absolute ${
              !isOddIncreased ? 'increased' : 'decreased'
            }`}
            fill={isOddIncreased ? 'var(--lightGreen)' : 'var(--appRed)'}
          />
        )}
      </div>
    </>
  );
};

export default memo(EventItem);
