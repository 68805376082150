import React, {memo, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';

import './index.scss';

import {setMessagesModal} from '../../Redux/AppSlice';
import {getMessagesModal} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import {BREAKPOINT_XS} from '../../Constants/Globals';
import {MESSAGES_MODAL_ITEMS} from '../../Constants/Menu';

import {Inbox, NewMessage, Sent} from './Tabs';

import {AppButton, AppModal} from '../UI';

const Messages = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const messagesModal = useSelector(getMessagesModal);

  const [pageIndex, setPageIndex] = useState(0);

  const [newInboxesCount, setNewInboxesCount] = useState(null);

  const onRequestClose = useCallback(() => {
    dispatch(setMessagesModal({isVisible: false}));
  }, [dispatch]);

  const Header = useMemo(
    () => (
      <div className="flex items-center pa-lg">
        <span className="text-subtitle2 blueText bold-600">
          {t('messages')?.toUpperCase?.()}
        </span>
      </div>
    ),
    [t],
  );

  return (
    <AppModal
      header={Header}
      onRequestClose={onRequestClose}
      isOpen={messagesModal?.isVisible}
      maxWidth={width < BREAKPOINT_XS ? '95vw' : undefined}>
      <div className="flex column fit px-xxl messagesContainer">
        <div className="py-md">
          <Swiper
            keyboard
            mousewheel
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Keyboard]}
            className="messagesItemsSwiper">
            {MESSAGES_MODAL_ITEMS?.map(({title, page}) => (
              <SwiperSlide key={title}>
                <AppButton
                  small
                  type={2}
                  width={180}
                  fontSize={11}
                  title={
                    page === 0 ? (
                      <div className="row gap-5 items-center justify-center py-xxs">
                        <span className="font-11 bold-700">{t(title)}</span>
                        {!!newInboxesCount && (
                          <div className="flex items-center justify-center unReadMessagesCounterContainer">
                            <span className="font-10 bold-700 whiteText">
                              {newInboxesCount}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      t(title)
                    )
                  }
                  active={pageIndex === page}
                  onClick={() => setPageIndex(page)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="tabsWrapper">
          {pageIndex === 0 ? (
            <Inbox setNewInboxesCount={setNewInboxesCount} />
          ) : pageIndex === 1 ? (
            <Sent />
          ) : pageIndex === 2 ? (
            <NewMessage setPageIndex={setPageIndex} />
          ) : null}
        </div>
      </div>
    </AppModal>
  );
};

export default memo(Messages);
