import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  setMyBetsModal,
  setMessagesModal,
  setMainMenuModal,
} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';

import {MAIN_MENU_DROPDOWN_ITEMS} from '../../../Constants/Menu';

import {useOutSideClickHandler, useSocket, useStorage} from '../../../Hooks';

import {ReactComponent as CopyIcon} from '../../../Assets/Icons/MainMenu/copy.svg';

const MenuDropDown = ({setIsMainMenuDropDownOpen, contentRef}) => {
  const {t} = useTranslation();
  const {logout} = useSocket();
  const dispatch = useDispatch();
  const {getLoginDataFromStorage} = useStorage();

  useOutSideClickHandler(contentRef, () => setIsMainMenuDropDownOpen(false));

  const user = useSelector(getUser);

  const copyIdHandler = useCallback(async () => {
    await navigator.clipboard.writeText(user?.unique_id);
  }, [user?.unique_id]);

  const menuItemClickHandler = useCallback(
    async action => {
      switch (action) {
        case 'myBets':
          dispatch(setMyBetsModal({isVisible: true}));
          break;
        case 'myProfile':
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 2}));
          break;
        case 'messages':
          dispatch(setMessagesModal({isVisible: true}));
          break;
        case 'logout':
          const loginData = await getLoginDataFromStorage();
          logout({jwe_token: loginData?.jwe_token});
          break;
        default:
          break;
      }
      setIsMainMenuDropDownOpen(false);
    },
    [dispatch, getLoginDataFromStorage, logout, setIsMainMenuDropDownOpen],
  );

  return (
    <div className="mainMenuDropDownContainer absolute flex column justify-center gap-5">
      <div className="mainMenuDropDownContainerHeader flex column items-center gap-5">
        <span className="blueText font-14">{user?.phone}</span>
        <div className="flex row items-center gap-5">
          <span className="blueText font-14">ID:{user?.unique_id}</span>
          <CopyIcon
            width={11}
            height={11}
            fill="var(--appBlue)"
            onClick={copyIdHandler}
            className="cursor-pointer"
          />
        </div>
      </div>
      <div className="flex column gap-5 menuItemContainer">
        {MAIN_MENU_DROPDOWN_ITEMS.map((item, index) => (
          <div className="flex column gap-5 cursor-pointer" key={item?.name}>
            <div
              className="flex row  gap-10 items-center menuItem"
              onClick={() => menuItemClickHandler(item?.action)}>
              <img
                width={15}
                alt={item?.icon}
                src={require(
                  `../../../Assets/Icons/MainMenu/${item?.icon}.svg`,
                )}
              />
              <span
                className={`${
                  item?.name === 'logOut' ? 'redText' : 'blueText'
                } font-13`}>
                {t(item?.name)}
              </span>
            </div>
            {index !== MAIN_MENU_DROPDOWN_ITEMS?.length - 1 && (
              <div className="line" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(MenuDropDown);
