import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import Header from './Header';
import FiltersList from './FiltersList';
import MarketsList from './MarketsList';
import MobileGameStats from './MobileGameStats';
import MobileHeader from '../../Header/MobileHeader';
import GameStatsSkeleton from '../../UI/Skeletons/GameStatsSkeleton';
import SelectedGameSkeleton from '../../UI/Skeletons/SelectedGameSkeleton';

import {
  getLanguage,
  getWssSocket,
  getIsLoggedIn,
} from '../../../Redux/AppSlice/AppSelectors';
import {getSportsArray} from '../../../Redux/SportSlice/SportSelectors';

import {getGameStartTime} from '../../../Utils/TimeConvertors';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {GET_SPECIFIC_GAME} from '../../../Constants/Socket';
import {MATCH_FILTERS} from '../../../Constants/MatchFilters';

const SelectedGame = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getFavouriteMarketsFromStorage} = useStorage();
  const {getSpecificGame, unSubscribeLiveEvents} = useSocket();

  const language = useSelector(getLanguage);
  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const sportsArray = useSelector(getSportsArray);

  const marketsListWrapperRef = useRef(null);

  const [data, setData] = useState({});
  const [stats, setStats] = useState([]);
  const [subId, setSubId] = useState(null);
  const [textInfo, setTextInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState(1);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [favouriteMarkets, setFavouriteMarkets] = useState([]);

  const gameId = useMemo(() => getParam.get('game'), [getParam]);

  const sport = useMemo(
    () => Object.values(data?.sport || {})?.[0],
    [data?.sport],
  );

  const activeSportCount = useMemo(
    () => sportsArray?.find(item => item?.alias === params?.sport)?.game,
    [params?.sport, sportsArray],
  );

  const region = useMemo(
    () => Object.values(sport?.region || {})?.[0],
    [sport?.region],
  );

  const competition = useMemo(
    () => Object.values(region?.competition || {})?.[0],
    [region?.competition],
  );

  const game = useMemo(
    () => Object.values(competition?.game || {})?.[0],
    [competition?.game],
  );

  const startTime = useMemo(
    () => getGameStartTime(game?.start_ts),
    [game?.start_ts],
  );

  const textInfoLabel = useMemo(
    () => textInfo?.replace(';', '')?.replace('HT', ''),
    [textInfo],
  );

  const ALL_MARKETS_ARRAY = useMemo(
    () =>
      Object.values(game?.market || {})?.sort((a, b) => a?.order - b?.order),
    [game?.market],
  );

  const GROUP_IDS_ARRAY = useMemo(
    () => [
      ...new Set(
        ALL_MARKETS_ARRAY.map(item => item?.group_id && item?.group_id),
      ),
    ],
    [ALL_MARKETS_ARRAY],
  );

  const GROUP_DATA = useMemo(() => {
    const groupedFilters = GROUP_IDS_ARRAY?.map(item => {
      const filteredData = ALL_MARKETS_ARRAY?.filter(
        marketItem => marketItem?.group_id === item && item,
      );

      return {
        groupId: item,
        data: filteredData,
        title: filteredData?.[0]?.group_name,
        groupOrder: filteredData?.[0]?.group_order,
      };
    });

    const filteredGroups = groupedFilters?.filter(
      item => item?.data?.length > 0,
    );

    filteredGroups?.sort((a, b) => a?.groupOrder - b?.groupOrder);

    const otherData = ALL_MARKETS_ARRAY?.filter(item => !item?.group_id);

    return [
      {
        groupId: 0,
        icon: 'star',
        title: 'fav',
        data: ALL_MARKETS_ARRAY?.filter(item =>
          favouriteMarkets?.includes?.(item?.market_type),
        ),
      },
      {
        groupId: 1,
        data: ALL_MARKETS_ARRAY,
        title: 'All',
      },
      ...(groupedFilters?.length > 1 ? filteredGroups : []),
      ...(otherData?.length > 0 && groupedFilters?.length > 1
        ? [
            {
              groupId: null,
              title: 'other',
              data: ALL_MARKETS_ARRAY?.filter(item => !item?.group_id),
            },
          ]
        : []),
    ];
  }, [ALL_MARKETS_ARRAY, GROUP_IDS_ARRAY, favouriteMarkets]);

  const ACTIVE_DATA = useMemo(
    () => GROUP_DATA?.find(item => item?.groupId === activeGroupId),
    [GROUP_DATA, activeGroupId],
  );

  const marketsArray = useMemo(
    () => Object.values(ACTIVE_DATA?.data || {}),
    [ACTIVE_DATA?.data],
  );

  const ACTIVE_DATA_UNIQUE_MARKETS = useMemo(
    () => [...new Set(ACTIVE_DATA?.data?.map?.(item => item?.name))],
    [ACTIVE_DATA],
  );

  const LIST_DATA = useMemo(
    () =>
      ACTIVE_DATA_UNIQUE_MARKETS?.map?.(item => {
        const filteredArray = ACTIVE_DATA?.data?.filter?.(
          filteredItem => filteredItem?.name === item,
        );
        return {
          data: filteredArray,
          key:
            filteredArray?.[0]?.name +
            filteredArray?.[0]?.id +
            filteredArray?.[0]?.type,
          name: filteredArray?.[0]?.name,
          cashout: !!filteredArray?.[0]?.cashout,
          market_type: filteredArray?.[0]?.market_type,
        };
      }),
    [ACTIVE_DATA, ACTIVE_DATA_UNIQUE_MARKETS],
  );

  const sortedGameInfo = useMemo(() => {
    const team1Stats = stats?.find?.(item => item?.EntityId === game?.team1_id);
    const team2Stats = stats?.find?.(item => item?.EntityId === game?.team2_id);
    return [
      ...(team1Stats?.EntityId ? [team1Stats] : []),
      ...(team1Stats?.EntityId ? [team2Stats] : []),
    ];
  }, [game, stats]);

  const onMessageCb = useCallback(
    event => {
      const eventData = JSON.parse(event?.data);

      if (eventData?.rid === GET_SPECIFIC_GAME) {
        if (Object.values(eventData?.data?.data?.sport || {})?.length === 0) {
          setGetParam({});
        } else {
          setSubId(eventData?.data?.subid);
          setData(eventData?.data?.data);
        }
        setIsLoading(false);
      } else if (eventData?.data?.[subId]) {
        const changedGame =
          eventData?.data?.[subId]?.sport?.[sport?.id]?.region?.[region?.id]
            ?.competition?.[competition?.id]?.game?.[game?.id];

        if (
          changedGame === null ||
          changedGame?.info?.current_game_state === 'finished'
        ) {
          if (activeSportCount > 0) {
            getParam.delete('game');
            setGetParam(getParam);
          } else {
            navigate(`/sports/${MATCH_FILTERS?.[params?.filter]?.name}`);
          }
          return;
        }

        if (changedGame?.is_blocked) {
          setIsBlocked(!!changedGame?.is_blocked);
        }

        if (changedGame?.text_info) {
          setTextInfo(changedGame?.text_info);
        }

        if (changedGame?.market) {
          // HANDLE MARKET CHANGES
          const prevMarkets = {...game?.market};

          for (const market in changedGame?.market) {
            if (changedGame?.market?.[market] === null) {
              delete prevMarkets[market];
            } else if (
              typeof prevMarkets?.[market] === 'undefined' ||
              Object.keys(prevMarkets?.[market])?.length === 0
            ) {
              // HANDLE MARKET ADDING CASE
              prevMarkets[market] = changedGame?.market?.[market];
            } else if (prevMarkets?.[market]) {
              // HANDLE MARKET PRICE CHANGING
              const changedEventObject = changedGame?.market?.[market]?.event;

              const prevEvents = {...prevMarkets[market].event};

              for (const changedEvent in changedEventObject) {
                if (changedEventObject[changedEvent] === null) {
                  // HANDLE EVENT DELETING CASE
                  delete prevEvents[changedEvent];
                } else if (
                  typeof prevEvents[changedEvent] === 'undefined' ||
                  Object.keys(prevEvents[changedEvent])?.length === 0
                ) {
                  // HANDLE EVENT ADDING CASE
                  prevEvents[changedEvent] =
                    changedGame?.market?.[market].event[changedEvent];
                } else if (prevEvents?.[changedEvent]) {
                  // HANDLE EVENT PRICE CHANGING
                  prevEvents[changedEvent] = {
                    ...(prevEvents?.[changedEvent] || {}),
                    ...(changedEventObject?.[changedEvent] || {}),
                    lastPrice: (prevEvents?.[changedEvent] || {})?.price,
                  };
                }
              }
              prevMarkets[market].event = prevEvents;
            }
          }

          const newData = {...data};

          newData.sport[sport?.id].region[region?.id].competition[
            competition?.id
          ].game[game?.id].market = prevMarkets;

          setData(newData);
        }
      }
    },
    [
      data,
      subId,
      getParam,
      navigate,
      game?.id,
      sport?.id,
      region?.id,
      setGetParam,
      game?.market,
      params?.filter,
      competition?.id,
      activeSportCount,
    ],
  );

  useEffect(() => {
    setIsBlocked(game?.is_blocked);
    setTextInfo(game?.text_info);
  }, [game?.is_blocked, game?.text_info]);

  useEffect(() => {
    if (!!gameId) {
      setIsLoading(true);
      getSpecificGame({gameId: +gameId});
    }

    return () => {
      unSubscribeLiveEvents({subId: subId});
    };
  }, [gameId]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsStatsLoading(true);
    const storageFavouriteMarkets = getFavouriteMarketsFromStorage();
    setFavouriteMarkets(storageFavouriteMarkets);
    fetch(
      `https://krosstats.betcoapps.com/api/${language}/900/93f428d0-6591-48da-859d-b6c326db2448/Entity/GetGeneralStatsInfo?matchId=${gameId}`,
    )
      .then(response => response.json())
      .then(statsData => setStats(statsData?.GeneralStatsInfoResult))
      .catch(err => console.log(err))
      .finally(() => setIsStatsLoading(false));
  }, []);

  useEffect(() => {
    document?.querySelector?.('.homePageContainer')?.scrollIntoView?.();
  }, [gameId]);

  const headerRef = useRef(null);

  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    setHeaderHeight(`calc(100% - ${headerRef.current?.offsetHeight}px)`);
  }, [headerRef.current?.offsetHeight, isScrolled]);

  useEffect(() => {
    if (width < BREAKPOINT_XS) {
      document.querySelector('body').classList.add('overflow-hidden');
      document.querySelector('html').classList.add('overflow-hidden');
    }
    return () => {
      if (width < BREAKPOINT_XS) {
        document.querySelector('body').classList.remove('overflow-hidden');
        document.querySelector('html').classList.remove('overflow-hidden');
      }
    };
  }, [width]);

  return (
    <div
      className={`specificGameContainer overflow-hidden-x blueBackground ${
        isLoggedIn ? 'loggedInPadding' : 'loggedOutPadding'
      } ${width < BREAKPOINT_XS ? 'fixed-full mobile z-index-15' : ''}`}>
      {isLoading ? (
        <SelectedGameSkeleton />
      ) : (
        <>
          <div
            ref={headerRef}
            className={`${
              width < BREAKPOINT_XS ? 'mobile' : 'desktop'
            } blueBackground z-index-15 pb-sm selectedGameHeaderWrapper`}>
            {width < BREAKPOINT_XS && (
              <MobileHeader showFilters={false} isScrolled={isScrolled} />
            )}
            <Header
              game={game}
              sport={sport}
              region={region}
              startTime={startTime}
              isScrolled={isScrolled}
              competition={competition}
              textInfoLabel={textInfoLabel}
              sortedGameInfo={sortedGameInfo}
            />
            <FiltersList
              GROUP_DATA={GROUP_DATA}
              activeGroupId={activeGroupId}
              setActiveGroupId={setActiveGroupId}
            />
          </div>
          <div
            ref={marketsListWrapperRef}
            className={`${
              width < BREAKPOINT_XS ? 'scroll-auto-y scroll-3' : ''
            }`}
            onScroll={e => setIsScrolled(e?.target?.scrollTop > 15)}
            style={{
              height: headerHeight,
            }}>
            <MarketsList
              stats={stats}
              isBlocked={isBlocked}
              LIST_DATA={LIST_DATA}
              headerHeight={headerHeight}
              marketsArray={marketsArray}
              team1Name={game?.team1_name}
              team2Name={game?.team2_name}
              sortedGameInfo={sortedGameInfo}
              isStatsLoading={isStatsLoading}
              favouriteMarkets={favouriteMarkets}
              shirt1Color={game?.info?.shirt1_color}
              shirt2Color={game?.info?.shirt2_color}
              setFavouriteMarkets={setFavouriteMarkets}
              marketsListWrapperRef={marketsListWrapperRef}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(SelectedGame);
