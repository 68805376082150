import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import './index.scss';

import {useScreenSize} from '../../../../Hooks';

import {BREAKPOINT_SM} from '../../../../Constants/Globals';

import {formatUnixDate} from '../../../../Utils/DateFormatter';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

const TransactionHistoryItem = ({item, isColored}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const itemAmountPrefix = useCallback(
    type =>
      type === 'Deposit' ||
      type === 'Correction Up' ||
      type === 'Bet Cashout' ||
      type === 'Increasing the winning' ||
      type === 'Rejected withdrawal request'
        ? '+'
        : '',
    [],
  );

  return (
    <div
      className={`transactionHistoryItem row px-md py-sm items-center full-width wrap rounded-borders blueText text-caption bold-500 ${
        isColored ? 'colored' : ''
      }`}>
      <div className="col-xs-12 col-md-2 row justify-between">
        {width < BREAKPOINT_SM && <span className="bold-700">{t('id')}</span>}
        <span>{item?.TransactionId}</span>
      </div>
      <div className="col-xs-12 col-md-3 row justify-between">
        {width < BREAKPOINT_SM && <span className="bold-700">{t('date')}</span>}
        <span>{formatUnixDate(item?.Created)}</span>
      </div>
      <div className="col-xs-12 col-md-3 row justify-between">
        {width < BREAKPOINT_SM && <span className="bold-700">{t('type')}</span>}
        <span className={`${width < BREAKPOINT_SM ? 'text-right' : ''}`}>
          {!!item?.Game ? `${item?.Game} ` : ''}
          {item?.DocumentTypeName}
        </span>
      </div>
      <div
        className={`col-xs-12 col-md-2 row justify-${
          width > BREAKPOINT_SM ? 'end' : 'between'
        }`}>
        {width < BREAKPOINT_SM && (
          <span className="bold-700">{t('amount')}</span>
        )}
        <span>
          {itemAmountPrefix(item?.DocumentTypeName)}
          {bigNumbersPrettier(item?.Amount)}
        </span>
      </div>
      <div
        className={`col-xs-12 col-md-2 row justify-${
          width > BREAKPOINT_SM ? 'end' : 'between'
        }`}>
        {width < BREAKPOINT_SM && (
          <span className="bold-700">{t('finalBalance')}</span>
        )}
        <span>{item?.Balance}</span>
      </div>
    </div>
  );
};

export default memo(TransactionHistoryItem);
