import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Keyboard} from 'swiper/modules';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {
  CASINO_METHODS,
  PAYMENT_SERVICES,
  LE_GRANDE_CASINO_ID,
  PALM_BEACH_CASINO_ID,
  CASINO_MAX_WITHDRAW_AMOUNT,
  CASINO_MIN_WITHDRAW_AMOUNT,
  OPERATOR_MIN_WITHDRAW_AMOUNT,
  OPERATOR_MAX_WITHDRAW_AMOUNT,
} from '../../../../Constants/PaymentServices';
import {WITHDRAW} from '../../../../Constants/Socket';
import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../../Constants/Globals';

import {AppButton, AppInput} from '../../../UI';

const Withdraw = ({userOperator}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {withdraw} = useSocket();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [activeOperator, setActiveOperator] = useState(
    PAYMENT_SERVICES.find(item => item?.title === userOperator) ||
      PAYMENT_SERVICES?.[0],
  );

  const withdrawClickHandler = useCallback(() => {
    setIsWithdrawLoading(true);
    withdraw({
      amount: withdrawAmount,
      service: activeOperator?.id,
      ...(activeOperator?.office_id
        ? {
            office_id: activeOperator?.office_id,
          }
        : {}),
    });
  }, [activeOperator?.id, activeOperator?.office_id, withdraw, withdrawAmount]);

  const isCasinoOperator = useMemo(
    () =>
      activeOperator?.id === PALM_BEACH_CASINO_ID ||
      activeOperator?.id === LE_GRANDE_CASINO_ID,
    [activeOperator?.id],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case WITHDRAW:
          if (
            data?.data?.result === 0 ||
            data?.data?.result === '0' ||
            data?.data?.status?.trim() === 'finish_status'
          ) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('withdrawCompletedTitle', {
                    currency,
                    amountValue: bigNumbersPrettier(withdrawAmount),
                  }),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          } else {
            inputRef?.current.blur();

            if (
              data?.data?.status?.trim() === 'error' &&
              typeof data?.data?.message === 'string'
            ) {
              setErrorMsg(data?.data?.message);
            } else if (typeof data?.data?.details === 'string') {
              setErrorMsg(data?.data?.details);
            }
          }
          setIsWithdrawLoading(false);
          break;
        default:
          break;
      }
    },
    [currency, dispatch, t, withdrawAmount],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="menu-balance-withdrawContainer">
      <span className="blueText text-caption">
        {t('pleaseSelectAPaymentMethod')}
      </span>
      <div className="column gap-15 py-xl">
        <div className="row gap-10">
          <div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">
            <span className="text-center grayText bold-500">MOBILE MONEY</span>
          </div>
          <Swiper
            keyboard
            mousewheel
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Keyboard]}
            className="paymentsSwiper"
            initialSlide={
              PAYMENT_SERVICES?.findIndex?.(
                item => item?.title === userOperator,
              ) || 0
            }>
            {PAYMENT_SERVICES?.map(item => (
              <SwiperSlide key={item?.id}>
                <img
                  src={item?.logo}
                  alt={item?.title}
                  onClick={() => setActiveOperator(item)}
                  className={`paymentSwiperImage cursor-pointer ${
                    activeOperator?.title !== item?.title ? 'not-selected' : ''
                  }`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="row gap-10">
          <div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">
            <span className="text-center grayText bold-500">CASH DESK</span>
          </div>
          <Swiper
            keyboard
            mousewheel
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Keyboard]}
            className="casinoPaymentsSwiper">
            {CASINO_METHODS.map(item => (
              <SwiperSlide key={item?.id}>
                <img
                  src={item?.logo}
                  alt={item?.title}
                  style={{background: item?.background}}
                  onClick={() => setActiveOperator(item)}
                  className={`casinoPaymentSwiperImage cursor-pointer ${
                    activeOperator?.title !== item?.title ? 'not-selected' : ''
                  }`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="column gap-10">
        <span className="text-body2 blueText">{t('amount')}</span>
        <div className="row wrap col-gutter-sm">
          <div className="col-xs-12 col-sm-8">
            <AppInput
              error={errorMsg}
              inputRef={inputRef}
              setErrorMsg={setErrorMsg}
              inputValue={withdrawAmount}
              onChange={setWithdrawAmount}
              placeholder={t('enterAmountToWithdraw', {currency})}
            />
            <span className="text-caption blueText">
              {t('minMaxLimits', {
                currency,
                minValue: bigNumbersPrettier(
                  isCasinoOperator
                    ? CASINO_MIN_WITHDRAW_AMOUNT
                    : OPERATOR_MIN_WITHDRAW_AMOUNT,
                ),
                maxValue: bigNumbersPrettier(
                  isCasinoOperator
                    ? CASINO_MAX_WITHDRAW_AMOUNT
                    : OPERATOR_MAX_WITHDRAW_AMOUNT,
                ),
              })}
            </span>
          </div>
          <div className="col-xs-12 col-sm-4">
            <AppButton
              type={3}
              height={45}
              loading={isWithdrawLoading}
              onClick={withdrawClickHandler}
              title={t('withdraw')?.toUpperCase?.()}
              disabled={
                !+withdrawAmount ||
                +withdrawAmount <
                  (isCasinoOperator
                    ? CASINO_MIN_WITHDRAW_AMOUNT
                    : OPERATOR_MIN_WITHDRAW_AMOUNT)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Withdraw);
