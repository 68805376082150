import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {setSuccessModal} from '../../../../Redux/AppSlice';
import {getSuccessModal} from '../../../../Redux/AppSlice/AppSelectors';

import {AppButton, AppModal} from '../../index';

import success from '../../../../Assets/Icons/Globals/success.svg';

const SuccessModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const successModal = useSelector(getSuccessModal);

  const clickHandler = useCallback(() => {
    dispatch(
      setSuccessModal({
        isVisible: false,
        successTitle: '',
        successDescription: '',
      }),
    );
  }, [dispatch]);

  const Header = useMemo(
    () => <span className="py-md text-subtitle2 whiteText">{t('done')}</span>,
    [t],
  );

  return (
    <AppModal
      headerType={2}
      header={Header}
      maxWidth="300px"
      onRequestClose={clickHandler}
      isOpen={successModal?.isVisible}>
      <div className="flex column items-center justify-between successContainer">
        <div className="flex justify-center successIcon">
          <img src={success} alt="success" width={150} />
        </div>
        <div className="flex column items-center gap-15 titlesWrapper">
          <div className="flex column items-center">
            <span className="greenText text-subtitle2 textCenter">
              {successModal?.successTitle}
            </span>
            <span className="blueText text-subtitle2 textCenter">
              {successModal?.successDescription}
            </span>
          </div>
          <AppButton type={3} title={t('ok')} onClick={clickHandler} />
        </div>
      </div>
    </AppModal>
  );
};

export default memo(SuccessModal);
