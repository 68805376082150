import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getUser} from '../../../../../Redux/UserSlice/UserSelectors';
import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {useSocket, useStorage} from '../../../../../Hooks';

import {
  SOCKET_RESPONSES,
  UPDATE_USER_PASSWORD,
  SEND_SMS_WITH_USERNAME,
} from '../../../../../Constants/Socket';
import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../../../Constants/Globals';

import {AppButton, AppError, AppInput} from '../../../../UI';

const ChangePassword = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {updateUserPassword, sendSmsWithUsername} = useSocket();
  const {getLoginDataFromStorage, setLoginDataToStorage} = useStorage();

  const user = useSelector(getUser);
  const wssSocket = useSelector(getWssSocket);

  const [step, setStep] = useState(0);

  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [error, setError] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(null);
  const [isGetCodeLoading, setIsGetCodeLoading] = useState(false);
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);

  const isChangePasswordDisabled = useMemo(
    () =>
      step === 0
        ? newPassword?.trim?.()?.length < 5 ||
          currentPassword?.trim?.()?.length < 5 ||
          confirmNewPassword?.trim?.()?.length < 5 ||
          newPassword?.trim?.() !== confirmNewPassword?.trim?.()
        : !verificationCode?.trim() || !!error,
    [
      step,
      error,
      newPassword,
      currentPassword,
      verificationCode,
      confirmNewPassword,
    ],
  );

  const isPasswordsMatch = useMemo(
    () =>
      newPassword?.trim?.()?.length > 4 &&
      confirmNewPassword?.trim?.()?.length > 4 &&
      newPassword?.trim?.() !== confirmNewPassword?.trim?.()
        ? t('passwordsMismatch')
        : '',
    [confirmNewPassword, newPassword, t],
  );

  const isOldPassShort = useMemo(
    () =>
      !!currentPassword?.trim?.() && currentPassword?.trim?.()?.length < 5
        ? t('passwordErrorMsg')
        : '',
    [currentPassword, t],
  );
  const isNewPassShort = useMemo(
    () =>
      !!newPassword?.trim?.() && newPassword?.trim?.()?.length < 5
        ? t('passwordErrorMsg')
        : '',
    [newPassword, t],
  );
  const isConfirmPassShort = useMemo(
    () =>
      !!confirmNewPassword?.trim?.() && confirmNewPassword?.trim?.()?.length < 5
        ? t('passwordErrorMsg')
        : '',
    [confirmNewPassword, t],
  );

  const updatePasswordHandler = useCallback(async () => {
    setIsCodeSent(false);
    setIsChangePasswordLoading(true);
    const loginData = await getLoginDataFromStorage();
    updateUserPassword({
      password: currentPassword,
      new_password: newPassword,
      confirmation_code: verificationCode,
      encrypted_token: !!loginData?.jwe_token,
    });
  }, [
    newPassword,
    currentPassword,
    verificationCode,
    updateUserPassword,
    getLoginDataFromStorage,
  ]);

  const getCodeHandler = useCallback(() => {
    setIsGetCodeLoading(true);
    if (isCodeSent) {
      setIsCodeSent(false);
    }
    sendSmsWithUsername({username: user?.username});
  }, [isCodeSent, sendSmsWithUsername, user?.username]);

  const onClickHandler = useCallback(() => {
    step === 0 ? setStep(1) : updatePasswordHandler();
  }, [step, updatePasswordHandler]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case UPDATE_USER_PASSWORD:
          if (!data?.data?.auth_token) {
            if (
              data?.data?.result === SOCKET_RESPONSES?.INCORRECT_OLD_PASSWORD
            ) {
              setOldPasswordError(data?.data?.details);
              setStep(0);
            }
            setError(data?.data?.details);
          } else {
            const loginData = await getLoginDataFromStorage();
            await setLoginDataToStorage({
              ...loginData,
              auth_token: data?.data?.auth_token,
              ...(!!data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
            });
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('passwordWasChangedSuccessfully'),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          }
          setIsChangePasswordLoading(false);
          break;
        case SEND_SMS_WITH_USERNAME:
          if (data?.data?.result === 0) {
            setIsCodeSent(true);
          }
          setIsGetCodeLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, getLoginDataFromStorage, setLoginDataToStorage, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="py-lg flex column items-center changePasswordTabContainer">
      <div className="flex row items-center full-width justify-center">
        <div className="col-xs-12 col-sm-10 col-md-8 flex column content justify-between gap-10">
          <div className="flex justify-between column gap-10 col-sm-12 col-md-4">
            <span className="blueText text-body2 bold-600 textCenter py-md">
              {step === 0
                ? t('changePassword')?.toUpperCase?.()
                : t('smsVerification')?.toUpperCase?.()}
            </span>
            <div className="line" />
            {step === 0 ? (
              <>
                <div className="flex column full-width gap-10">
                  <span className="text-body2 blueText">
                    {t('currentPassword')}:
                  </span>
                  <AppInput
                    password
                    inputValue={currentPassword}
                    onChange={setCurrentPassword}
                    setErrorMsg={setOldPasswordError}
                    placeholder={t('enterCurrentPassword')}
                    error={isOldPassShort || oldPasswordError}
                  />
                </div>
                <div className="flex column full-width gap-10">
                  <span className="text-body2 blueText">
                    {t('newPassword')}:
                  </span>
                  <AppInput
                    password
                    error={isNewPassShort}
                    inputValue={newPassword}
                    onChange={setNewPassword}
                    placeholder={t('enterNewPassword')}
                  />
                </div>
                <div className="flex column full-width gap-10">
                  <span className="text-body2 blueText">
                    {t('confirmNewPassword')}:
                  </span>
                  <AppInput
                    password
                    inputValue={confirmNewPassword}
                    onChange={setConfirmNewPassword}
                    placeholder={t('confirmNewPassword')}
                    error={isConfirmPassShort || isPasswordsMatch}
                  />
                </div>
              </>
            ) : (
              <div className="flex column full-width gap-10">
                <span className="text-body1 blueText textCenter my-lg">
                  {t('theCodeWillBeSent', {phone: user?.phone})}
                </span>
                <span className="text-body2 blueText">
                  {t('verificationCode')}:
                </span>
                <div className="flex row gap-5">
                  <AppInput
                    inputValue={verificationCode}
                    onChange={setVerificationCode}
                    placeholder={t('enterVerificationCode')}
                  />
                  <AppButton
                    type={1}
                    width={150}
                    height={45}
                    title={t('getCode')}
                    onClick={getCodeHandler}
                    loading={isGetCodeLoading}
                  />
                </div>
                {isCodeSent && (
                  <span className="greenText textCenter text-body1 my-md">
                    {t('codeWasSent')}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="flex column gap-5">
            <AppError error={error} />
            <AppButton
              type={3}
              onClick={onClickHandler}
              loading={isChangePasswordLoading}
              disabled={isChangePasswordDisabled}
              title={
                step === 0 ? t('continue') : t('confirmLower')?.toUpperCase()
              }
            />
            {step === 1 && (
              <AppButton
                type={1}
                title={t('back')}
                onClick={() => setStep(0)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChangePassword);
