import React, {memo, useMemo} from 'react';

import './index.scss';

import {
  BET_TYPES,
  SINGLE_BET,
  SYSTEM_BET,
  MAX_SYSTEM_BET_COUNT,
} from '../../../../Constants/Betting';

import {AppSelect} from '../../../UI';

const BetTypeDropdown = ({betType, setBetType, allEventsData}) => {
  const availableBettingTypes = useMemo(
    () =>
      BET_TYPES?.filter(item =>
        allEventsData?.length === 1
          ? item?.name === SINGLE_BET
          : (allEventsData?.length < 3 && allEventsData?.length > 0) ||
            allEventsData?.length > MAX_SYSTEM_BET_COUNT
          ? item?.name !== SYSTEM_BET
          : item,
      ),
    [allEventsData?.length],
  );

  return (
    <div className="betTypeDropdownContainer">
      <AppSelect
        type={1}
        value={betType}
        setValue={setBetType}
        titleClassName="bold-600"
        options={availableBettingTypes}
        itemClassName="py-sm px-md bold-600"
        mainContainerClassName="py-sm px-md"
      />
    </div>
  );
};

export default memo(BetTypeDropdown);
