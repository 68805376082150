import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {getUser} from '../../../Redux/UserSlice/UserSelectors';

import {AppButton} from '../../../Components/UI';
import HomeProviders from '../../../Components/Home/HomeProviders';
import CasinoActionBar from '../../../Components/Casino/CasinoActionBar';
import GamesListContainer from '../../../Components/Casino/Games/GamesListContainer';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SLOTS_GAMES_ID,
  PROVIDERS_ALL_GAMES_NAME,
} from '../../../Constants/CasinoMenuFilter';
import {SITE_ID} from '../../../Constants/Socket';

import './index.scss';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';
import {setAuthModal} from '../../../Redux/AppSlice';
import {
  APP_MODAL_CLOSE_TIMEOUT_MS,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';

const Casino = () => {
  const params = useParams();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getCasinoGames} = useCasino();
  const {getLastPlayedGamesFromStorage} = useStorage();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const offset = useRef(0);
  const inputValueRef = useRef(getParam.get('search'));

  const [games, setGames] = useState([]);
  const [totalCounts, setTotalCounts] = useState(0);
  const [lastPlayedGames, setLastPlayedGames] = useState([]);
  const [isGamesLoading, setIsGamesLoading] = useState(false);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const gameId = useMemo(() => getParam.get('game'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);

  const isGameVisible = useMemo(
    () => !!gameId && !!isLoggedIn,
    [gameId, isLoggedIn],
  );

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${gameId}` +
        '&openType=real' +
        `&token=${user?.auth_token}`
      )?.trim(),
    [gameId, user?.auth_token],
  );

  const LAST_PLAYED_DATA = useMemo(() => {
    const storageData = getLastPlayedGamesFromStorage()
      ?.filter(item =>
        params?.category === 'slots'
          ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
          : params?.category === 'liveCasino'
          ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
          : item,
      )
      ?.map(item => `${item?.gameId}`);

    return lastPlayedGames?.filter(item =>
      storageData?.includes(item?.extearnal_game_id),
    );
  }, [getLastPlayedGamesFromStorage, lastPlayedGames, params?.category]);

  const onShowMore = useCallback(() => {
    offset.current = offset.current + 30;
    setMoreItemsLoading(true);

    getCasinoGames({
      provider:
        params?.provider === PROVIDERS_ALL_GAMES_NAME ? '' : params?.provider,
      category:
        params?.category === 'liveCasino' ? CATEGORY_LIVE_CASINO_ID : '',
      offset: offset.current,
      limit: offset.current + 30,
    })
      ?.then(res => {
        if (
          inputValueRef.current === searchValue ||
          (!inputValueRef.current && !searchValue)
        ) {
          setGames(prevState => [...prevState, ...res?.data?.games]);
        }
      })
      ?.finally(() => setMoreItemsLoading(false));
  }, [searchValue, getCasinoGames, params?.provider, params?.category]);

  useEffect(() => {
    setIsGamesLoading(true);
    getCasinoGames({
      provider:
        params?.provider === PROVIDERS_ALL_GAMES_NAME ? '' : params?.provider,
      category:
        params?.category === 'liveCasino' ? CATEGORY_LIVE_CASINO_ID : '',
    })
      ?.then(res => {
        setTotalCounts(res?.data?.total_count);
        setGames(res?.data?.games);
      })
      ?.finally(() => setIsGamesLoading(false));
  }, [params?.category, params?.provider]);

  useEffect(() => {
    if (!params?.provider) {
      navigate({
        pathname: `${location?.pathname}/${PROVIDERS_ALL_GAMES_NAME}`,
        ...(gameId
          ? {
              search: createSearchParams({
                game: gameId,
              }).toString(),
            }
          : {}),
      });
    }
  }, [params?.provider]);

  useEffect(() => {
    getParam.delete('search');
    setGetParam(getParam);
  }, []);

  useEffect(() => {
    if (gameId && isLoggedIn === false) {
      dispatch(setAuthModal({isVisible: true}));
    }
  }, [isLoggedIn, gameId]);

  useEffect(() => {
    if (!!params?.provider) {
      offset.current = 0;
    }
  }, [params?.provider]);

  useEffect(() => {
    if (gameId && isLoggedIn) {
      setIsFullScreen(width < BREAKPOINT_XS);
    }
  }, [gameId, isLoggedIn, width]);

  useEffect(() => {
    const externalIdsString = getLastPlayedGamesFromStorage()
      ?.map(game => game?.gameId)
      ?.join(',');
    if (externalIdsString) {
      getCasinoGames({
        limit: 30,
        externalIds: externalIdsString,
      })?.then?.(response => {
        const specificGames = response?.data?.games;
        specificGames?.sort(
          (a, b) =>
            externalIdsString.indexOf(a?.extearnal_game_id) -
            externalIdsString.indexOf(b?.extearnal_game_id),
        );
        setLastPlayedGames(specificGames);
      });
    }
  }, []);

  useEffect(() => {
    if (isGameVisible) {
      setTimeout(() => {
        document.querySelector('body').classList.add('overflow-hidden');
        document.querySelector('html').classList.add('overflow-hidden');
      }, APP_MODAL_CLOSE_TIMEOUT_MS);
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
      document.querySelector('html').classList.remove('overflow-hidden');
    }
  }, [isGameVisible]);

  return (
    <div className="casinoPageContainer">
      {isGameVisible && (
        <>
          <GameActionBarFooter
            setIsFullScreen={setIsFullScreen}
            gameTitle={
              games?.find(item => +item?.extearnal_game_id === +gameId)?.name
            }
          />
          <div
            className={`${
              isFullScreen
                ? 'fullScreenContainer overflow-hidden darkBlueBackground'
                : `mt-md gameIframeContainer`
            }`}>
            {isFullScreen && (
              <GameActionBarHeader setIsFullScreen={setIsFullScreen} />
            )}
            <div
              className={`full-width ${
                isFullScreen ? 'casinoGameIframe' : 'full-height'
              }`}>
              <iframe
                src={src}
                scrolling="no"
                className="fit"
                title="casino_game"
              />
            </div>
          </div>
        </>
      )}
      {!isGameVisible && <HomeProviders />}
      {/*{!isGameVisible && width > BREAKPOINT_XS && (*/}
      {/*  <CasinoActionBar inputValueRef={inputValueRef} showLastPlayedButton />*/}
      {/*)}*/}
      <GamesListContainer
        isGamesLoading={isGamesLoading}
        moreItemsLoading={moreItemsLoading}
        data={
          location?.pathname?.includes('lastPlayed') ? LAST_PLAYED_DATA : games
        }
      />
      {games?.length < totalCounts && !isGameVisible && (
        <div className="rowCenter my-xl">
          <AppButton
            type={6}
            width={150}
            onClick={onShowMore}
            title={<span className="boldText">{t('showMore')}</span>}
          />
        </div>
      )}
    </div>
  );
};

export default Casino;
