import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import './Header.scss';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getMainMenuModal} from '../../Redux/AppSlice/AppSelectors';

import {MAIN_MENU_HEADER_ITEMS} from '../../Constants/Menu';

import {AppButton} from '../UI';

const Header = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const mainMenuModal = useSelector(getMainMenuModal);

  return (
    <div className="flex column items-center px-lg pb-xl mainMenuHeaderWrapper full-width">
      <span className="py-md text-subtitle2 blueText bold-600">
        {t('mainMenuUpper')}
      </span>
      <div className="full-width justify-center flex">
        <Swiper
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard]}
          className="mainMenuHeaderSwiper">
          {MAIN_MENU_HEADER_ITEMS?.map(({title, tabIndex}) => (
            <SwiperSlide key={title}>
              <AppButton
                type={10}
                toUpperCase
                fontSize={11}
                title={t(title)}
                active={mainMenuModal?.tabIndex === tabIndex}
                onClick={() => dispatch(setMainMenuModal({tabIndex}))}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default memo(Header);
