import React, {memo, useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation} from 'swiper/modules';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import 'swiper/css';
import './index.scss';
import 'swiper/css/navigation';

import {getIsLoggedIn, getLanguage} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {APP_LANGUAGES} from '../../../Constants/Languages';
import {CMS_URL, SITE_ID, SOCKET_RESPONSES} from '../../../Constants/Socket';

import CarouselSkeletons from '../Skeletons/CarouselSkeletons';

const autoplay = {
  delay: 2500,
  disableOnInteraction: false,
};

const Carousel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const casinoGameId = getParam.get('game');

  const language = useSelector(getLanguage);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const [carouselData, setCarouselData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const onClickHandler = useCallback(
    id => {
      // navigate({
      //   pathname: location?.pathname,
      //   search: createSearchParams({
      //     showPromotions: id,
      //   }).toString(),
      // });
    },
    [location?.pathname, navigate],
  );

  useEffect(() => {
    const selectedLanguage = language === APP_LANGUAGES.CHN ? 'chi' : language;
    setIsDataLoading(true);
    axios
      .get(
        location?.pathname.includes('casino')
          ? `https://cms.qaxcraveniq.com/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=${
              width < BREAKPOINT_XS ? '1' : '0'
            }`
          : `https://cms.qaxcraveniq.com/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/841/contents?use_webp=1&platform=${
              width < BREAKPOINT_XS ? '1' : '0'
            }`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/promotions?platform=0&category=all&exclude=content`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=1`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=0`
      )
      .then(data => {
        if (data?.data && data?.data?.text === SOCKET_RESPONSES.OK) {
          const carouselDataArray = data?.data?.data?.map?.(item => ({
            id: item?.id,
            href: item?.href,
            target: item?.target,
            src: `${CMS_URL}${item?.src}`,
          }));
          setCarouselData(carouselDataArray);
        } else {
          setCarouselData(null);
        }
      })
      .catch(() => {})
      .finally(() => setIsDataLoading(false));
  }, [language, location?.pathname?.split?.('/')?.[1], width < BREAKPOINT_XS]);

  return (
    <div
      className={`homeCarouselContainer ${
        location?.pathname?.includes('/casino') && !!casinoGameId && isLoggedIn
          ? 'hidden'
          : 'block'
      }`}>
      {isDataLoading ? (
        <CarouselSkeletons />
      ) : (
        !!carouselData?.length && (
          <Swiper
            loop
            navigation
            centeredSlides
            spaceBetween={15}
            autoplay={autoplay}
            slidesPerView="auto"
            className="appCarousel"
            modules={[Autoplay, Navigation]}>
            {carouselData?.map(item => (
              <SwiperSlide key={item?.id}>
                <div onClick={() => onClickHandler(item?.id)}>
                  <img src={item?.src} alt={item?.id} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      )}
    </div>
  );
};

export default memo(Carousel);
