import React, {memo, useCallback, useState} from 'react';

import './index.scss';

const TextArea = ({placeholder, onChange, value, error, setErrorMsg}) => {
  const [isBlurred, setIsBlurred] = useState(true);

  const onChangeText = useCallback(() => {
    if (error && setErrorMsg && typeof setErrorMsg === 'function') {
      setErrorMsg(null);
    }
  }, [error, setErrorMsg]);

  return (
    <>
      <textarea
        value={value}
        placeholder={placeholder}
        className="appTextArea scroll-y scroll-1"
        onChange={e => {
          onChange(e?.target?.value);
          onChangeText(e?.target?.value);
        }}
        onBlur={() => setIsBlurred(true)}
        onFocus={() => setIsBlurred(false)}
      />

      {error && isBlurred && (
        <span className="redText text-caption ellipsis">{error}</span>
      )}
    </>
  );
};

export default memo(TextArea);
