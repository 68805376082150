import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Layout from './Layout';

import {
  HomePage,
  PokerPage,
  CasinoPage,
  LastPlayedPage,
  SportsJackpotPage,
} from './Pages';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route
        element={<HomePage />}
        path="/sports/:filter?/:sport?/:region?/:competition?/:game?/"
      />
      <Route path="/casino/:category/:provider?/" element={<CasinoPage />} />
      <Route path="/casino/lastPlayed/:tab?/" element={<LastPlayedPage />} />
      <Route path="/casino/poker" element={<PokerPage />} />
      <Route path="jackpot" element={<SportsJackpotPage />} />
    </Route>,
  ),
);
