import React, {memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import './index.scss';

import {getUser} from '../../Redux/UserSlice/UserSelectors';
import {
  setAuthModal,
  setShowBetSlip,
  setShowSearchModal,
} from '../../Redux/AppSlice';
import {setShowCasinoSearchModal} from '../../Redux/CasinoSlice';
import {getIsLoggedIn, getShowBetSlip} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import logo from '../../Assets/Layout/HeaderLogo.png';
import {ReactComponent as UserIcon} from '../../Assets/Icons/Globals/user.svg';
import {ReactComponent as MyBetsIcon} from '../../Assets/Icons/Globals/myBets.svg';
import {ReactComponent as DownArrow} from '../../Assets/Icons/Globals/downTriangle.svg';
import {ReactComponent as TriangleTop} from '../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as SearchIcon} from '../../Assets/Icons/Globals/search.svg';

import {
  HEADER_FILTERS,
  FILTERS_TODAY_NAME,
  HEADER_FILTERS_SPORTS,
} from '../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppButton, AppLanguageChooser} from '../UI';

import DepositButton from './DepositButton';
import MenuDropDown from '../Home/MenuDropDown';
import SportFilters from '../Home/SportFilters';
import CasinoFixedGames from '../Casino/CasinoFixedGames';

const Header = () => {
  const params = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();

  const mainMenuDropDownContainerRef = useRef(null);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);

  const [isMainMenuDropDownOpen, setIsMainMenuDropDownOpen] = useState(false);

  const signInHandler = useCallback(
    () => dispatch(setAuthModal({tabIndex: 0, isVisible: true})),
    [dispatch],
  );

  const registerHandler = useCallback(
    () => dispatch(setAuthModal({tabIndex: 1, isVisible: true})),
    [dispatch],
  );

  const onSearchClick = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        location?.pathname?.includes('casino')
          ? setShowCasinoSearchModal(true)
          : setShowSearchModal(true),
      );
    },
    [dispatch, location?.pathname],
  );

  const ArrowIcon = isMainMenuDropDownOpen ? TriangleTop : DownArrow;

  return (
    <div className="flex column homeHeaderContainerWrapper px-md">
      <div
        className={`flex justify-between ${
          width < BREAKPOINT_XS ? 'column' : 'row'
        } homeHeaderContainer items-center gap-10 py-md`}>
        <div className="flex justify-between row items-center full-width">
          <img
            src={logo}
            height={50}
            alt="homeHeaderLogo"
            className="homeHeaderLogo"
            onClick={() => {
              if (params?.filter !== FILTERS_TODAY_NAME) {
                navigate(`/sports/${FILTERS_TODAY_NAME}`);
              }
            }}
          />
          {width > BREAKPOINT_XS && isLoggedIn && <DepositButton />}
          <div className="menuContainer flex row items-center gap-5 relative">
            {!isLoggedIn && (
              <div className="flex items-center gap-10">
                <AppButton
                  type={3}
                  width={85}
                  fontSize={11}
                  className="py-sm"
                  title={t('signIn')}
                  onClick={signInHandler}
                />
                <AppButton
                  type={1}
                  width={85}
                  fontSize={11}
                  className="py-sm"
                  title={t('register')}
                  onClick={registerHandler}
                />
              </div>
            )}

            {isLoggedIn && (
              <div ref={mainMenuDropDownContainerRef}>
                <div
                  className={`flex items-center gap-5 mainMenu justify-between cursor-pointer ${
                    isMainMenuDropDownOpen ? 'menuOpened' : 'menuClosed'
                  }`}
                  onClick={() =>
                    setIsMainMenuDropDownOpen(prevState => !prevState)
                  }>
                  <div className="fix row items-center gap-5">
                    <UserIcon
                      width={20}
                      height={20}
                      fill={
                        isMainMenuDropDownOpen
                          ? 'var(--appBlue)'
                          : 'var(--white)'
                      }
                    />
                    <span
                      className={`font-13 bold-500 ${
                        isMainMenuDropDownOpen ? 'blueText' : 'whiteText'
                      }`}>
                      {user?.first_name}
                    </span>
                  </div>
                  <ArrowIcon
                    width={10}
                    height={10}
                    fill={
                      isMainMenuDropDownOpen ? 'var(--appBlue)' : 'var(--white)'
                    }
                  />
                </div>
                {isMainMenuDropDownOpen && (
                  <MenuDropDown
                    contentRef={mainMenuDropDownContainerRef}
                    setIsMainMenuDropDownOpen={setIsMainMenuDropDownOpen}
                  />
                )}
              </div>
            )}
            <AppLanguageChooser type={2} />
            <div
              onClick={e => onSearchClick(e)}
              className="cursor-pointer blueBackground pa-sm flex items-center justify-center rounded-borders">
              <SearchIcon width={15} height={15} fill="var(--white)" />
            </div>
          </div>
        </div>
        {width < BREAKPOINT_XS && isLoggedIn && <DepositButton />}
      </div>
      <div className="row items-center justify-center headerFiltersContainer py-sm rounded-borders">
        <Swiper
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard]}
          className="headerFiltersSwiper full-width">
          {Object.values(HEADER_FILTERS).map(item => (
            <SwiperSlide key={item?.path}>
              <AppButton
                type={6}
                width={160}
                onClick={() => {
                  if (item?.name === HEADER_FILTERS_SPORTS) {
                    if (params?.filter !== FILTERS_TODAY_NAME) {
                      navigate(item?.path);
                    }
                  } else {
                    navigate(item?.path);
                  }
                  window.scrollTo(0, 0);
                }}
                active={location?.pathname?.includes(item?.activeState)}
                title={
                  <div className="flex row gap-10 items-center justify-center">
                    {item?.icon && (
                      <img
                        width={15}
                        alt={item?.name}
                        src={require(
                          `../../Assets/Icons/HeaderFilters/${item?.icon}.png`,
                        )}
                      />
                    )}
                    <span className="font-12 bold-700">{t(item?.name)}</span>
                  </div>
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/*<AppDownloadDropDown />*/}
        {/*<div className="relative cursor-pointer flex items-center">*/}
        {/*  {!!betSlipCount && (*/}
        {/*    <div className="absolute items-center flex justify-center betSlipCounter">*/}
        {/*      <span className="text-caption-extra-small blueText bold-600">*/}
        {/*        {betSlipCount}*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*  <BetSlipIcon*/}
        {/*    width={25}*/}
        {/*    height={25}*/}
        {/*    fill="var(--white)"*/}
        {/*    className="mx-md"*/}
        {/*    onClick={() => dispatch(setShowBetSlip(!showBetSlip))}*/}
        {/*  />*/}
        {/*</div>*/}
        {location?.pathname?.includes('/casino') && width > BREAKPOINT_XS && (
          <div
            onClick={() => dispatch(setShowBetSlip(!showBetSlip))}
            className={`row items-center gap-5 py-sm px-md mx-sm rounded-borders cursor-pointer ${
              showBetSlip ? 'yellowBackground' : 'darkBlueBackground'
            }`}>
            <MyBetsIcon
              width={18}
              height={18}
              fill={showBetSlip ? 'var(--appBlue)' : 'white'}
            />
            <span
              className={`text-caption-extra-small bold-500 flex-shrink ${
                showBetSlip ? 'blueText' : 'whiteText'
              }`}>
              {t('myBets')}
            </span>
          </div>
        )}
      </div>
      {!location?.pathname?.includes('/casino') && (
        <div className="blueBackground my-sm rounded-borders">
          <SportFilters />
        </div>
      )}
      {location?.pathname?.includes('/casino') && <CasinoFixedGames />}
    </div>
  );
};

export default memo(Header);
