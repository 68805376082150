import React, {memo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Virtuoso} from 'react-virtuoso';

import './MarketsList.scss';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

import MarketListItem from './MarketListItem';
import MobileGameStats from './MobileGameStats';
import GameStatsSkeleton from '../../UI/Skeletons/GameStatsSkeleton';

const MarketsList = ({
  stats,
  isBlocked,
  LIST_DATA,
  team1Name,
  team2Name,
  shirt1Color,
  shirt2Color,
  headerHeight,
  isStatsLoading,
  sortedGameInfo,
  favouriteMarkets,
  setFavouriteMarkets,
  marketsListWrapperRef,
}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const [listHeight, setListHeight] = useState(null);

  const Header = memo(
    () =>
      !!stats?.length &&
      (isStatsLoading ? (
        <div className="mx-md">
          <GameStatsSkeleton />
        </div>
      ) : (
        <MobileGameStats
          team1Name={team1Name}
          team2Name={team2Name}
          shirt1Color={shirt1Color}
          shirt2Color={shirt2Color}
          sortedGameInfo={sortedGameInfo}
        />
      )),
  );

  useEffect(
    () => setListHeight(marketsListWrapperRef.current?.offsetHeight),
    [headerHeight, marketsListWrapperRef],
  );
  return (
    <div
      className={`flex column ${
        width < BREAKPOINT_XS ? 'mobile' : ''
      } marketsListWrapper`}>
      {!!LIST_DATA?.length || !!isBlocked ? (
        <Virtuoso
          data={LIST_DATA}
          components={{Header}}
          useWindowScroll={width > BREAKPOINT_XS}
          style={width < BREAKPOINT_XS ? {height: listHeight} : {}}
          itemContent={(index, listItem) => (
            <MarketListItem
              index={index}
              key={listItem?.key}
              marketItem={listItem}
              team2Name={team2Name}
              team1Name={team1Name}
              favouriteMarkets={favouriteMarkets}
              setFavouriteMarkets={setFavouriteMarkets}
            />
          )}
        />
      ) : (
        <div className="row justify-center py-xl">
          <span className="blueText text-body1 bold-600">
            {t('emptyEvents')}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(MarketsList);
