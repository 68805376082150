import React, {memo, useCallback, useState} from 'react';

import './index.scss';

import openedEye from '../../../Assets/Icons/Globals/openedEye.svg';
import checkedIcon from '../../../Assets/Icons/Globals/success.svg';
import closedEye from '../../../Assets/Icons/Globals/closedEye.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';

const Input = ({
  error,
  inputRef,
  inputValue,
  placeholder,
  type = 1,
  onFocusCb = () => {},
  onChange = () => {},
  height = 45,
  className = null,
  setErrorMsg = () => {},
  search = false,
  checked = false,
  disabled = false,
  inputType = 'text',
  password = false,
  autoComplete = 'new-password',
}) => {
  const [showText, setShowText] = useState(password);
  const [isBlurred, setIsBlurred] = useState(true);

  const onChangeText = useCallback(() => {
    if (error && setErrorMsg && typeof setErrorMsg === 'function') {
      setErrorMsg(null);
    }
  }, [error, setErrorMsg]);

  return (
    <div className="flex column full-width gap-3 inputContainerWrapper overflow-hidden">
      <div className="inputContainer flex">
        <input
          ref={inputRef}
          style={{height}}
          value={inputValue}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={showText ? 'password' : inputType}
          className={`input background_${type} ${
            !!search ? 'leftSpaceWithSearch' : ''
          } ${className ? className : ''}`}
          onBlur={() => setIsBlurred(true)}
          onFocus={() => {
            setIsBlurred(false);
            onFocusCb();
          }}
          onChange={e => {
            onChange(e?.target?.value);
            onChangeText(e?.target?.value);
          }}
          onWheel={() => inputRef.current?.blur()}
        />
        {password && (
          <img
            alt="eye"
            className="eyeIcon cursor-pointer"
            src={showText ? openedEye : closedEye}
            onClick={() => setShowText(prevState => !prevState)}
          />
        )}
        {search && (
          <div className="searchIconContainer flex items-center justify-center">
            <SearchIcon width={13} height={13} fill="var(--appBlue)" />
          </div>
        )}
        {checked && (
          <img alt="checked" src={checkedIcon} className="checkedIcon" />
        )}
      </div>
      {error && isBlurred && (
        <span className="redText text-caption ellipsis">{error}</span>
      )}
    </div>
  );
};

export default memo(Input);
