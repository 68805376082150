import React, {memo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';

import {AppSwitcher} from '../../../../UI';

import {ReactComponent as DownTriangle} from '../../../../../Assets/Icons/Globals/downTriangle.svg';

const Preferences = ({
  subscribedToSms,
  subscribedToEmail,
  setSubscribedToSms,
  setSubscribedToEmail,
  subscribedToPhoneCall,
  setSubscribedToPhoneCall,
  subscribedToPushNotification,
  subscribedToInternalMessages,
  setSubscribedToInternalMessages,
  setSubscribedToPushNotification,
}) => {
  const {t} = useTranslation();

  const [isExpanded, setExpanded] = useState(true);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  return (
    <div className="flex column gap-5 py-lg col-xs-12 col-sm-12 col-md-4">
      <div
        className="flex row items-center justify-between"
        {...getToggleProps({
          onClick: () => setExpanded(prevExpanded => !prevExpanded),
        })}>
        <span className="text-subtitle1 blueText bold-700">
          {t('preferences')}
        </span>
        <DownTriangle fill="var(--appBlue)" width={10} height={10} />
      </div>
      <div {...getCollapseProps()}>
        <span className="blueText text-body2">{t('notifyMeAbout')}</span>
        <div className="flex column gap-10 ml-lg mt-md">
          <AppSwitcher
            type={2}
            label={t('internalMessages')}
            isToggled={subscribedToInternalMessages}
            onToggle={() => setSubscribedToInternalMessages(prev => !prev)}
          />
          <AppSwitcher
            type={2}
            label={t('pushNotifications')}
            isToggled={subscribedToPushNotification}
            onToggle={() => setSubscribedToPushNotification(prev => !prev)}
          />
          <AppSwitcher
            type={2}
            label={t('phoneCall')}
            isToggled={subscribedToPhoneCall}
            onToggle={() => setSubscribedToPhoneCall(prev => !prev)}
          />
          <AppSwitcher
            type={2}
            label={t('email')}
            isToggled={subscribedToEmail}
            onToggle={() => setSubscribedToEmail(prev => !prev)}
          />
          <AppSwitcher
            type={2}
            label={t('sms')}
            isToggled={subscribedToSms}
            onToggle={() => setSubscribedToSms(prev => !prev)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Preferences);
