import React, {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useSocket} from '../../../../Hooks';
import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';

import {Flags} from '../../../../Constants/Flags';
import {GET_All_REGIONS} from '../../../../Constants/Socket';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {ReactComponent as DownTriangle} from '../../../../Assets/Icons/Globals/downTriangle.svg';
import {ReactComponent as RightTriangle} from '../../../../Assets/Icons/Globals/rightTriangle.svg';

import './index.scss';

const Regions = ({
  sportAlias,
  gameFilters,
  selectedRegion,
  setSelectedRegion,
}) => {
  const {getLeagues} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [regions, setRegions] = useState([]);
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);

  const onMessageCb = useCallback(async event => {
    const data = JSON.parse(event.data);

    switch (data?.rid) {
      case GET_All_REGIONS:
        setRegions(
          Object.values(data?.data?.data?.region || {})?.sort(
            (a, b) => a?.order - b?.order,
          ),
        );
        setIsRegionsLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsRegionsLoading(true);
    getLeagues({
      gameFilters,
      activeSportAlias: sportAlias,
    });
  }, []);

  return (
    <div className="regionsContainer">
      {isRegionsLoading ? (
        <RegionSkeletons />
      ) : (
        <div>
          {regions?.map(regionItem => {
            const ArrowIcon =
              selectedRegion?.id === regionItem?.id
                ? RightTriangle
                : DownTriangle;
            return (
              <div
                key={regionItem?.id}
                className={`regionItemContainer ${
                  selectedRegion?.id === regionItem?.id ? 'selected' : ''
                }`}
                onClick={e => {
                  e.stopPropagation();
                  if (selectedRegion?.id === regionItem?.id) {
                    setSelectedRegion(null);
                  } else {
                    setSelectedRegion(regionItem);
                  }
                }}>
                <div
                  className="itemContainer flex items-center justify-between cursor-pointer"
                  style={{
                    background:
                      selectedRegion?.id === regionItem?.id
                        ? 'var(--lightPurple)'
                        : '',
                  }}>
                  <div className="flex items-center gap-3">
                    <img
                      alt="flag"
                      className="image"
                      src={Flags?.[regionItem?.alias]}
                    />
                    <span className="blueText textWithDotes title">
                      {regionItem?.name}
                    </span>
                  </div>
                  <ArrowIcon fill="var(--appBlue)" className="arrowIcon" />
                </div>
                <div className="line" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default memo(Regions);
