import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {ReactComponent as Back} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as Minimize} from '../../../Assets/Icons/Globals/minimize.svg';

import DepositButton from '../../Header/DepositButton';

import './index.scss';

const GameActionBarHeader = ({setIsFullScreen}) => {
  const {t} = useTranslation();
  const [getParam, setGetParam] = useSearchParams();

  const onBackHandler = useCallback(() => {
    setIsFullScreen(false);
    getParam.delete('game');
    setGetParam(getParam);
  }, [getParam, setGetParam, setIsFullScreen]);

  return (
    <div className="casinoGameActionBarContainer flex row justify-between px-lg py-md items-center darkBlueBackground">
      <div
        onClick={onBackHandler}
        className="flex rowCenter gap-5 cursor-pointer blueBackground px-md py-sm rounded-borders">
        <Back width={10} height={10} fill="var(--white)" className="backIcon" />
        <span className="whiteText text-caption-small bold-700">
          {t('back')}
        </span>
      </div>

      <DepositButton />
      <div className="flex row items-center">
        <div
          onClick={() => setIsFullScreen(false)}
          className="flex row items-center cursor-pointer gap-5 blueBackground pa-sm rounded-borders">
          <Minimize
            width={18}
            height={18}
            fill="var(--white)"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};
export default memo(GameActionBarHeader);
