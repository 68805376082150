import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {AppButton} from '../../UI';
import HomeSportSkeletons from '../../UI/Skeletons/HomeSportSkeletons';

import {getProviders} from '../../../Redux/CasinoSlice/CasinoSelectors';
import {getIsContentScrolled} from '../../../Redux/AppSlice/AppSelectors';
import {getIsSportDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {PROVIDER_ICONS} from '../../../Constants/ProviderIcons';
import {PROVIDERS_ALL_GAMES_NAME} from '../../../Constants/CasinoMenuFilter';

import allProviders from '../../../Assets/Images/Casino/all.png';
import lastPlayedImage from '../../../Assets/Images/Casino/lastPlayed.png';

const HomeProviders = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const providers = useSelector(getProviders);
  const isContentScrolled = useSelector(getIsContentScrolled);
  const isSportDataLoading = useSelector(getIsSportDataLoading);

  const navigateToCategory = useCallback(
    path => {
      getParam.delete('search');
      setGetParam(getParam);
      navigate(path);
    },
    [getParam, navigate, setGetParam],
  );

  const renderSports = useMemo(
    () => (
      <>
        {width < BREAKPOINT_XS ? (
          <SwiperSlide key="lastPlayed">
            <AppButton
              type={6}
              width={110}
              active={location?.pathname?.includes('lastPlayed')}
              height={width < BREAKPOINT_XS && !isContentScrolled ? 60 : 'auto'}
              title={
                <div
                  onClick={() =>
                    navigate({
                      pathname: `/casino/${params?.category}/lastPlayed`,
                    })
                  }
                  className="columnCenter gap-5 lastPlayedContainer">
                  {!isContentScrolled && (
                    <img
                      width={25}
                      src={lastPlayedImage}
                      alt="lastPlayedIcon"
                    />
                  )}
                  <span className="bold-600 font-12">{t('lastPlayed')}</span>
                </div>
              }
            />
          </SwiperSlide>
        ) : (
          <SwiperSlide key="lastPlayed">
            <AppButton
              type={6}
              height={36}
              width="fit-content"
              active={location?.pathname?.includes('lastPlayed')}
              title={
                <div
                  onClick={() =>
                    navigate({
                      pathname: `/casino/${params?.category}/lastPlayed`,
                    })
                  }
                  className="row items-center justify-center gap-5 lastPlayedContainer">
                  {!isContentScrolled && (
                    <img
                      width={20}
                      src={lastPlayedImage}
                      alt="lastPlayedIcon"
                    />
                  )}
                  <span className="bold-600 font-12">{t('lastPlayed')}</span>
                </div>
              }
            />
          </SwiperSlide>
        )}
        <SwiperSlide key="allProviders">
          <AppButton
            type={6}
            height={width < BREAKPOINT_XS && !isContentScrolled ? 60 : 'auto'}
            title={
              width < BREAKPOINT_XS ? (
                <div className="columnCenter gap-5">
                  {!isContentScrolled && (
                    <img width={25} src={allProviders} alt="allProvidersIcon" />
                  )}
                  <span className="bold-600 font-12">
                    {t('allProvidersLower')}
                  </span>
                </div>
              ) : (
                t('allProviders')
              )
            }
            className="flex items-center gap-5"
            active={params?.provider === PROVIDERS_ALL_GAMES_NAME}
            onClick={() =>
              navigateToCategory(
                `/casino/${
                  params?.category ||
                  (params?.tab === 'all' ? 'slots' : params?.tab)
                }/${PROVIDERS_ALL_GAMES_NAME}`,
              )
            }
          />
        </SwiperSlide>

        {providers?.map(providerItem => (
          <SwiperSlide key={providerItem?.name}>
            <AppButton
              type={6}
              className="flex items-center gap-5"
              height={width < BREAKPOINT_XS && !isContentScrolled ? 60 : 'auto'}
              active={providerItem?.name === params?.provider}
              onClick={() =>
                navigateToCategory(
                  `/casino/${
                    params?.category ||
                    (params?.tab === 'all' ? 'slots' : params?.tab)
                  }/${providerItem?.name}`,
                )
              }
              title={
                isContentScrolled ? (
                  <span className="bold-600 font-12">
                    {providerItem?.title}
                  </span>
                ) : (
                  <img
                    alt={providerItem?.name}
                    src={PROVIDER_ICONS?.[providerItem?.name]}
                    style={{
                      width: 80,
                      objectFit: 'contain',
                      height: width < BREAKPOINT_XS ? 60 : 20,
                    }}
                  />
                )
              }
            />
          </SwiperSlide>
        ))}
      </>
    ),
    [
      t,
      width,
      navigate,
      providers,
      params?.tab,
      params?.category,
      params?.provider,
      isContentScrolled,
      navigateToCategory,
    ],
  );

  return isSportDataLoading ? (
    <HomeSportSkeletons isMobile={width < BREAKPOINT_XS} />
  ) : providers?.length > 0 ? (
    <Swiper
      freeMode
      spaceBetween={5}
      slidesPerView="auto"
      keyboard={width > BREAKPOINT_XS}
      mousewheel={width > BREAKPOINT_XS}
      navigation={width > BREAKPOINT_XS}
      slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
      slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
      modules={[
        ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
        FreeMode,
      ]}
      className={`flex items-center homeProvidersSwiper z-index-2 ${
        width < BREAKPOINT_XS ? 'mobile blueBackground' : 'desktop'
      }`}>
      {renderSports}
    </Swiper>
  ) : (
    <></>
  );
};

export default HomeProviders;
