import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import './index.scss';

import {MAIN_MENU_PROFILE_HEADER_ITEMS} from '../../../Constants/Menu';

import {
  TimeOut,
  SelfExclusion,
  DepositLimits,
  ChangePassword,
  PersonalDetails,
} from './Tabs';

import {AppButton} from '../../UI';

const Profile = () => {
  const {t} = useTranslation();

  const [pageIndex, setPageIndex] = useState(0);

  return (
    <div className="flex column full-width profileHeaderWrapper">
      <div className="flex row gap-5 justify-center">
        <Swiper
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard]}
          className="profileHeaderSwiper">
          {MAIN_MENU_PROFILE_HEADER_ITEMS?.map(({title, page}) => (
            <SwiperSlide key={title}>
              <AppButton
                type={2}
                width={180}
                toUpperCase
                fontSize={11}
                title={t(title)}
                active={pageIndex === page}
                onClick={() => setPageIndex(page)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {pageIndex === 0 ? (
        <PersonalDetails />
      ) : pageIndex === 1 ? (
        <ChangePassword />
      ) : pageIndex === 2 ? (
        <SelfExclusion />
      ) : pageIndex === 3 ? (
        <TimeOut />
      ) : pageIndex === 4 ? (
        <DepositLimits />
      ) : null}
    </div>
  );
};

export default memo(Profile);
