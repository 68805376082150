import React, {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {useSocket} from '../../../../Hooks';

import {CASH_OUT} from '../../../../Constants/Socket';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import blocked from '../../../../Assets/Icons/Globals/block.svg';

import {AppButton} from '../../../UI';

const CashOutActions = ({
  betId,
  cashOutAmount,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
}) => {
  const {t} = useTranslation();
  const {cashOut} = useSocket();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCashOutInProgress, setIsCashOutInProgress] = useState(false);

  const cashOutPressHandler = useCallback(
    e => {
      e.stopPropagation();
      if (showConfirmation) {
        setIsCashOutInProgress(true);
        cashOut({price: cashOutAmount, bet_id: betId});
      } else {
        setShowConfirmation(true);
      }
    },
    [betId, cashOut, cashOutAmount, showConfirmation],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case CASH_OUT:
          if (data?.data?.result === 'Ok') {
            cashOutedAmountRef.current = data?.data?.details?.price;
            setIsSuccessModalOpen(true);
          }
          setIsCashOutInProgress(false);
          break;
        default:
          break;
      }
    },
    [cashOutedAmountRef, setIsSuccessModalOpen],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="column gap-5">
      <AppButton
        small
        type={2}
        disabled={!cashOutAmount}
        loading={isCashOutInProgress}
        onClick={cashOutPressHandler}
        title={
          cashOutAmount ? (
            <span className="text-caption">
              <span className="whiteText bold-600">
                {t(showConfirmation ? 'confirmCashOut' : 'cashout')}
              </span>
              <span className="whiteText bold-600"> - </span>
              <span className="electricGreenText bold-700">
                {currency} {bigNumbersPrettier(cashOutAmount)}
              </span>
            </span>
          ) : (
            <div className="row items-center justify-center gap-5">
              <span className="whiteText bold-600 text-caption">
                {t('cashout')}
              </span>
              <img src={blocked} alt="blocked" height={15} width={15} />
            </div>
          )
        }
      />
      {showConfirmation && (
        <AppButton
          type={5}
          small
          onClick={e => {
            e.stopPropagation();
            setShowConfirmation(false);
          }}
          title={
            <span className="whiteText text-caption bold-600">
              {t('cancel')}
            </span>
          }
        />
      )}
    </div>
  );
};

export default memo(CashOutActions);
