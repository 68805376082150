import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import '../index.scss';

import {useOutSideClickHandler} from '../../../../Hooks';

import {getCompetitionsArray} from '../../../../Utils/GetSportId';

import Regions from '../Regions';
import {AppButton} from '../../../UI';
import SportButton from './SportButton';
import CompetitionsWrapper from '../Competitions/CompetitionsWrapper';

import {
  MATCH_FILTERS,
  FILTERS_LIVE_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_STATUS,
} from '../../../../Constants/MatchFilters';
import {FixedCompetitionsFilters} from '../../../../Constants/FixedCompetitionsFilters';

const SportItem = ({
  sportItem,
  isMenuOpen,
  setIsMenuOpen,
  pressedSportId,
  selectedRegion,
  setSelectedRegion,
  setPressedSportId,
}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [isExpanded, setExpanded] = useState(false);

  const containerRef = useRef(null);

  useOutSideClickHandler(containerRef, () => setExpanded(false));

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const onItemClick = useCallback(() => {
    setSelectedRegion({});
    if (!isExpanded) {
      setIsMenuOpen(true);
    }
    setPressedSportId(sportItem?.id);
    setExpanded(prevExpanded => {
      if (!!prevExpanded) {
        setPressedSportId(null);
        setSelectedRegion({});
      }
      return !prevExpanded;
    });
  }, [
    isExpanded,
    sportItem?.id,
    setIsMenuOpen,
    setPressedSportId,
    setSelectedRegion,
  ]);

  useEffect(() => {
    if (pressedSportId !== sportItem?.id && !!isExpanded) {
      setExpanded(false);
      setSelectedRegion({});
    }
  }, [isExpanded, sportItem?.id, pressedSportId]);

  useEffect(() => {
    if (!isExpanded) {
      setPressedSportId(null);
    }
  }, [isExpanded]);

  return (
    <div ref={containerRef}>
      <div
        className="sportItemContainer"
        {...getToggleProps({
          onClick: onItemClick,
        })}>
        <AppButton
          type={6}
          width={isMenuOpen ? '100%' : 40}
          className={
            isMenuOpen
              ? 'z-index-1'
              : 'flex items-center justify-center z-index-1'
          }
          active={
            isExpanded ||
            (sportItem?.alias === params?.sport && !pressedSportId)
          }
          title={
            <SportButton
              sportItem={sportItem}
              isExpanded={isExpanded}
              isMenuOpen={isMenuOpen}
            />
          }
        />
        <div {...getCollapseProps()}>
          <div className="regionsWrapper">
            {FixedCompetitionsFilters?.[sportItem?.alias]?.map(item => (
              <div
                key={item?.id}
                className="whiteBackground px-md"
                onClick={e => {
                  e.stopPropagation();
                  setSelectedRegion({});
                  navigate(
                    `/sports/${
                      params?.filter === FILTERS_LIVE_NAME
                        ? FILTERS_LIVE_NAME
                        : FILTERS_UPCOMING_MATCHES_NAME
                    }/${sportItem?.alias}/${item?.regionAlias}/${
                      item?.competitionId
                    }`,
                  );
                }}>
                <div
                  className="itemContainer flex items-center justify-between py-md px-xs"
                  style={{
                    borderRadius: 5,
                    background: getCompetitionsArray(
                      params?.competition,
                    )?.includes(item?.competitionId)
                      ? 'var(--lightPurple)'
                      : '',
                  }}>
                  <div className="flex items-center gap-3">
                    <img
                      width={25}
                      height={15}
                      src={item?.logo}
                      alt={item?.title}
                      style={{objectFit: 'contain'}}
                    />
                    <span
                      className="blueText textWithDotes title"
                      style={{maxWidth: 95}}>
                      {t(item?.title)}
                    </span>
                  </div>
                </div>
                <div className="line" />
              </div>
            ))}
            {!!isExpanded && isMenuOpen && (
              <Regions
                sportAlias={sportItem?.alias}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                gameFilters={MATCH_FILTERS?.[params?.filter]?.status}
              />
            )}
          </div>
        </div>
        {!!selectedRegion?.id && !!isExpanded && (
          <CompetitionsWrapper
            sportItem={sportItem}
            setExpanded={setExpanded}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            gameFilters={MATCH_FILTERS?.[params?.filter]?.status}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SportItem);
