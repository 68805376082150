import React, {memo, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import {Flags} from '../../../Constants/Flags';
import {BREAKPOINT_XS, BREAKPOINT_XXS} from '../../../Constants/Globals';

import {ReactComponent as Back} from '../../../Assets/Icons/Globals/topArrow.svg';

const Header = ({
  game,
  sport,
  region,
  startTime,
  isScrolled,
  competition,
  textInfoLabel,
  sortedGameInfo,
}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [_, setGetParam] = useSearchParams();

  const isLoggedIn = useSelector(getIsLoggedIn);

  const isSpecialBet = useMemo(
    () => game?.show_type === 'OUTRIGHT',
    [game?.show_type],
  );

  return (
    <div
      className={`headerContainer pa-md 
      ${
        width < BREAKPOINT_XS ? 'mt-sm' : ''
      } flex justify-between items-center blueBackground mx-sm rounded-borders ${
        !isLoggedIn ? 'mt-sm' : ''
      }`}>
      <div
        className={`flex items-center gap-${
          width < BREAKPOINT_XXS ? '5' : '20'
        } full-width`}>
        <div
          onClick={() => setGetParam({})}
          className="cursor-pointer whiteBackground rounded-borders row items-center justify-center py-sm px-md gap-5">
          <Back
            width={10}
            height={10}
            fill="var(--appBlue)"
            className="backIcon"
          />
          <span className="text-caption-small blueText bold-600">
            {t('back')}
          </span>
        </div>
        {width > BREAKPOINT_XS && (
          <div className="column text-caption whiteText bold-500">
            <span>{startTime?.split?.('-')?.[0]}</span>
            <span>{startTime?.split?.('-')?.[1]}</span>
          </div>
        )}
        <div className="line" />
        <div
          className={`whiteText full-width ${
            isSpecialBet ? 'column' : 'row'
          } items-center justify-between gap-5 overflow-hidden`}>
          {width < BREAKPOINT_XS && isSpecialBet && (
            <div className="text-caption yellowText bold-600 column items-center">
              <span>{startTime?.split?.('-')?.[0]}</span>
              <span>{startTime?.split?.('-')?.[1]}</span>
            </div>
          )}
          {game?.team1_name && (
            <div className="column gap-5 items-center justify-center overflow-hidden">
              {!isSpecialBet && (
                <img
                  width={35}
                  height={35}
                  alt={game?.team1_id}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    game?.team1_id / 2000,
                  )}/${game?.team1_id}.png`}
                />
              )}
              <span className="font-13 bold-600 text-center ellipsis full-width">
                {game?.team1_name}
              </span>
            </div>
          )}
          <div className="column overflow-hidden">
            {width < BREAKPOINT_XS && !isSpecialBet && (
              <div className="text-caption yellowText bold-600 column items-center">
                <span>{startTime?.split?.('-')?.[0]}</span>
                <span>{startTime?.split?.('-')?.[1]}</span>
              </div>
            )}
            {!!textInfoLabel && (
              <span className="bold-600 font-13 text-center ellipsis full-width">
                {textInfoLabel}
              </span>
            )}
            <div className="flex items-center overflow-hidden">
              {Flags?.[region?.alias] && (
                <img
                  alt="flag"
                  className="flag mr-sm"
                  src={Flags?.[region?.alias]}
                  width={width < BREAKPOINT_XS ? 15 : 25}
                  height={width < BREAKPOINT_XS ? 10 : 15}
                />
              )}
              <span className="bold-600 text-caption text-center ellipsis full-width">
                {competition?.name}
              </span>
            </div>
          </div>
          {game?.team2_name && (
            <div className="column gap-5 items-center justify-center overflow-hidden">
              <img
                width={40}
                height={40}
                alt={game?.team2_id}
                src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                  game?.team2_id / 2000,
                )}/${game?.team2_id}.png`}
              />

              <span className="font-13 bold-600 text-center ellipsis full-width">
                {game?.team2_name}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
