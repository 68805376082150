import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {AppButton, AppInput} from '../../UI';

import {setShowCasinoSearchModal} from '../../../Redux/CasinoSlice';

import lastPlayedImage from '../../../Assets/Images/Casino/lastPlayed.png';

import './index.scss';

const CasinoActionBar = ({inputValueRef, showLastPlayedButton = false}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();

  const timeOutRef = useRef(null);

  const getParamSearch = getParam.get('search');

  const [inputValue, setInputValue] = useState();

  const onReset = useCallback(() => {
    setInputValue('');
    getParam.delete('search');
    setGetParam(getParam);
  }, [getParam, setGetParam]);

  const goToLastPlayed = useCallback(() => {
    navigate({
      pathname: `/casino/lastPlayed/${params?.category}`,
    });
  }, [navigate, params?.category]);

  const onInputChange = useCallback(
    text => {
      if (!!text?.trim()) {
        setInputValue(text);
        clearTimeout(timeOutRef.current);
        timeOutRef.current = setTimeout(() => {
          setGetParam({search: text});
        }, 300);
      } else {
        clearTimeout(timeOutRef.current);
        setInputValue('');
        getParam.delete('search');
        setGetParam(getParam);
      }
    },
    [getParam, setGetParam, timeOutRef],
  );

  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  useEffect(() => {
    if (getParamSearch === null) {
      onReset();
    }
  }, [getParamSearch]);

  return (
    <div className="full-width mt-md rowCenter gap-10 mb-sm casinoActionBarContainer">
      {!!showLastPlayedButton && (
        <AppButton
          small
          type={7}
          height={35}
          width={140}
          className="items-center row justify-center"
          title={
            <div
              onClick={goToLastPlayed}
              className="rowCenter gap-5 lastPlayedContainer">
              <img
                width={20}
                height={20}
                alt="lastPlayedIcon"
                src={lastPlayedImage}
              />
              <span className="bold-600 font-12">{t('lastPlayed')}</span>
            </div>
          }
        />
      )}
      <AppInput
        search
        type={4}
        inputValue={''}
        className="mobileSize"
        placeholder={t('searchCasinoGame')}
        onFocusCb={() => dispatch(setShowCasinoSearchModal(true))}
      />
    </div>
  );
};

export default memo(CasinoActionBar);
