import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../Hooks';

import headerLogo from '../../Assets/Layout/HeaderLogo.png';
import investmentLogo from '../../Assets/Layout/investmentLogo.png';
import cashIcon from '../../Assets/Images/PaymentServices/cash.png';
import leGrandeCasino from '../../Assets/Layout/leGrandeCasinoWhite.png';
import palmBeachCasino from '../../Assets/Layout/palmBeachCasinoWhite.png';

import {
  IOS_APP_URL,
  CONTACT_EMAIL,
  BREAKPOINT_XS,
  CONTACT_NUMBER,
  ANDROID_APP_URL,
} from '../../Constants/Globals';
import {PAYMENT_SERVICES} from '../../Constants/PaymentServices';

import {AppModal, AppPrivacyPolicy} from '../UI';

const Border = () => <div className="footerBorder" />;

const Footer = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const [privacyModalTabIndex, setPrivacyModalTabIndex] = useState(null);

  return (
    <div
      className={`appFooter blueBackground pa-md ${
        width > BREAKPOINT_XS ? 'mx-md' : ''
      }`}>
      <div className="darkenBlueBackground flex column gap-20 pa-xxl">
        <div
          className={`flex row  wrap justify-between gap-20 linksBlock ${
            width < BREAKPOINT_XS ? 'full-width' : ''
          }`}>
          <div className="flex column gap-10 cursor-pointer">
            <span className="yellowText boldText font-13">{t('about')}</span>
            {/*<span className="whiteText bold-400 font-12">{t('contactUs')}</span>*/}
            <span
              className="whiteText bold-400 font-12"
              onClick={() => setPrivacyModalTabIndex(0)}>
              {t('generalTerms')}
            </span>
            <span
              className="whiteText bold-400 font-12"
              onClick={() => setPrivacyModalTabIndex(2)}>
              {t('responsibleGaming')}
            </span>
            <span
              className="whiteText bold-400 font-12"
              onClick={() => setPrivacyModalTabIndex(1)}>
              {t('privacyPolicy')}
            </span>
          </div>
          {/*<div className="flex column gap-10">*/}
          {/*  <span className="yellowText boldText font-13">{t('help')}</span>*/}
          {/*  <span className="whiteText bold-400 font-12">{t('faqs')}</span>*/}
          {/*  <span className="whiteText bold-400 font-12">*/}
          {/*    {t('sportsBetting')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className="flex column gap-10">*/}
          {/*  <span className="yellowText boldText font-13">*/}
          {/*    {t('statistics')}*/}
          {/*  </span>*/}
          {/*  <span className="whiteText bold-400 font-12">{t('results')}</span>*/}
          {/*  <span className="whiteText bold-400 font-12">*/}
          {/*    {t('matchCenter')}*/}
          {/*  </span>*/}
          {/*  <span className="whiteText bold-400 font-12">*/}
          {/*    {t('liveCalendar')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div className="flex column gap-10 cursor-pointer">
            <span className="yellowText boldText font-13">
              {t('applications')}
            </span>
            <span
              className="whiteText bold-400 font-12"
              onClick={() => window.open(IOS_APP_URL, '_blank')}>
              {t('downloadIosPigabetApp')}
            </span>
            <span
              className="whiteText bold-400 font-12"
              onClick={() => window.open(ANDROID_APP_URL, '_blank')}>
              {t('downloadAndroidPigabetApp')}
            </span>
          </div>
        </div>
        <Border />
        <div className="flex column gap-15 wrap items-center justify-center">
          <span className="boldText yellowText font-13">
            {t('mainAffiliates')}
          </span>
          <div className="flex row items-center gap-35">
            <img
              width={50}
              height={60}
              alt="leGrandeCasino"
              src={leGrandeCasino}
            />
            <img
              width={65}
              height={60}
              alt="palmBeachCasino"
              src={palmBeachCasino}
            />
          </div>
        </div>
        <Border />

        <div className="flex column items-center gap-15">
          <img src={headerLogo} width={90} height={45} alt="footerLogo" />
          <div className="flex column items-center gap-5">
            <span className="whiteText font-12">
              {t('copyRight', {year: new Date().getFullYear()})}
            </span>
            <div>
              <span className="yellowText bold-400 font-13">
                {t('contact')}
              </span>
              <span className="whiteText font-12"> {CONTACT_NUMBER} | </span>
              <span className="yellowText bold-400 font-13">{t('email')}</span>
              <span className="whiteText font-12">: {CONTACT_EMAIL}</span>
            </div>
          </div>
        </div>
        <Border />

        <div className="flex row items-center justify-center">
          <div className="justify-center flex row items-center gap-25 wrap paymentsBlock">
            {PAYMENT_SERVICES.map(item => (
              <img
                width={80}
                height={35}
                key={item?.id}
                alt={item?.title}
                className="paymentIcon"
                src={item?.logo || cashIcon}
              />
            ))}
          </div>
        </div>
        <Border />

        <div className="flex column items-center gap-5">
          <img src={investmentLogo} width={90} alt="investmentLogo" />
          <span className="whiteText font-12 textCenter">
            {t('footerText')}
          </span>
        </div>
        <Border />

        <div className="flex column items-center gap-5">
          <span className="yellowText boldText font-20">18+</span>
          <span className="whiteText textCenter font-12">
            {t('ourSiteIsOnlyOpen')}
          </span>
        </div>
      </div>
      <AppModal
        isOpen={
          privacyModalTabIndex === 0 ||
          privacyModalTabIndex === 1 ||
          privacyModalTabIndex === 2
        }
        onRequestClose={() => setPrivacyModalTabIndex(null)}>
        <AppPrivacyPolicy tabIndex={privacyModalTabIndex} />
      </AppModal>
    </div>
  );
};

export default memo(Footer);
