import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useNavigate, useParams} from 'react-router-dom';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';

import './index.scss';

import {
  getSportsArray,
  getIsGameDataLoading,
  getIsSportDataLoading,
} from '../../../Redux/SportSlice/SportSelectors';
import {setActiveFilterParams} from '../../../Redux/SportSlice';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {SportIcons} from '../../../Constants/SportIcons';
import {MATCH_FILTERS} from '../../../Constants/MatchFilters';

import {AppButton} from '../../UI';
import HomeSportSkeletons from '../../UI/Skeletons/HomeSportSkeletons';

const HomeSports = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const sportData = useSelector(getSportsArray);
  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const isSportDataLoading = useSelector(getIsSportDataLoading);

  const changeSport = useCallback(
    pressedItem => {
      navigate(
        `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
          pressedItem?.alias
        }`,
      );
      window.scrollTo(0, 0);
    },
    [navigate, params?.filter],
  );

  const renderSports = useMemo(
    () =>
      sportData?.map(sportItem => (
        <SwiperSlide
          key={sportItem?.alias}
          className={`item ${width > BREAKPOINT_XS ? 'pt-md' : ''}`}>
          <AppButton
            type={7}
            onClick={() => changeSport(sportItem)}
            disabledWithoutLayer={isGameDataLoading}
            className="items-center column py-sm px-lg"
            active={sportItem?.alias === params?.sport}
            title={
              <div className="items-center justify-center column">
                <img
                  alt="sportIcon"
                  src={SportIcons?.[sportItem?.alias]}
                  width={width > BREAKPOINT_XS ? 25 : 30}
                  height={width > BREAKPOINT_XS ? 25 : 30}
                />
                <span className="text-caption-small bold-700">
                  {sportItem?.name}{' '}
                  {width > BREAKPOINT_XS && `(${sportItem?.game})`}
                </span>
                {width < BREAKPOINT_XS && (
                  <span className="absolute-top-right text-caption-extra-small bold-600 mobileCounter">
                    {sportItem?.game}
                  </span>
                )}
              </div>
            }
          />
        </SwiperSlide>
      )),
    [isGameDataLoading, changeSport, params?.sport, sportData, width],
  );

  useEffect(() => () => dispatch(setActiveFilterParams('')), []);

  return isSportDataLoading ? (
    <HomeSportSkeletons isMobile />
  ) : sportData?.length > 0 ? (
    <Swiper
      freeMode
      slidesPerView="auto"
      keyboard={width > BREAKPOINT_XS}
      mousewheel={width > BREAKPOINT_XS}
      navigation={width > BREAKPOINT_XS}
      spaceBetween={width > BREAKPOINT_XS ? 8 : 5}
      slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
      slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
      className="homeSportsSwiper flex items-center full-width"
      modules={[
        FreeMode,
        ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
      ]}>
      {renderSports}
    </Swiper>
  ) : (
    <></>
  );
};

export default memo(HomeSports);
