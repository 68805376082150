import React, {useCallback, useMemo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import EventsList from './EventsList';

import {useStorage} from '../../../Hooks';

import {formatEventName} from '../../../Utils/EventNamesFormatter';

import {ReactComponent as StarIcon} from '../../../Assets/Icons/Globals/star.svg';
import {ReactComponent as CashoutIcon} from '../../../Assets/Icons/Globals/cashout.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {ReactComponent as StarActiveIcon} from '../../../Assets/Icons/Globals/starWithBackground.svg';

const MarketListItem = ({
  index,
  team1Name,
  team2Name,
  marketItem,
  favouriteMarkets,
  setFavouriteMarkets,
}) => {
  const betSlipCount = useSelector(getBetSlipCount);

  const [isExpanded, setExpanded] = useState(true);

  const {setFavouriteMarketsToStorage} = useStorage();

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  const TriangleIcon = useMemo(
    () => (isExpanded ? TopTriangle : DownTriangle),
    [isExpanded],
  );

  const isFavourite = useMemo(
    () => !!favouriteMarkets?.includes(marketItem?.market_type),
    [favouriteMarkets, marketItem?.market_type],
  );

  const changeFavouriteItem = useCallback(
    e => {
      setFavouriteMarkets(prevState => {
        e.stopPropagation();
        const newData = [...prevState];
        const itemIndex = newData.indexOf(marketItem?.market_type);
        if (itemIndex > -1) {
          newData.splice(itemIndex, 1);
        } else {
          newData?.push(marketItem?.market_type);
        }
        return newData;
      });
      setFavouriteMarketsToStorage(marketItem?.market_type)?.then?.();
    },
    [
      setFavouriteMarkets,
      marketItem?.market_type,
      setFavouriteMarketsToStorage,
    ],
  );

  const Star = !!isFavourite ? StarActiveIcon : StarIcon;

  return (
    <div className="full-width py-xxs px-sm marketContainerRadius blueBackground">
      <div
        style={{border: '1px solid white'}}
        className="flex justify-between items-center mb-sm rounded-borders px-md py-xs"
        {...getToggleProps({
          onClick: () => setExpanded(prevState => !prevState),
        })}>
        <div className="flex gap-5 items-center">
          <div onClick={changeFavouriteItem} className="pt-xs">
            <Star
              width={15}
              height={15}
              fill={!!isFavourite ? 'var(--appYellow)' : 'var(--white)'}
            />
          </div>
          <span className="whiteText font-13 bold-700">
            {formatEventName({
              team1Name,
              team2Name,
              name: marketItem?.name,
            })}
          </span>
        </div>
        <div className="row items-center gap-5">
          {marketItem?.cashout && <CashoutIcon width={15} height={15} />}
          <TriangleIcon width={12} height={12} fill="white" />
        </div>
      </div>
      <div {...getCollapseProps()}>
        {marketItem?.data?.map((eventItem, index) => (
          <div className="flex row wrap justify-between" key={index}>
            <EventsList
              key={betSlipCount}
              team1Name={team1Name}
              team2Name={team2Name}
              marketItem={eventItem}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
// DO NOT PUT IN MEMO
export default MarketListItem;
