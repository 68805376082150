import Modal from 'react-modal';
import {memo} from 'react';

import './index.scss';

import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../Constants/Globals';

import {AppModalHeader} from '../index';

import close from '../../../Assets/Icons/Globals/close.svg';

const AppModal = ({
  isOpen,
  children,
  header = null,
  headerType = 1,
  maxWidth = '1000px',
  maxHeight = '90dvh',
  onAfterOpen = () => {},
  isCloseDisabled = false,
  onRequestClose = () => {},
}) => {
  const style = {
    content: {
      maxHeight,
      border: 0,
      padding: 0,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '95%',
      width: maxWidth,
      display: 'flex',
      borderRadius: 15,
      overflowY: 'auto',
      marginRight: '-50%',
      flexWrap: 'no-wrap',
      flexDirection: 'column',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 150,
      background: 'var(--lightBlack)',
    },
  };

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      bodyOpenClassName="overflow-hidden"
      htmlOpenClassName="overflow-hidden"
      closeTimeoutMS={APP_MODAL_CLOSE_TIMEOUT_MS}
      shouldCloseOnOverlayClick={!isCloseDisabled}
      onRequestClose={isCloseDisabled ? () => {} : onRequestClose}>
      {!isCloseDisabled && (
        <img
          src={close}
          alt="close"
          onClick={onRequestClose}
          className="closeIcon cursor-pointer"
        />
      )}
      {!!header && <AppModalHeader type={headerType}>{header}</AppModalHeader>}
      <div className="contentContainer scroll-auto-y scroll-1 items-start">
        {children}
      </div>
    </Modal>
  );
};

export default memo(AppModal);
