import moment from 'moment';

export const FILTERS_LIVE_NAME = 'live';
export const FILTERS_TODAY_NAME = 'today';
export const FILTERS_PROMOTIONS_NAME = 'promotions';
export const FILTERS_BOOSTED_ODDS_NAME = 'boostedOdds';
export const FILTERS_MULTIPLE_DAY_NAME = 'multipleDay';
export const FILTERS_POPULAR_MATCHES_NAME = 'popularMatches';
export const FILTERS_UPCOMING_MATCHES_NAME = 'upcomingMatches';
export const FILTERS_POPULAR_COMPETITIONS_NAME = 'popularCompetitions';

export const HEADER_FILTERS_POKER = 'headerFiltersPoker';
export const HEADER_FILTERS_SLOTS = 'headerFiltersSlots';
export const HEADER_FILTERS_SPORTS = 'headerFiltersSports';
export const HEADER_FILTERS_LIVE_CASINO = 'headerFiltersLiveCasino';
export const HEADER_FILTERS_13_MATCH_JACKPOT = 'headerFilters13Matchjackpot';

export const FOOTBALL_ID = 1;
export const FOOTBALL_ALIAS = 'Soccer';

export const LIMIT_OF_ALL_GAMES = 50;
export const LIMIT_OF_TODAY_FILTER_GAMES = 150;

export const FILTERS_MATCH_WINNERS = {
  display_key: 'WINNER',
  display_sub_key: 'MATCH',
};

export const FILTERS_LIVE_STATUS = {
  game: {type: 1},
};

export const FILTERS_TODAY_STATUS = {
  game: {
    // start_ts: {
    //   '@gte': getTodayUnix()?.start_date,
    //   '@lte': getTodayUnix()?.end_date,
    // },
    start_ts: {
      '@now': {
        '@gte': 0,
        '@lt': moment?.()?.endOf?.('day')?.diff?.(moment?.(), 'seconds'),
      },
    },
    type: {'@ne': 1},
  },
};

export const FILTERS_BOOSTED_ODDS_STATUS = 'boostedStatus';
export const FILTERS_PROMOTIONS_STATUS = 'promotionsStatus';
export const FILTERS_MULTIPLE_DAY_STATUS = 'multipleDayStatus';
export const FILTERS_UPCOMING_MATCHES_STATUS = {
  game: {
    '@or': [{type: {'@in': [0, 2]}}, {visible_in_prematch: 1, type: 0}],
  },
};

export const FILTERS_POPULAR_MATCHES_STATUS = {
  game: {promoted: true, type: {'@ne': 1}},
};

export const FILTERS_FEATURED_MATCHES_STATUS = {
  sport: {type: {'@ne': 0}},
  market: FILTERS_MATCH_WINNERS,
  game: {promoted: true, '@limit': LIMIT_OF_ALL_GAMES},
};

export const FILTERS_POPULAR_COMPETITIONS_STATUS = {
  competition: {favorite: true},
  game: {type: {'@ne': 1}},
};

export const MARKETS_WINNER = 'WINNER';
export const MARKETS_TOTALS = 'TOTALS';
export const MARKETS_ODD_EVEN = 'ODD/EVEN';
export const MARKETS_HANDICAP = 'HANDICAP';
export const MARKETS_OUTRIGHT = 'OUTRIGHT';

export const MARKETS_DOUBLE_CHANCE = 'DOUBLE CHANCE';
export const MARKETS_BOTH_TEAM_TO_SCORE = 'BOTHTEAMTOSCORE';

export const MARKETS_FIXED_COUNTS = {
  [MARKETS_WINNER]: 3,
  [MARKETS_TOTALS]: 2,
  [MARKETS_ODD_EVEN]: 2,
  [MARKETS_HANDICAP]: 2,
  [MARKETS_DOUBLE_CHANCE]: 3,
  [MARKETS_BOTH_TEAM_TO_SCORE]: 2,
};

export const FOOTBALL_MARKETS = {
  '@or': [
    {display_key: MARKETS_OUTRIGHT},
    {'@and': [{display_key: MARKETS_WINNER}, {display_sub_key: 'MATCH'}]},
    {
      '@and': [
        {display_sub_key: 'MATCH'},
        {display_key: MARKETS_HANDICAP},
        {market_type: 'AsianHandicapAsian'},
      ],
    },
    {
      '@and': [
        {display_sub_key: 'MATCH'},
        {display_key: MARKETS_DOUBLE_CHANCE},
      ],
    },
    {
      '@and': [
        {display_sub_key: 'MATCH'},
        {display_key: MARKETS_BOTH_TEAM_TO_SCORE},
      ],
    },
    {
      '@and': [
        {market_type: 'OverUnder'},
        {display_sub_key: 'MATCH'},
        {display_key: MARKETS_TOTALS},
      ],
    },
    {
      '@and': [{display_key: MARKETS_ODD_EVEN}, {display_sub_key: 'MATCH'}],
    },
  ],
};

export const SPORTS_MARKETS = {
  '@or': [
    {display_key: MARKETS_OUTRIGHT},
    {'@and': [{display_key: MARKETS_WINNER}, {display_sub_key: 'MATCH'}]},
    {
      '@and': [
        {display_sub_key: 'MATCH'},
        {display_key: MARKETS_TOTALS},
        {market_type: {'@or': ['MatchTotal', 'OverUnder']}},
      ],
    },
    {
      '@and': [{display_key: MARKETS_HANDICAP}, {display_sub_key: 'MATCH'}],
    },
  ],
};

export const MATCH_FILTERS = {
  [FILTERS_LIVE_NAME]: {
    icon: FILTERS_LIVE_NAME,
    name: FILTERS_LIVE_NAME,
    status: FILTERS_LIVE_STATUS,
  },
  [FILTERS_TODAY_NAME]: {
    name: FILTERS_TODAY_NAME,
    status: FILTERS_TODAY_STATUS,
    icon: FILTERS_UPCOMING_MATCHES_NAME,
  },
  [FILTERS_UPCOMING_MATCHES_NAME]: {
    icon: FILTERS_UPCOMING_MATCHES_NAME,
    name: FILTERS_UPCOMING_MATCHES_NAME,
    status: FILTERS_UPCOMING_MATCHES_STATUS,
  },
  [FILTERS_POPULAR_MATCHES_NAME]: {
    icon: FILTERS_POPULAR_MATCHES_NAME,
    name: FILTERS_POPULAR_MATCHES_NAME,
    status: FILTERS_POPULAR_MATCHES_STATUS,
  },
  [FILTERS_POPULAR_COMPETITIONS_NAME]: {
    icon: FILTERS_POPULAR_COMPETITIONS_NAME,
    name: FILTERS_POPULAR_COMPETITIONS_NAME,
    status: FILTERS_POPULAR_COMPETITIONS_STATUS,
  },
  [FILTERS_BOOSTED_ODDS_NAME]: {
    icon: FILTERS_BOOSTED_ODDS_NAME,
    name: FILTERS_BOOSTED_ODDS_NAME,
    status: FILTERS_BOOSTED_ODDS_STATUS,
  },
  [FILTERS_PROMOTIONS_NAME]: {
    icon: FILTERS_PROMOTIONS_NAME,
    name: FILTERS_PROMOTIONS_NAME,
    status: FILTERS_PROMOTIONS_STATUS,
  },
  // [FILTERS_MULTIPLE_DAY_NAME]: {
  //   icon: FILTERS_MULTIPLE_DAY_NAME,
  //   status: FILTERS_MULTIPLE_DAY_STATUS,
  // },
};

export const HEADER_FILTERS = {
  [HEADER_FILTERS_SPORTS]: {
    icon: HEADER_FILTERS_SPORTS,
    name: HEADER_FILTERS_SPORTS,
    path: `/sports/${FILTERS_TODAY_NAME}`,
    activeState: 'sports',
  },
  [HEADER_FILTERS_SLOTS]: {
    path: '/casino/slots',
    icon: HEADER_FILTERS_SLOTS,
    name: HEADER_FILTERS_SLOTS,
    activeState: 'slots',
  },
  [HEADER_FILTERS_LIVE_CASINO]: {
    path: '/casino/liveCasino',
    icon: HEADER_FILTERS_LIVE_CASINO,
    name: HEADER_FILTERS_LIVE_CASINO,
    activeState: 'liveCasino',
  },
  [HEADER_FILTERS_POKER]: {
    path: '/casino/poker',
    icon: HEADER_FILTERS_POKER,
    name: HEADER_FILTERS_POKER,
    activeState: 'poker',
  },
  [HEADER_FILTERS_13_MATCH_JACKPOT]: {
    path: '/jackpot',
    name: HEADER_FILTERS_13_MATCH_JACKPOT,
    activeState: 'jackpot',
  },
};
