import React, {memo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useScreenSize} from '../../Hooks';

import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppButton} from '../UI';
import BalanceDropDown from '../Home/BalanceDropDown';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getUser} from '../../Redux/UserSlice/UserSelectors';

import {bigNumbersPrettier} from '../../Utils/BigNumbersPrettier';

import tzs from '../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as WalletIcon} from '../../Assets/Icons/Globals/wallet.svg';

import './index.scss';

const DepositButton = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);

  const balanceDropDownContainerRef = useRef(null);

  const [hideBalance, setHideBalance] = useState(false);
  const [showBalanceDropDown, setShowBalanceDropDown] = useState(false);

  return (
    <div
      className={`depositContainer flex row items-center justify-between gap-5 ${
        width < BREAKPOINT_XS ? 'mobile overflow-hidden ' : 'desktop'
      }`}>
      <div
        className={`flex row items-center full-width  ${
          width < BREAKPOINT_XS ? 'overflow-hidden' : 'gap-5'
        }`}>
        <img src={tzs} alt="currencyIcon" width={25} height={25} />
        <div
          ref={balanceDropDownContainerRef}
          className={`relative balanceDropDown full-width ${
            width < BREAKPOINT_XS ? 'overflow-hidden' : ''
          }  ${showBalanceDropDown ? 'balanceDropDown_opened' : ''}`}>
          <span
            // onClick={
            //   width < BREAKPOINT_XS
            //     ? () => {}
            //     : () => setShowBalanceDropDown(prev => !prev)
            // }
            className={`text-caption-small full-width block ${
              showBalanceDropDown ? 'blueText' : 'yellowText'
            } boldText userBalanceText cursor-pointer block py-xs px-sm ${
              width < BREAKPOINT_XS ? 'mobile' : 'desktop'
            }`}>
            {hideBalance ? 'XXX,XXX,XXX' : bigNumbersPrettier(user?.balance)}
          </span>
          {showBalanceDropDown && (
            <BalanceDropDown
              hideBalance={hideBalance}
              setHideBalance={setHideBalance}
              contentRef={balanceDropDownContainerRef}
              setShowBalanceDropDown={setShowBalanceDropDown}
            />
          )}
        </div>
      </div>
      {width > BREAKPOINT_XS ? (
        <AppButton
          type={1}
          fontSize={11}
          width="fit-content"
          className="py-xxs px-sm"
          title={t('depositUpper')}
          onClick={() =>
            dispatch(
              setMainMenuModal({
                tabIndex: 0,
                isVisible: true,
                balanceManagementTabIndex: 0,
              }),
            )
          }
        />
      ) : (
        <div
          className="yellowBackground pa-sm rounded-borders flex items-center justify-center"
          onClick={() =>
            dispatch(
              setMainMenuModal({
                tabIndex: 0,
                isVisible: true,
                balanceManagementTabIndex: 0,
              }),
            )
          }>
          <WalletIcon width={13} height={13} fill="var(--appBlue)" />
        </div>
      )}
    </div>
  );
};

export default memo(DepositButton);
