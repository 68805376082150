import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const PopularCompetitionSkeletons = () => (
  <Skeleton
    count={10}
    height={32}
    width={180}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--darkenGray)"
    containerClassName="row gap-5"
    highlightColor="var(--lightGray)"
  />
);

export default memo(PopularCompetitionSkeletons);
