import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const GameStatsSkeleton = () => (
  <Skeleton
    count={1}
    height={105}
    width="100%"
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(GameStatsSkeleton);
