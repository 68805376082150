import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './index.scss';

import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../Hooks';

import {getThisWeekUnix} from '../../../Utils/GetDatePeriods';

import {BREAKPOINT_SM} from '../../../Constants/Globals';
import {GET_TRANSACTION_HISTORY} from '../../../Constants/Socket';

import TransactionHistoryItem from './TransactionHistoryItem';
import TransactionHistoryFilters from './TransactionHistoryFilters';

import TransactionHistoryRowSkeleton from '../../UI/Skeletons/TransactionHistoryRowSkeleton';

const TransactionHistory = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const {getTransactionHistory} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [historyData, setHistoryData] = useState([]);
  const [toDate, setToDate] = useState(getThisWeekUnix()?.end_date);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(getThisWeekUnix()?.start_date);

  const ListEmptyComponent = useMemo(
    () => (
      <div className="flex items-center justify-center fit">
        <span className="blueText bold-600 text-body1">
          {t('emptyTransactions')}
        </span>
      </div>
    ),
    [t],
  );

  useEffect(() => {
    setIsDataLoading(true);
    getTransactionHistory({from_date: fromDate, to_date: toDate});
  }, []);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event.data);
    switch (data?.rid) {
      case GET_TRANSACTION_HISTORY:
        setHistoryData(data?.data?.details);
        setIsDataLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="column full-width transactionHistoryContainer">
      <TransactionHistoryFilters
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        setFromDate={setFromDate}
        isDataLoading={isDataLoading}
        setIsDataLoading={setIsDataLoading}
      />

      <div className="column full-height">
        <div className="full-height">
          <div className="line mt-md" />
          {width > BREAKPOINT_SM && !!historyData?.length && (
            <div className="row  py-md px-md py-sm full-width wrap blueText text-caption bold-700">
              <div className="col-xs-12 col-md-2">
                <span>{t('id')}</span>
              </div>
              <div className="col-xs-12 col-md-3">
                <span>{t('date')}</span>
              </div>
              <div className="col-xs-12 col-md-3">
                <span>{t('type')}</span>
              </div>
              <div
                className={`col-xs-12 col-md-2 row justify-${
                  width > BREAKPOINT_SM ? 'end' : 'between'
                }`}>
                <span>{t('amount')}</span>
              </div>
              <div
                className={`col-xs-12 col-md-2 row justify-${
                  width > BREAKPOINT_SM ? 'end' : 'between'
                }`}>
                <span>{t('finalBalance')}</span>
              </div>
            </div>
          )}
          {isDataLoading ? (
            <TransactionHistoryRowSkeleton />
          ) : !!historyData?.length ? (
            historyData?.map((item, index) => (
              <TransactionHistoryItem
                item={item}
                key={item?.TransactionId}
                isColored={index % 2 === 0}
              />
            ))
          ) : (
            ListEmptyComponent
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TransactionHistory);
