import React, {memo, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import {SITE_ID} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {CATEGORY_POKER_ID} from '../../../Constants/CasinoMenuFilter';

import {AppButton} from '../../../Components/UI';

const Poker = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setAuthModal({isVisible: true}));
    }
  }, [isLoggedIn]);

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${CATEGORY_POKER_ID}` +
        '&openType=real' +
        `&token=${user?.auth_token}`
      )?.trim(),
    [user?.auth_token],
  );

  return isLoggedIn ? (
    <iframe
      src={src}
      scrolling="no"
      className={`pokerGameIframe ${
        width < BREAKPOINT_XS ? 'mobile' : 'desktop'
      }`}
      title="pigabet_poker"
    />
  ) : (
    <div className="my-xxl">
      <AppButton
        type={1}
        title={t('signInForPlay')}
        onClick={() => dispatch(setAuthModal({isVisible: true}))}
      />
    </div>
  );
};

export default memo(Poker);
