import React, {memo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';

import {AppButton} from '../../UI';

import {getProviders} from '../../../Redux/CasinoSlice/CasinoSelectors';

import {PROVIDER_ICONS} from '../../../Constants/ProviderIcons';
import {PROVIDERS_ALL_GAMES_NAME} from '../../../Constants/CasinoMenuFilter';

const Providers = ({isMenuOpen = true}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [getParam, setGetParam] = useSearchParams();

  const providers = useSelector(getProviders);

  const navigateToCategory = useCallback(
    path => {
      getParam.delete('search');
      setGetParam(getParam);
      navigate(path);
    },
    [getParam, navigate, setGetParam],
  );

  return (
    <>
      <AppButton
        type={6}
        title={t('allProviders')}
        width={isMenuOpen ? '100%' : 120}
        active={params?.provider === PROVIDERS_ALL_GAMES_NAME}
        onClick={() =>
          navigateToCategory(
            `/casino/${
              params?.category ||
              (params?.tab === 'all' ? 'slots' : params?.tab)
            }/${PROVIDERS_ALL_GAMES_NAME}`,
          )
        }
        className={
          isMenuOpen
            ? 'z-index-1'
            : 'flex items-center justify-center z-index-1'
        }
      />
      {providers?.map(providerItem => (
        <AppButton
          type={6}
          key={providerItem?.name}
          width={isMenuOpen ? '100%' : 120}
          active={providerItem?.name === params?.provider}
          onClick={() =>
            navigateToCategory(
              `/casino/${
                params?.category ||
                (params?.tab === 'all' ? 'slots' : params?.tab)
              }/${providerItem?.name}`,
            )
          }
          title={
            isMenuOpen ? (
              <img
                alt={providerItem?.name}
                src={PROVIDER_ICONS?.[providerItem?.name]}
                style={{width: 80, height: 20, objectFit: 'contain'}}
              />
            ) : (
              providerItem?.title
            )
          }
          className={
            isMenuOpen
              ? 'z-index-1'
              : 'flex items-center justify-center z-index-1'
          }
        />
      ))}
    </>
  );
};

export default memo(Providers);
