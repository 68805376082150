import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

import './index.scss';

import {getGameStartTime} from '../../../Utils/TimeConvertors';

import {
  getBetSlipCount,
  getWssSocket,
} from '../../../Redux/AppSlice/AppSelectors';

import {Flags} from '../../../Constants/Flags';

import {ReactComponent as TimerIcon} from '../../../Assets/Icons/Globals/timer.svg';

import EventItem from '../Games/EventItem';

const GameItem = ({game, subId}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [getParam] = useSearchParams();

  const getMarketParam = getParam.get('market');

  const wssSocket = useSelector(getWssSocket);
  const betSlipCount = useSelector(getBetSlipCount);

  const marketsRef = useRef({});

  const [currentMarkets, setCurrentMarkets] = useState(game?.market);
  const [currentMarketsCount, setCurrentMarketsCount] = useState(
    game?.markets_count,
  );

  const winnerMarket = useMemo(
    () => Object.values(currentMarkets || {})?.[0],
    [currentMarkets],
  );

  const eventsArray = useMemo(
    () => Object.values(winnerMarket?.event || {}),
    [winnerMarket?.event],
  );

  const renderEvents = useMemo(
    () =>
      Array.apply(null, Array(winnerMarket?.col_count))?.map((_, index) => {
        const eventItem = eventsArray?.find(
          eventItem => +eventItem?.order === index,
        );

        return (
          <div
            className="flex justify-center fit"
            key={!!eventItem ? eventItem?.id : game?.id + index}>
            <EventItem
              featuredGame
              gameId={game?.id}
              isFirst={index === 0}
              eventId={eventItem?.id}
              price={eventItem?.price}
              marketId={winnerMarket?.id}
              lastPrice={eventItem?.lastPrice}
              isLast={index === eventsArray?.length - 1}
            />
          </div>
        );
      }),
    [eventsArray, game?.id, winnerMarket?.col_count, winnerMarket?.id],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      if (data?.data?.[subId]) {
        for (const sportItem in data?.data?.[subId]?.sport) {
          for (const regionItem in data?.data?.[subId]?.sport?.[sportItem]
            ?.region) {
            for (const competitionItem in data?.data?.[subId]?.sport?.[
              sportItem
            ]?.region?.[regionItem]?.competition) {
              for (const gameItem in data?.data?.[subId]?.sport?.[sportItem]
                ?.region?.[regionItem]?.competition?.[competitionItem]?.game) {
                if (+gameItem === +game?.id) {
                  const changedGame =
                    data?.data?.[subId]?.sport?.[sportItem]?.region?.[
                      regionItem
                    ]?.competition?.[competitionItem]?.game?.[gameItem];

                  if (typeof changedGame?.markets_count === 'number') {
                    setCurrentMarketsCount(changedGame?.markets_count);
                  }

                  if (changedGame?.market) {
                    // HANDLE MARKET CHANGES
                    const prevMarkets = {...marketsRef.current};

                    for (const market in changedGame?.market) {
                      if (changedGame?.market?.[market] === null) {
                        // HANDLE MARKET DELETING CASE
                        delete prevMarkets[market];
                      } else if (
                        typeof prevMarkets?.[market] === 'undefined' ||
                        Object.keys(prevMarkets?.[market])?.length === 0
                      ) {
                        // HANDLE MARKET ADDING CASE
                        prevMarkets[market] = changedGame?.market?.[market];
                      } else if (prevMarkets?.[market]) {
                        // HANDLE MARKET PRICE CHANGING
                        const changedEventObject =
                          changedGame?.market?.[market]?.event;

                        const prevEvents = {...prevMarkets[market].event};

                        for (const changedEvent in changedEventObject) {
                          if (changedEventObject[changedEvent] === null) {
                            // HANDLE EVENT DELETING CASE
                            delete prevEvents[changedEvent];
                          } else if (
                            typeof prevEvents[changedEvent] === 'undefined' ||
                            Object.keys(prevEvents[changedEvent])?.length === 0
                          ) {
                            // HANDLE EVENT ADDING CASE
                            prevEvents[changedEvent] =
                              changedGame?.market?.[market].event[changedEvent];
                          } else if (prevEvents?.[changedEvent]) {
                            // HANDLE EVENT PRICE CHANGING
                            prevEvents[changedEvent] = {
                              ...(prevEvents?.[changedEvent] || {}),
                              ...(changedEventObject?.[changedEvent] || {}),
                              lastPrice: prevEvents[changedEvent]?.price,
                            };
                          }
                        }
                        prevMarkets[market].event = prevEvents;
                      }
                    }
                    setCurrentMarkets(prevMarkets);
                  }
                }
              }
            }
          }
        }
      }
    },
    [game?.id, subId],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    marketsRef.current = currentMarkets;
  }, [currentMarkets]);

  return (
    <div
      onClick={() =>
        navigate({
          pathname: location?.pathname,
          search: createSearchParams({
            game: game?.id,
            ...(getMarketParam ? {market: getMarketParam} : {}),
          }).toString(),
        })
      }
      className="featuredGameItemContainer px-md pb-md column justify-between">
      <div
        className="flex justify-between items-center gap-5"
        style={{borderBottom: '2px solid var(--lightGray)'}}>
        <div className="flex items-center gap-5 overflow-hidden">
          <TimerIcon fill="var(--appBlue)" width={12} height={12} />
          <span className="blueText text-caption-extra-small bold-500 textWithDotes">
            {getGameStartTime(game?.start_ts)}
          </span>
          <div className="mx-xxs blueLine" />
          {game?.sport_alias && (
            <img
              width={12}
              alt={game?.sport_alias}
              className="flex-shrink"
              src={require(
                `../../../Assets/Icons/Sport/${game?.sport_alias}.svg`,
              )}
            />
          )}
          <img
            alt="flag"
            className="regionImage"
            src={Flags?.[game?.region_alias]}
          />
          <span className="blueText text-caption-small bold-500 textWithDotes competitionName">
            {game?.competition_name}
          </span>
        </div>
        <span className="blueText text-caption-small bold-500 flex-shrink">{`${
          currentMarketsCount > 0 ? ' + ' : ''
        }${currentMarketsCount}`}</span>
      </div>
      <div className="row justify-between items-center">
        <div className="fit text-center column items-center gap-5 overflow-hidden">
          <img
            width={48}
            alt={game?.team1_id}
            src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
              game?.team1_id / 2000,
            )}/${game?.team1_id}.png`}
          />
          <span className="ellipsis-2-lines fit text-caption blueText bold-500">
            {game?.team1_name}
          </span>
        </div>
        {winnerMarket?.col_count > 2 && (
          <div className="fit text-center text-caption-small blueText bold-500 flex justify-center items-center">
            {t('drawLower')}
          </div>
        )}
        <div className="fit text-center column items-center gap-5 overflow-hidden">
          <img
            width={48}
            alt={game?.team2_id}
            src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
              game?.team2_id / 2000,
            )}/${game?.team2_id}.png`}
          />
          <span className="ellipsis-2-lines fit blueText bold-500 text-caption">
            {game?.team2_name}
          </span>
        </div>
      </div>
      <div className="flex gap-5 justify-between" key={betSlipCount}>
        {renderEvents}
      </div>
    </div>
  );
};

export default memo(GameItem);
