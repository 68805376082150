import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const SportSkeletons = () => (
  <Skeleton
    count={10}
    height={30}
    width={180}
    duration={0.7}
    className="top-5"
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(SportSkeletons);
